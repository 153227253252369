import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import Modal from '@common/modal/Modal';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { GetUnitConversions, ProcessIoDataQualityValues, ProcessIosType, UnitGroupList } from '@typeList/types';
import { useGetProductDetail, useGetUnitConversionsByFromUnitId } from '@hooks/useBackendApi';
import ProductEditModal from '../product_edit/ProductEditModal';
import ProcessIoDataQualityValueTable from '@common/table/ProcessIoDataQualityValueTable';
import FlowParameterSettingListModal from '../flow_parameter_setting/FlowParameterSettingListModal';
import styled from 'styled-components';
import ModalButton from '@common/button/ModalButton';
import { useTranslation } from 'react-i18next';
import { RequiredSpan } from '@styles/Common';
import { t } from 'i18next';
import { checkFormula, getMaxProcessIoNo } from '@utils/utils';
import { useUnitHooks } from '@hooks/useUnitHook';

interface OutputIntermediateFlowCreateModalType {
    data: any;
    onClose: () => void;
    setIsSearchModalOpen: Dispatch<SetStateAction<boolean>>
}

const allocationList = [
    { value: "allocation", label: t('配分') },
    { value: "disposal", label: t('廃棄') },
    { value: "alternative", label: t('代替') },
    { value: "cutoff", label: t('カットオフ') },
];

/**
 * 021　出力中間フローの新規追加
 * @param param0 
 * @returns 
 */
const OutputIntermediateFlowCreateModal: React.FC<OutputIntermediateFlowCreateModalType> = ({ data, onClose, setIsSearchModalOpen }) => {
    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);
    // テーブル追加用
    const {
        unitGroupsData,
        setOutProduct, 
        outProduct,
        inProduct,
        inElementaryFlow,
        outElementaryFlow,
        setChangeCheckFlow,
        unitConversionsData,
        databasePacks
    } = context;

    // 製品詳細
    const { getProductDetail, getProductDetailData, getProductDetailError, setGetProductDetailLoading } = useGetProductDetail()
    useEffect(() =>{
        if(data && data.id){
            getProductDetail(data.id)
        }
    },[data])

    const [inputProductName, setInputProductName] = useState<string>(data?.name);
    const [inputAmount, setInputAmount] = useState<number | null | undefined>(0);
    const [inputAmountFormula, setInputAmountFormula] = useState<string>();
    const [inputPublicComment, setInputPublicComment] = useState<string>(data?.public_comment);
    const [inputPrivateComment, setInputPrivateComment] = useState<string>(data?.private_comment);
    const [inputUnitId, setInputUnitId] = useState<string>(data?.unitId);
    const [inputUnitName, setInputUnitName] = useState<string>(data?.unitName);
    const [inputUnitCode, setInputUnitCode] = useState<string>(data?.unitCode);
    const [inputUnitLocale, setInputUnitLocale] = useState<string>(data?.unitLocale);
    const [inputUnitPrivateComment, setInputUnitPrivateComment] = useState<string | null>(null);
    const [inputUnitPublicComment, setInputUnitPublicComment] = useState<string | null>(null);
    const [inputCompatibleProductName, setInputCompatibleProductName] = useState<string>("");
    const [inputAmountComment, setInputAmountComment] = useState<string>("");
    const [inputInformationSources, setInputInformationSources] = useState<string>("");
    const [inputCompatibilityLevel, setInputCompatibilityLevel] = useState<number>(1);

    // エラーチェック
    const [productNameError, setProductNameError] = useState<any>(null);
    const [amountError, setAmountError] = useState<any>(null);
    const [amountFormulaError, setAmountFormulaError] = useState<any>(null);
    const validateCheck = (): boolean => {
        setProductNameError(null);
        setAmountError(null)
        if (!inputProductName) {
            setProductNameError(t('製品名は必須です'));
        }
        if (inputAmount === null || inputAmount === undefined) {
            setAmountError(t('流量は必須です'));
        }
        if (!checkFormula(inputAmountFormula)) {
            setAmountFormulaError(t('数式に使えない文字が混ざっています。使える文字は、英数と._+-*/()です」'));
        }
        if (!inputProductName || inputAmount === null || inputAmount === undefined || !checkFormula(inputAmountFormula)) {
            console.log("validateCheck false")
            return false;
        }
        return true;
    }

    // 単位マスタから取得
    const [unitList, setUnitList] = useState<UnitGroupList[]>();
    // 製品の単位換算
    const [unitConversionList, setUnitConversionList] = useState<GetUnitConversions[] | undefined>();

    
    // フローの製品の単位換算
    useEffect(() => {
        if (unitGroupsData) {
            const unitConversionCodes = getProductDetailData?.unit_conversions;
            setUnitConversionList(unitConversionCodes)
        }
    }, [unitGroupsData, getProductDetailData])

    // フローの単位が所属するグループでフィルター
    useEffect(() => {
        if (unitGroupsData) {
            const targetUnitGroupId = data?.unit_group_id ?? data?.unit.unit_group_id;
            const list = unitGroupsData.filter(units => units.id === targetUnitGroupId);
            setUnitList(list)
        }
    }, [unitGroupsData])

    const { applyUnitConversion } = useUnitHooks();

    // 単位換算
    const {getUnitConversionsByFromUnitId, getUnitConversionsByFromUnitIdData} = useGetUnitConversionsByFromUnitId();
    useEffect(() =>{
        // 単位切り替え時に換算リストを取得する
        try {
            if(databasePacks) {
                getUnitConversionsByFromUnitId(databasePacks, data.id, Number(inputUnitId))
            }
        } catch (error) {
            console.log(error);
        }
    },[inputUnitId])


    // 分類
    const [categoryName, setCategoryName] = useState<string>("");
    useEffect(() => {
        if (Array.isArray(data?.category_values)) {
            const names = data.category_values.map((categrys: any) => categrys.category.name + " " + categrys?.name).join(", ");
            setCategoryName(names);
        } else {
            console.warn(t("category_valuesは配列ではないか、undefinedです"));
        }
    }, [data]);

    // 製品詳細
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // 品質
    const [selectQualityIndicatorsData, setSelectQualityIndicatorsData] = useState<ProcessIoDataQualityValues[] | undefined>([]); // 選択された品質（更新用）
    const [qualityIndicatorsDataList, setQualityIndicatorsDataList] = useState<any[] | undefined>([]); // テーブルでの表示用 


    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const value = event.target.value;
        switch (key) {
            case "name":
                setInputProductName(value);
                break;
            case "amount":
                setInputAmount(value === '' ? null : Number(value));
                break;
            case "amount_formula":
                setInputAmountFormula(value);
                break;
            case "compatible_product_name":
                setInputCompatibleProductName(value);
                break;
        }
    }
    const handleInputChangeTextArea = async (event: React.ChangeEvent<HTMLTextAreaElement>, key: string) => {
        switch (key) {
            case "public_comment":
                setInputPublicComment(event.target.value);
                break;
            case "private_comment":
                setInputPrivateComment(event.target.value);
                break;
            case "amount_comment":
                setInputAmountComment(event.target.value);
                break;
            case "information_sources":
                setInputInformationSources(event.target.value);
                break;
        }
    }
    const handleInputChangeSelect = async (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case "unit":
                const selectedValue = event.target.value;
                setInputUnitId(selectedValue);
                // unitListの中のunitsから選択されたユニットを探す
                for (const group of unitGroupsData ?? []) {
                    const selectedUnit = group.units.find(unit => unit.id === Number(selectedValue));
                    if (selectedUnit) {
                        setInputUnitName(selectedUnit.name);
                        setInputUnitCode(selectedUnit.code);
                        setInputUnitLocale(selectedUnit.locale);
                        setInputUnitPrivateComment(selectedUnit.private_comment);
                        setInputUnitPublicComment(selectedUnit.public_comment);
                        break;
                    }
                }

                // 単位換算の適用
                const newAmount = applyUnitConversion(
                    inputAmount,
                    Number(inputUnitId),
                    Number(selectedValue),
                    getUnitConversionsByFromUnitIdData,
                    getProductDetailData,
                );
                setInputAmount(newAmount);

                break;
            case "compatibility_level":
                setInputCompatibilityLevel(Number(event.target.value));
                break;
        }
    }

    const handleClickOK = async () => {
        if (!validateCheck()) {
            return;
        }
        if (outProduct !== undefined) {
            setOutProduct([...outProduct, param]);
        }
        setChangeCheckFlow(true)
        setIsSearchModalOpen(false);
        onClose();
    }

    // テーブル追加データ
    const param: ProcessIosType = {
        amount: inputAmount ?? 0,
        amount_comment: inputAmountComment,
        compatible_product_name: inputCompatibleProductName,
        direction: 'out',
        exchange: {
            public_comment: data?.public_comment,
            private_comment: data?.private_comment,
            cas_number: data?.cas_number,
            id: data?.id,
            locale: data?.locale,
            name: inputProductName,
            synonyms_text: data?.synonyms_text,
            include_items_text: data?.include_items_text,
            related_items_text: data?.related_items_text,
            global_id: data?.global_id,
            unit: {
                id: getProductDetailData?.unit.id ?? 0,
                locale: getProductDetailData?.unit.locale ?? "",
                name: getProductDetailData?.unit.name ?? "",
                private_comment: getProductDetailData?.unit.private_comment ?? "",
                public_comment: getProductDetailData?.unit.public_comment ?? "",
                code: getProductDetailData?.unit.code ?? "",
            },
            identifier_scheme_values: data?.identifier_scheme_values,
            type: "product",
            created_username: data?.created_username,
            category_values: data?.category_values,
            category1_code: null,
            category2_code: null,
            category3_code: null,
            category1_name: data?.category1_name,
            category2_name: data?.category2_name,
            category3_name: data?.category3_name,
            is_lcia_result_flow: null,
        },
        process_io_no: getMaxProcessIoNo(inProduct, inElementaryFlow, outProduct, outElementaryFlow) + 1,
        formula: inputAmountFormula ?? "",
        global_id: data?.global_id,
        id: null,
        information_sources: inputInformationSources ?? "",
        locale: data?.locale,
        private_comment: inputPrivateComment,
        public_comment: inputPublicComment,
        unit: {
            id: Number(inputUnitId) ?? 0,
            locale: inputUnitLocale,
            name: inputUnitName,
            private_comment: inputUnitName,
            public_comment: inputUnitPublicComment,
            code: inputUnitCode
        },
        treatment: {
            type: "allocation",
            allocation_rate: 1,
        },
        upper_output: null,
        process_io_data_quality_values: selectQualityIndicatorsData ?? [],
        compatibility_level: inputCompatibilityLevel
    }

    return (
        <Inner>
            <Title>{t('出力中間フロー（製品等）の新規追加')}</Title>
            <SectionBody>
                <SectionColumn>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('製品')}<RequiredSpan>※</RequiredSpan></SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <DisabledInput
                                type="text"
                                onChange={(event) => handleInputChange(event, 'name')}
                                value={inputProductName ?? ''}
                                disabled
                            />
                            <ModalButton type='button' onClick={() => { setIsModalOpen(true) }} text={t('製品詳細')} />
                            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                                <ProductEditModal Id={data?.id} onClose={onClose} />
                            </Modal>
                            <ModalButton type='button' onClick={onClose} text={t('変更する')} />
                            {productNameError && <ErrorMsg>{productNameError}</ErrorMsg>}
                        </SectionColumnContent>
                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('分類')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <InputPrimary
                                type="text"
                                disabled
                                value={categoryName ?? ''}
                            />
                        </SectionColumnContent>
                    </SectionColumnWrap>

                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('流量の数式')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <InputPrimary
                                type="text"
                                value={inputAmountFormula ?? ""}
                                onChange={(event) => handleInputChange(event, 'amount_formula')}
                            />
                            <FlowParameterSettingListModal inputAmountFormula={inputAmountFormula} setInputAmountFormula={setInputAmountFormula} />
                        </SectionColumnContent>
                        {amountFormulaError && <><SectionColumnContent></SectionColumnContent><ErrorMsg>{amountFormulaError}</ErrorMsg></>}
                    </SectionColumnWrap>

                    <SectionColumnWrap>
                        <SectionColumnWrapTitle></SectionColumnWrapTitle>
                        <SectionColumnContent>
                        {t('*数式はプロセス保存時に評価され流量に反映されます')}
                        </SectionColumnContent>
                    </SectionColumnWrap>

                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('流量')}<RequiredSpan>※</RequiredSpan></SectionColumnWrapTitle>
                        <SectionColumnContentShort>
                            <InputPrimaryShort
                                type="number"
                                onChange={(event) => handleInputChange(event, 'amount')}
                                value={inputAmount !== null && inputAmount !== undefined ? inputAmount : ''}
                                disabled={!!inputAmountFormula}
                            />
                            <SelectPrimaryShort>
                                <SelectInner
                                    value={inputUnitId}
                                    onChange={(event) => handleInputChangeSelect(event, 'unit')}
                                >
                                    {unitList?.map((units, index) => (
                                        units.units.map((unit) => (
                                            <option key={unit.name} value={unit.id}>
                                                {unit.name}
                                            </option>
                                        ))
                                    ))}
                                    {unitConversionList?.map((units, index) => (
                                        <option key={units.to_unit.id} value={units.to_unit.id}>
                                            {units.to_unit.name}
                                        </option>
                                    ))}
                                </SelectInner>
                            </SelectPrimaryShort>
                            {amountError && <ErrorMsg>{amountError}</ErrorMsg>}
                        </SectionColumnContentShort>
                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('取扱方法')}</SectionColumnWrapTitle>
                        <SectionColumnContentRow>
                            <DisabledInput
                                type="text"
                                disabled
                                value={allocationList.find(option => option.value === "allocation")?.label || ""}
                            />
                            <SectionColumnContentNote>
                                <SectionColumnContentNoteItem>{t('＊取扱方法変更する場合配分のタブで設定してください。')}</SectionColumnContentNoteItem>
                            </SectionColumnContentNote>
                        </SectionColumnContentRow>
                    </SectionColumnWrap>
                </SectionColumn>
            </SectionBody>

            <Title>{t('適合性')}</Title>
            <SectionBody>
                <SectionColumn>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('情報源のフロー名')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <InputPrimary
                                type="text"
                                value={inputCompatibleProductName ?? ''}
                                onChange={(event) => handleInputChange(event, 'compatible_product_name')}
                            />
                        </SectionColumnContent>
                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('適合度')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <SelectPrimary>
                                <SelectInner
                                    onChange={(event) => handleInputChangeSelect(event, 'compatibility_level')}
                                    value={inputCompatibilityLevel ?? "1"}
                                >
                                    <option value="1">{t('よい')}</option>
                                    <option value="2">{t('普通')}</option>
                                    <option value="3">{t('悪い')}</option>
                                </SelectInner>
                            </SelectPrimary>
                        </SectionColumnContent>

                    </SectionColumnWrap>
                </SectionColumn>
            </SectionBody>

            <Title>{t('品質')}</Title>
            <SectionBody>
                <SectionColumn>
                    <ProcessIoDataQualityValueTable
                        selectQualityIndicatorsData={selectQualityIndicatorsData}
                        setSelectQualityIndicatorsData={setSelectQualityIndicatorsData}
                        qualityIndicatorsDataList={qualityIndicatorsDataList}
                        setQualityIndicatorsDataList={setQualityIndicatorsDataList}
                    />
                </SectionColumn>
            </SectionBody>

            <Title>{t('コメント')}</Title>
            <SectionBody>
                <SectionColumn>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('プライベートコメント')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <TextArea
                                name=""
                                id=""
                                cols={50}
                                rows={2}
                                onChange={(event) => handleInputChangeTextArea(event, 'private_comment')}
                                value={inputPrivateComment ?? ''}
                            />
                        </SectionColumnContent>
                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('公開コメント')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <TextArea
                                name=""
                                id=""
                                cols={50}
                                rows={2}
                                onChange={(event) => handleInputChangeTextArea(event, 'public_comment')}
                                value={inputPublicComment ?? ''}
                            />
                        </SectionColumnContent>
                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('情報源')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <TextArea
                                name=""
                                id=""
                                cols={50}
                                rows={2}
                                onChange={(event) => handleInputChangeTextArea(event, 'information_sources')}
                                value={inputInformationSources ?? ''}
                            />
                        </SectionColumnContent>
                    </SectionColumnWrap>
                    <SectionColumnWrap>
                        <SectionColumnWrapTitle>{t('流量に関するコメント')}</SectionColumnWrapTitle>
                        <SectionColumnContent>
                            <TextArea
                                name=""
                                id=""
                                cols={50}
                                rows={2}
                                onChange={(event) => handleInputChangeTextArea(event, 'amount_comment')}
                                value={inputAmountComment ?? ''}
                            />
                        </SectionColumnContent>
                    </SectionColumnWrap>
                </SectionColumn>
            </SectionBody>
            <ButtonCreateSectionTabel>
                <ModalButton onClick={handleClickOK} text={t("追加")} />
            </ButtonCreateSectionTabel>
        </Inner>
    )
}

export default OutputIntermediateFlowCreateModal

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const SectionBody = styled.div`
    margin-bottom: 60px;
`

const SectionBodyLast = styled.div`
`

const SectionColumn = styled.dl`
`

const SectionColumnContent = styled.dd`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`

const SectionColumnContentRow = styled.dd`
    flex-direction: column;
    gap: 8px 0;
    align-items: stretch;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
`


const SectionColumnContentShort = styled.dd`
    width: min(100%, 340px);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`


const SectionColumnWrap = styled.div`
    margin-bottom: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;      
`

const SectionColumnWrapTitle = styled.dt`
    margin-bottom: 0;   
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const InputPrimary = styled.input`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const DisabledInput = styled.input`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`


const InputPrimaryShort = styled.input<{ disabled?: boolean }>`
    width: 50%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: ${(props) => (props.disabled ? 'var(--color-bg-primary)' : '#fff')};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    &:disabled {
        pointer-events: none;
    }
`


const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectPrimaryShort = styled.label`
    width: 50%;
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 10px 32px 10px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const TextArea = styled.textarea`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
`

const ButtonCreateSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`

const SectionColumnContentNote = styled.ul`
`

const SectionColumnContentNoteItem = styled.li`
    position: relative;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
`
const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`;