import React, { useState } from 'react';
import Modal from '@common/modal/Modal';
import styled from 'styled-components';
import { LinkMenu } from '@styles/Common';

import { getRequest } from '@services/api/api';
import DataTable from '@common/table/DataTable';
import { createColumnHelper } from '@tanstack/react-table'
import { Region } from '@typeList/types';
import { useTranslation } from 'react-i18next';
import ErrorMsg from '@common/error/ErrorMsg';
import { FadeLoader } from 'react-spinners';

/**
 * 004　地域マスタ
 * @returns 
 */
const RegionsModal: React.FC = () => {
    const { t } = useTranslation();
    const [data, setData] = useState<any | null>(null);
    const [dataCount, setDataCount] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // モーダル開閉
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // テーブル設定
    const columnHelper = createColumnHelper<Region>()

    const columns = [
        columnHelper.accessor(row => row.code, {
            id: 'code',
            cell: info => <i>{info.getValue()}</i>,
            header: () => t('地域コード'),
        }),
        columnHelper.accessor('name', {
            header: () => t('地域名'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('parent_region.name', {
            header: () => t('親地域'),
            cell: info => info.renderValue(),
        }),
    ]

    // 地域マスタデータ取得
    const getRegions = async () => {
        setLoading(true);
        try {
            const response: any = await getRequest('/regions');
            setData(response);
            setDataCount(response.length)
        } catch (err: any) {
            console.log("NG", err.message);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="app">
            <LinkMenu onClick={() => {
                openModal();
                getRegions();
            }
            }>
                {t('地域マスタ')}
            </LinkMenu>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <Title>{t('地域の閲覧')}</Title>
                    {loading && 
                        <FadeLoader
                            color="#48bdbb"
                            height={10}
                            radius={2}
                            width={5}
                        />
                    }
                    {error && <ErrorMsg>{error}</ErrorMsg>}
                    <SectionTable>
                        {data &&
                            <>
                                <DataTable count={dataCount} columns={columns} data={data} paging_flag={true} />
                            </>
                        }
                    </SectionTable>
                </Inner>
            </Modal>
        </div>
    );
};

export default RegionsModal;

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`
const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const SectionTable = styled.div`
    background-color: #fff;
    padding: 40px 20px 55px;
  
`

const SectionResult = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`

const SectionResultTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const SectionResultTitleText = styled.p`
    font-size: 18px;
    font-weight: 500;
`

const SectionTitle = styled.h2`
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const SectionBody = styled.div`
`

const SectionContent = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const InputPrimary = styled.input`
    width: min(100%, 340px);

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const DataError = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`


const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`




const Radio = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px; 
`

const RadioLabel = styled.label`
    order: 1;
    
    isplay: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;    
`
const RadioText = styled.span`
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500; 
`

const SectionColumn = styled.dl`
    margin-bottom: 40px;
`

const StyledButton = styled.button`
    background-color: var(--color-gray--01);
    color: inherit;

    padding: 14px 30px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;   
  &:hover {
    background-color: var(--color-site-secondary);
    color: #fff;
  }
`

