import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { SubsystemIOElementaryFlowOut, UnitGroupList } from '@typeList/types';
import { useGetUnitConversionsByFromUnitId, useGetUnitGroups } from '@hooks/useBackendApi';
import FlowParameterSettingListModal from '@specific/process/flow_parameter_setting/FlowParameterSettingListModal';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import styled from 'styled-components';

import iconReload from '@images/button/icon_reload.svg';
import { useTranslation } from 'react-i18next';
import { RequiredSpan } from '@styles/Common';
import { checkFormula } from '@utils/utils';
import { useUnitHooks } from '@hooks/useUnitHook';

interface SubsystemOutputElementaryFlowCreateModalType {
    data: any;
    onClose: () => void;
    setIsSearchModalOpen: Dispatch<SetStateAction<boolean>>
    currentNodeData: any;
}

/**
 * 067-3　製品システム: 出力基本フローの編集
 * @param param0 
 * @returns 
 */
const SubsystemOutputElementaryFlowCreateModal: React.FC<SubsystemOutputElementaryFlowCreateModalType> = ({ 
    data, 
    onClose, 
    setIsSearchModalOpen,
    currentNodeData,
}) => {
    const {t} = useTranslation();
    const context = useContext(CaseStudyViewContext);
    // テーブル追加用
    const { unitGroupsData, nodes, setNodes, flowState, setFlowState, unitConversionsData, databasePacks } = context;
    
    const [inputElementaryFlowName, setInputElementaryFlowName] = useState<string>(data?.name);
    const [inputAmount, setInputAmount] = useState<number | null | undefined>(0);
    const [inputAmountFormula, setInputAmountFormula] = useState<string>();
    const [inputPublicComment, setInputPublicComment] = useState<string>(data?.public_comment);
    const [inputPrivateComment, setInputPrivateComment] = useState<string>(data?.private_comment);
    const [inputUnitId, setInputUnitId] = useState<string>(data?.unitId);
    const [inputUnitName, setInputUnitName] = useState<string>(data?.unitName);
    const [inputUnitCode, setInputUnitCode] = useState<string>(data?.unitCode);
    const [inputUnitLocale, setInputUnitLocale] = useState<string>(data?.unitLocale);
    const [inputUnitPrivateComment, setInputUnitPrivateComment] = useState<string | null>(null);
    const [inputUnitPublicComment, setInputUnitPublicComment] = useState<string | null>(null);
    const [inputCompatibleProductName, setInputCompatibleProductName] = useState<string>("");
    const [inputAmountComment, setInputAmountComment] = useState<string>("");
    const [inputInformationSources,  setInputInformationSources] = useState<string>("");
    const [inputCompatibilityLevel, setInputCompatibilityLevel] = useState<number>(1);

    // エラーチェック
    const [elementaryFlowNameError, setElementaryFlowNameError] = useState<any>(null);
    const [amountError, setAmountError] = useState<any>(null);
    const [amountFormulaError, setAmountFormulaError] = useState<any>(null);
    const validateCheck = (): boolean => {
        setElementaryFlowNameError(null);
        setAmountError(null);
        setAmountFormulaError(null);
        if (!inputElementaryFlowName) {
            setElementaryFlowNameError(t('製品名は必須です'));
        }
        if (inputAmount === null || inputAmount === undefined) {
            setAmountError(t('流量は必須です'));
        }
        if (!checkFormula(inputAmountFormula)) {
            setAmountFormulaError(t('数式に使えない文字が混ざっています。使える文字は、英数と._+-*/()です'));
        }          
        if (!inputElementaryFlowName || inputAmount === null || inputAmount === undefined || !checkFormula(inputAmountFormula)) {
            console.log("validateCheck false")
            return false;
        }
        return true;
    }

    // 単位マスタから取得
    const [unitList, setUnitList] = useState<UnitGroupList[]>();

    // フローの単位が所属するグループでフィルター
    useEffect(() => {
        if(unitGroupsData){
            const targetUnitGroupId = data?.unit_group_id ?? data?.unit.unit_group_id;
            const list = unitGroupsData.filter(units => units.id === targetUnitGroupId);
            setUnitList(list)
        }
    },[unitGroupsData])

    const { applyUnitConversion } = useUnitHooks();

    // 単位換算
    const {getUnitConversionsByFromUnitId, getUnitConversionsByFromUnitIdData} = useGetUnitConversionsByFromUnitId();
    useEffect(() =>{
        // 単位切り替え時に換算リストを取得する
        try {
            if(databasePacks) {
                getUnitConversionsByFromUnitId(databasePacks, data.id, Number(inputUnitId))
            }
        } catch (error) {
            console.log(error);
        }
    },[inputUnitId])

    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const value = event.target.value;
        switch (key) {
            case "name":
                setInputElementaryFlowName(value);
                break;
            case "amount":
                setInputAmount(value === '' ? null : Number(value));
                break;
            case "amount_formula":
                setInputAmountFormula(value);
                break;
            case "compatible_product_name":
                setInputCompatibleProductName(value);
                break;
        }
    }
    const handleInputChangeTextArea = async (event: React.ChangeEvent<HTMLTextAreaElement>, key: string) => {
        switch (key) {
            case "public_comment":
                setInputPublicComment(event.target.value);
                break;
            case "private_comment":
                setInputPrivateComment(event.target.value);
                break;
            case "amount_comment":
                setInputAmountComment(event.target.value);
                break;
            case "information_sources":
                setInputInformationSources(event.target.value);
                break;
        }
    }
    const handleInputChangeSelect = async (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case "unit":
                const selectedValue = event.target.value;
                setInputUnitId(selectedValue);
                // unitListの中のunitsから選択されたユニットを探す
                for (const group of unitList ?? []) {
                    const selectedUnit = group.units.find(unit => unit.id === Number(selectedValue));
                    if (selectedUnit) {
                        setInputUnitName(selectedUnit.name);
                        setInputUnitCode(selectedUnit.code);
                        setInputUnitLocale(selectedUnit.locale);
                        setInputUnitPrivateComment(selectedUnit.private_comment);
                        setInputUnitPublicComment(selectedUnit.public_comment);
                        break;
                    }
                }

                // 単位換算の適用
                const newAmount = applyUnitConversion(
                    inputAmount,
                    Number(inputUnitId),
                    Number(selectedValue),
                    getUnitConversionsByFromUnitIdData,
                );
                setInputAmount(newAmount);
                
                break;
            case "compatibility_level":
                setInputCompatibilityLevel(Number(event.target.value));
                break;
        }
    }

    const handleClickOK = async () => {
        if(!validateCheck()){
            return;
        }
        const targetNode = nodes.find(node => String(node.id) === String(currentNodeData.id));
        const maxId = targetNode ? Math.max(...targetNode.data.subsystem_ios.map((io: any) => io.id), 0) : 0;
        // const maxProcessIoNo = targetNode ? Math.max(...targetNode.data.subsystem_ios.map((io: any) => io.process_io_no), 0) : 0;
        const maxProcessIoNo = Math.max(
            ...flowState.nodes.flatMap(node => node.data.subsystem_ios.map((io: any) => io.process_io_no)),
            0
          );

        const newParam: SubsystemIOElementaryFlowOut = {
            ...param,
            id: null,
            process_io_no: maxProcessIoNo + 1
        };

        if(nodes !== undefined){
            const updatedNodes = nodes.map(node => {
                if (String(node.id) === String(currentNodeData.id)) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            subsystem_ios: [...node.data.subsystem_ios, newParam]
                        }
                    }
                }
                return node;
            });
            setNodes(updatedNodes);
        }

        if(flowState.nodes !== undefined){
            const updatedFlowStateNodes = flowState.nodes.map(node => {
                if (String(node.id) === String(currentNodeData.id)) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            subsystem_ios: [...node.data.subsystem_ios, newParam]
                        }
                    }
                }
                return node;
            });
            setFlowState({
                ...flowState,
                nodes: updatedFlowStateNodes
            });
        }

        setIsSearchModalOpen(false);
        onClose();
    }

    // テーブル追加データ
    const param: SubsystemIOElementaryFlowOut = {
        locale: data?.locale,
        compatible_product_name: inputCompatibleProductName,
        public_comment: inputPublicComment,
        private_comment: inputPrivateComment,
        information_sources: inputInformationSources,
        amount_comment: inputAmountComment,
        process_io_no: 1, // 自動採番
        formula: inputAmountFormula || "",
        amount: inputAmount || 0,
        direction: "out",
        compatibility_level: inputCompatibilityLevel,
        id: 0,　// 自動採番
        global_id: "",
        unit: {
            id: Number(inputUnitId),
            name: inputUnitName || ""
        },
        type: "elementary_flow",
        exchange: {
            id: data?.id || 0,
            global_id: data?.global_id,
            unit: {
                id: data?.unitId || 0 ,
                name: data?.unitName || ""
            },
            name: inputElementaryFlowName,
            is_lcia_result_flow: data?.is_lcia_result_flow,
            category1_code: data?.category1_code,
            category2_code: data?.category2_code,
            category3_code: data?.category3_code,
            category1_name: data?.category1_name,
            category2_name: data?.category2_name,
            category3_name: data?.category3_name,
        }
    }

    return (
        <Inner>
            <Title>{t('製品システム: 基本フローの追加')}</Title>
            <Section>
                <Dl>
                    <DlWrap>
                        <Dt>{t('資源・排出物')}<RequiredSpan>※</RequiredSpan></Dt>
                        <Dd>
                            <DisabledInput
                                type="text"
                                onChange={(event) => handleInputChange(event, 'name')}
                                value={inputElementaryFlowName ?? ''}
                                disabled
                            />
                            <UpdateButton type='button' onClick={onClose}>
                                <ButtonIconWrapper>
                                    <ButtonIcon></ButtonIcon>
                                </ButtonIconWrapper>
                                <ButtonText>{t('変更')}</ButtonText>
                            </UpdateButton>
                            {elementaryFlowNameError && <ErrorMsg>{elementaryFlowNameError}</ErrorMsg>}
                        </Dd>
                    </DlWrap>
                    <DlWrap>
                        <Dt>{t('流量の数式')}</Dt>
                        <Dd>
                            <Input
                                type="text"
                                value={inputAmountFormula ?? ""}
                                onChange={(event) => handleInputChange(event, 'amount_formula')}
                            />
                            <FlowParameterSettingListModal inputAmountFormula={inputAmountFormula} setInputAmountFormula={setInputAmountFormula} />
                        </Dd>
                        {amountFormulaError && <><Dd></Dd><ErrorMsg>{amountFormulaError}</ErrorMsg></>}
                    </DlWrap>
                    <DlWrap>
                        <Dt></Dt>
                        <Dd>
                        {t('*数式はケーススタディ保存時に評価され流量に反映されます')}
                        </Dd>
                    </DlWrap>
                    <DlWrap>
                        <Dt>{t('流量')}<RequiredSpan>※</RequiredSpan></Dt>
                        <DdShort>
                            <AmountInput
                                type="number"
                                onChange={(event) => handleInputChange(event, 'amount')}
                                value={inputAmount !== null && inputAmount !== undefined ? inputAmount : ''}
                                disabled={!!inputAmountFormula}
                            />
                            <UnitSelectLabel>
                                <UnitSelect
                                    value={inputUnitId ?? ''}
                                    onChange={(event) => handleInputChangeSelect(event, 'unit')}
                                >
                                    <option value={data?.unit_id}>{data?.unitCode}</option>
                                    {unitList?.map((units, index) => (
                                        units.units.map((unit) => (
                                            <option key={unit.name} value={unit.id}>
                                                {unit.name}
                                            </option>
                                        ))
                                    ))}
                                </UnitSelect>
                            </UnitSelectLabel>
                            {amountError && <ErrorMsg>{amountError}</ErrorMsg>}
                        </DdShort>
                    </DlWrap>
                </Dl>
            </Section>
            <Section>
                <Title>{t('適合性')}</Title>
                <Dl>
                    <DlWrap>
                        <Dt>{t('情報源のフロー名')}</Dt>
                        <Dt>
                            <FlowNameInput
                                type="text"
                                value={inputCompatibleProductName ?? ''}
                                onChange={(event) => handleInputChange(event, 'compatible_product_name')}
                            />
                        </Dt>
                    </DlWrap>
                    <DlWrap>
                        <Dt>{t('適合度')}</Dt>
                        <Dd>
                            <SelectLabel>
                                <Select
                                    onChange={(event) => handleInputChangeSelect(event, 'compatibility_level')}
                                    value={inputCompatibilityLevel ?? "1"}
                                >
                                    <option value="1">{t('よい')}</option>
                                    <option value="2">{t('普通')}</option>
                                    <option value="3">{t('悪い')}</option>
                                </Select>
                            </SelectLabel>
                        </Dd>
                    </DlWrap>
                </Dl>
            </Section>
            <Section>
                <Title>{t('コメント')}</Title>
                <Dl>
                    <DlWrap>
                        <Dt>{t('プライベートコメント')}</Dt>
                        <Dd>
                            <TextArea
                                cols={100}
                                rows={3}
                                onChange={(event) => handleInputChangeTextArea(event, 'private_comment')}
                                value={inputPrivateComment ?? ''}
                            />
                        </Dd>
                    </DlWrap>
                    <DlWrap>
                        <Dt>{t('公開コメント')}</Dt>
                        <Dd>
                            <TextArea
                                cols={100}
                                rows={3}
                                onChange={(event) => handleInputChangeTextArea(event, 'public_comment')}
                                value={inputPublicComment ?? ''}
                            />
                        </Dd>
                    </DlWrap>
                    <DlWrap>
                        <Dt>{t('情報源')}</Dt>
                        <Dd>
                            <TextArea
                                cols={50}
                                rows={2}
                                onChange={(event) => handleInputChangeTextArea(event, 'information_sources')}
                                value={inputInformationSources ?? ''}
                            />
                        </Dd>
                    </DlWrap>
                    <DlWrap>
                        <Dt>{t('流量の計算方法')}</Dt>
                        <Dd>
                            <TextArea
                                cols={50}
                                rows={2}
                                onChange={(event) => handleInputChangeTextArea(event, 'amount_comment')}
                                value={inputAmountComment ?? ''}
                            />
                        </Dd>
                    </DlWrap>
                </Dl>
            </Section>
            <ButtonWrapper>
                <Button type="button" onClick={handleClickOK}>
                    <ButtonText>{t('追加')}</ButtonText>
                </Button>
            </ButtonWrapper>
        </Inner>
    )
}

export default SubsystemOutputElementaryFlowCreateModal;


const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const Section = styled.div`
    margin-bottom: 60px;
`

const Dl = styled.dl`
`

const DlWrap = styled.div`
margin-bottom: 8px;

display: grid;
align-items: center;
grid-template-columns: 27.0833333333% minmax(0, 1fr);
gap: 6px 40px;
`

const Dt = styled.dt`
margin-bottom: 0;
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`
const Dd = styled.dd`
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 16px;
`
const DdShort = styled.dd`
width: min(100%, 340px);

display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 16px;
`

const Input = styled.input`
width: 81.6176470588%;

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const AmountInput = styled.input<{ disabled?: boolean }>`
width: 50%;

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: ${(props) => (props.disabled ? 'var(--color-bg-primary)' : '#fff')};
font-size: 16px;
font-weight: 400;
line-height: 1.25;
&:disabled {
    pointer-events: none;
}
`

const FlowNameInput = styled.input`
width: min(100%, 340px);

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const UnitSelectLabel = styled.label`
width: 50%;
position: relative;
display: block;
`
const UnitSelect = styled.select`
color: rgb(85, 85, 85);

position: relative;
width: 100%;
padding: 10px 32px 10px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const SelectLabel = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
`

const Select = styled.select`
color: rgb(85, 85, 85);

position: relative;
width: 100%;
padding: 12px 32px 12px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const DownInput = styled.input`
width: 100%;
background-color: var(--color-bg-primary);

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const TextArea = styled.textarea`
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
width: 100%;
`

const ButtonWrapper = styled.div`
margin-top: 20px;
text-align: right;
`
const Button = styled.button`
padding: 8px 24px;
border-radius: 20px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
background-color: var(--color-gray--01);
gap: 0 4px;

-moz-appearance: none;
appearance: none;
border: none;
font: inherit;
outline: none;

&:hover {
    background-color: var(--color-site-secondary);
    text-decoration: none !important;

    div span {
        background-color: #fff;
    }
    span {
        color: #fff;
    }
}
`

const ButtonIconWrapper = styled.div`
`
const ButtonIcon = styled.span`
background-color: var(--color-site-secondary);

mask: url(${iconReload}) no-repeat center center / contain;
-webkit-mask: url(${iconReload}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 24px;
height: 24px;
`
const ButtonText = styled.span`
font-size: 12px;
font-weight: 500;
`

const UpdateButton = styled.button`
padding: 2px 12px;
border-radius: 20px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
background-color: var(--color-gray--01);
gap: 0 4px;

-moz-appearance: none;
appearance: none;
border: none;
font: inherit;
outline: none;

&:hover {
    background-color: var(--color-site-secondary);
    text-decoration: none !important;

    div span {
        background-color: #fff;
    }
    span {
        color: #fff;
    }
}
`

const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`;

const DisabledInput = styled.input`
    width: 81.6176470588%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: var(--color-bg-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`