import React, { useContext, useEffect, useState } from 'react';
import Modal from '@common/modal/Modal';
import { getRandomColor, getRandomColorFromColorPalette } from '@utils/colorUtils';
import PlotlySankeyDiagram from '@common/graph/PlotlySankeyDiagram';
import { CaseStudyContributionAnalyze, UpperContributions } from '@typeList/types';
import { useCaseStudyContributionAnalyze } from '@hooks/useBackendApi';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LoadingDialog from '@common/modal/LoadingDialog';
import SearchSelect from '@common/select/SearchSelect';
import { SingleValue } from 'react-select';

interface ContributionAnalysisProps {
    resultData: any | null;
    result_type: string;
}

const CaseStudyContributionAnalysisModal: React.FC<ContributionAnalysisProps> = ({ resultData, result_type }) => {
    const { t } = useTranslation();
    const { caseStudyData, userData } = useContext(CaseStudyViewContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedExchangeIndex, setSelectedExchangeIndex] = useState(0);
    const [maxPercentage, setMaxPercentage] = useState(10);
    const [sankeyData, setSankeyData] = useState<{ nodes: any[]; links: any[] } | null>(null);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleExchangeChange = (newValue: SingleValue<any> | null) => {
        if (newValue && result_type === "lci") {
            setSelectedExchangeIndex(exchangeNames.findIndex(option => option.value === newValue.value));
        }

        if (newValue && result_type === "lcia") {
            setSelectedExchangeIndex(lciaModelNames.findIndex(option => option.value === newValue.value));
        }
    };

    const handleMaxPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.min(Math.max(Number(event.target.value), 1), 100);
        setMaxPercentage(value);
    };

    const {
        excuteProcessContributionAnalyze,
        contributionAnalyzeData,
        contributionAnalyzeLoading,
    } = useCaseStudyContributionAnalyze();

    const [analyzeData, setAnalyzeData] = useState<CaseStudyContributionAnalyze>();

    const handleClickAnalyze = async () => {
        if (!resultData || resultData.length === 0 || !isModalOpen) {
            return;
        }

		setDaialogMessage('')
        openLoadingDialog();

        try {
			// LCI インベントリ計算
            if (result_type === "lci") {
                const selectedExchange = resultData?.result_items?.[selectedExchangeIndex]?.exchange;
                if (selectedExchange) {
                    excuteProcessContributionAnalyze(caseStudyData?.id || 0, {
                        result_key: {
                            result_type: result_type,
                            direction: resultData?.result_items?.[selectedExchangeIndex]?.direction || "in",
                            exchange_id: selectedExchange.id,
                        },
                        ratio_border: maxPercentage / 100,
                    });
                }
            }

			// LCIA　特性化　被害評価　統合化
            if (result_type === "lcia") {
                const selectedLciaModel = resultData?.result_items?.[selectedExchangeIndex]?.lcia_model;
                if (selectedLciaModel) {
                    excuteProcessContributionAnalyze(caseStudyData?.id || 0, {
                        result_key: {
                            result_type: result_type,
                            lcia_model_id: selectedLciaModel.id,
                        },
                        ratio_border: maxPercentage / 100,
                    });
                }
            }
			setDaialogMessage(t('計算が完了しました'))
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (contributionAnalyzeData) {
            setAnalyzeData(contributionAnalyzeData);
        }
    }, [contributionAnalyzeData]);

    useEffect(() => {
        if (analyzeData) {
            const nodes: { label: string; color: string }[] = [];
            const links: { source: number; target: number; value: number; color: string }[] = [];
            const nodeIndexMap: { [key: string]: number } = {};

            const traverseContributions = (
                contributions: UpperContributions[],
                parentName: string | null = null
            ) => {
                for (const contribution of contributions) {
                    if (!nodeIndexMap[contribution.name]) {
                        const color = getRandomColorFromColorPalette();
                        nodeIndexMap[contribution.name] = nodes.length;
                        nodes.push({ label: contribution.name, color });
                    }

                    if (parentName) {
                        links.push({
                            source: nodeIndexMap[parentName],
                            target: nodeIndexMap[contribution.name],
                            value: contribution.ratio * contribution.amount,
                            color: getRandomColorFromColorPalette(),
                        });
                    }

                    if (contribution.upper_contributions.length > 0) {
                        traverseContributions(contribution.upper_contributions, contribution.name);
                    }
                }
            };

            traverseContributions(analyzeData.upper_contributions);

            setSankeyData({ nodes, links });
        }
    }, [analyzeData]);

    const [exchangeNames, setExchangeNames] = useState<any[]>([]);
    useEffect(() => {
        if (resultData && isModalOpen && result_type === "lci") {
            const names =
                resultData?.result_items?.map((item: any) => {
                    const category2 = item.exchange.category2_name ? `/${item.exchange.category2_name}` : "";
                    const category3 = item.exchange.category3_name ? `/${item.exchange.category3_name}` : "";
                    return {
                        label: `${item.exchange.name}${category2}${category3}`,
                        value: item.exchange.id,
                    };
                }) || [];

            setExchangeNames(names);
        }
    }, [resultData, isModalOpen]);

    const [lciaModelNames, setLciaModelNames] = useState<any[]>([]);
    useEffect(() => {
        if (resultData && isModalOpen && result_type === "lcia") {
            const names =
                resultData?.result_items?.map((item: any) => ({
                    label: `${item.lcia_model?.impact_assessment_method?.name}/${item.lcia_model?.impact_category?.name}/${item.lcia_model?.lcia_indicator?.name}`,
                    value: item.lcia_model?.id,
                })) || [];
            setLciaModelNames(names);
        }
    }, [resultData, isModalOpen]);

    const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false);
    const openLoadingDialog = () => setIsLoadingDialogOpen(true);
    const closeLoadingDialog = () => setIsLoadingDialogOpen(false);
	const [daialogMessage, setDaialogMessage] = useState<string>("");
	const daialogLoadingMessage = t('計算しています');

    // 別タブで展開
	const handleOpenSankeyDiagram = () => {
		if (sankeyData) {
			sessionStorage.setItem('sankeyData', JSON.stringify(sankeyData));
			window.open('/sankey_diagram_view', '_blank');
		} else {
			alert('データがありません');
		}
	};

    return (
        <span>
            <Button type="button" onClick={openModal}>
                <ButtonInnerText>
                    {t('寄与率分析')}
                </ButtonInnerText>
            </Button>

            <LoadingDialog
                open={isLoadingDialogOpen}
                onClose={closeLoadingDialog}
                loading={contributionAnalyzeLoading}
                loading_message={daialogLoadingMessage}
				result_message={daialogMessage}
            />

            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <SectionTitle>{t('寄与率分析')}</SectionTitle>
                    <Label>
                        {result_type === "lci" && (
                            <SearchSelect
                                value={exchangeNames[selectedExchangeIndex] || null}
                                options={exchangeNames}
                                onChange={handleExchangeChange}
                                placeholder={t('Select an exchange')}
                                isClearable={false}
                            />
                        )}
                        {result_type === "lcia" && (
                            <SearchSelect
                                value={lciaModelNames[selectedExchangeIndex] || null}
                                options={lciaModelNames}
                                onChange={handleExchangeChange}
                                placeholder={t('Select an LCIA model')}
                                isClearable={false}
                            />
                        )}
                    </Label>
                    <Dl>
                        <Dt>{t('表示する最小の寄与率')}</Dt>
                        <Dd>
                            <Input
                                type="number"
                                max="100"
                                min="1"
                                value={maxPercentage}
                                onChange={handleMaxPercentageChange}
                            />
                            <span>%</span>
                        </Dd>
                    </Dl>
                    <Button onClick={handleClickAnalyze}>
                        <ButtonInnerText>{t('分析')}</ButtonInnerText>
                    </Button>
                    
                    {sankeyData && 
                    <>
                        <Button onClick={handleOpenSankeyDiagram}>
                            <ButtonInnerText>{t('グラフを別タブで開く')}</ButtonInnerText>
                        </Button>
                        <PlotlySankeyDiagram data={sankeyData} />
                    </>
                    }
                </Inner>
            </Modal>
        </span>
    );
};

export default CaseStudyContributionAnalysisModal;

const Inner = styled.div`
    width: 100%;
    padding: 50px 20px 0px 20px;
    margin: 0 auto;
`;

const SectionTitle = styled.h2`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`;

const Label = styled.label`
    width: min(100%, 470px);
    position: relative;
    display: block;
    cursor: pointer;
`;

const Dl = styled.dl`
    display: grid;
    gap: 8px 40px;
    grid-template-columns: 25% 70%;
    margin-top: 10px;
`;

const Dt = styled.dt`
    font-size: 16px;
    font-weight: 500;
    padding: 12px 0;
`;

const Dd = styled.dd`
    font-size: 12px;
    font-weight: 500;
`;

const Input = styled.input`
    background-color: #fff;
    width: 15%;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`;

const Button = styled.button`
    margin-right: 20px;
    background-color: var(--color-site-secondary);
    border-radius: 4px;
    border: 1px solid var(--color-site-secondary);
    padding: 7px 16px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font: inherit;
    outline: none;

    &:hover {
        background-color: #fff;
        text-decoration: none;

        span {
            color: var(--color-site-secondary);
        }
    }
`;

const ButtonInnerText = styled.span`
    color: #fff;
    font-size: 13px;
    font-weight: 500;
`;
