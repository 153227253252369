import React, { useContext } from 'react';
import styled from 'styled-components';
import Modal from '@common/modal/Modal';
import { useTranslation } from 'react-i18next';
import { ProcessViewContext } from '@pages/process/ProcessView';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';


interface LciaFactorAnalysisProps {
    isModalOpen: boolean,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    amount: number,
    groupingName?: string, //  グルーピング名
    groupingKeyId?:number,
    subGroupingName?: string, //  サブグルーピング名
    subGroupingKeyId?:number

}

/**
 * LCIA 要因分析
 * @returns 
 */
const LciaFactorAnalysisModal: React.FC<LciaFactorAnalysisProps> = ({ 
    isModalOpen,
    setIsModalOpen,
    amount,
    groupingName,
    groupingKeyId,
    subGroupingName,
    subGroupingKeyId
 }) => {

    const { t } = useTranslation();
    const processViewContext = useContext(ProcessViewContext);
    const caseStudyViewContext = useContext(CaseStudyViewContext);

    const userData = processViewContext.userData || caseStudyViewContext.userData;

    // モーダル操作
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <>
            {userData?.is_admin &&
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <Inner>
                        <SectionTitle>
                            <Title>
                                [SystemTest]LCIA 要因分析
                            </Title>
                        </SectionTitle>
                        <Section>
                            <SectionText>
                                {groupingName && groupingName + " "}
                                {subGroupingName && subGroupingName}
                            </SectionText>
                        </Section>
                        <Section>
                            <SectionText>
                                {amount}
                            </SectionText>
                        </Section>
                    </Inner>
                </Modal>
            }
        </>
    );
};

export default LciaFactorAnalysisModal;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`;

const Title = styled.h3`
    font-size: 18px;
    font-weight: 500;
`

const Section = styled.section`
    margin-bottom: 32px;
    display: block;
`

const SectionTitle = styled.div`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    display: flex;
    justify-content: space-between;
`

const SectionText = styled.span`
    font-size: 13px;
    font-weight: 500;
`