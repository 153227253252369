import React, { lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { getCurrentUserInfo } from '@services/Cookies';
import Header from '@common/header/Header';
import SidebarMenu from '@common/sidebar/SidebarMenu';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './i18n';
import { GlobalStyle } from '@styles/Common';
import { FadeLoader } from 'react-spinners';

// 遅延ロードするコンポーネント
const MyPage = lazy(() => import('@pages/MyPage'));
const LogIn = lazy(() => import('@pages/LogIn'));
const ProcessView = lazy(() => import('@pages/process/ProcessView'));
const CaseStudyView = lazy(() => import('@pages/case_study/CaseStudyView'));
const UserManagement = lazy(() => import('@pages/admin/UserManagement'));
const MasterExportModal = lazy(() => import('@specific/admin/MasterExportModal'));
const SharedFileManagement = lazy(() => import('@pages/admin/SharedFileManagement'))
const TabDisplayBySanKeyDiagram = lazy(() => import('@pages/TabDisplayBySankeyDiagram'))
const NotFound = lazy(() => import('@pages/NotFound'));

const App = () => {
  // 特定のパスの場合はヘッダーとサイドバーを非表示にする
  const HeaderAndSideberMenuWrapper: React.FC = () => {
    const location = useLocation();
    if (location.pathname === '/login' || location.pathname === '/sankey_diagram_view') {
      return null;
    }
    return (
      <>
        <Header />
        <SidebarMenu />
      </>
    );
  };

  // 認証確認
  const RequireAuth = (props: any) => {
    const currentUser = getCurrentUserInfo();
    if (currentUser) {
      return props.component;
    }
    // 権限がない場合、ログインページへリダイレクト
    document.location = "/login";
  };

  // 非認証確認
  const RequireNoAuth = (props: any) => {
    const currentUser = getCurrentUserInfo();
    if (!currentUser) {
      return props.component;
    }
    // 認証済みの場合マイページへリダイレクト
    document.location = "/";
  };

  // 管理者確認
  const RequireAdmin = (props: any) => {
    const currentUser = getCurrentUserInfo();
    if (!currentUser) {
      document.location = "/login";
    }
    if (currentUser?.is_admin) {
      return props.component;
    }
    document.location = "/";
  };

  return (
    <Router>
      <DndProvider backend={HTML5Backend}>
        <GlobalStyle />
        <HeaderAndSideberMenuWrapper />
        <Suspense fallback={<FadeLoader color="#48bdbb" />}>
          <Routes>
            <Route path="/login" element={<RequireNoAuth component={<LogIn />} />} />
            <Route path="/" element={<RequireAuth component={<MyPage />} />} />
            <Route path="/process/process_view/:id" element={<RequireAuth component={<ProcessView />} />} />
            <Route path="/case_study/case_study_view/:id" element={<RequireAuth component={<CaseStudyView />} />} />
            <Route path="/admin/user_management" element={<RequireAdmin component={<UserManagement />} />} />
            <Route path="/admin/master_export_model" element={<RequireAdmin component={<MasterExportModal />} />} />
            <Route path="/admin/shared_file_management" element={<RequireAdmin component={<SharedFileManagement />} />} />
            <Route path="/sankey_diagram_view" element={<RequireAdmin component={<TabDisplayBySanKeyDiagram />} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </DndProvider>
    </Router>
  );
};

export default App;
