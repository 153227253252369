// 汎用的なAPI関数
import { removeCurrentDefaultDatabasePackId, removeCurrentUserInfo, removei18next } from '@services/Cookies';
import axios from 'axios';

const baseURL: string | undefined = process.env.REACT_APP_BACKEND_API_URL;

const Axios = () => {
  const axiosInstance = axios.create({
    baseURL: baseURL, 
    headers: {
      "accept": 'application/json',
      "content-type": "application/json"
    },
    withCredentials: true,
  });

  // レスポンスインターセプターで共通処理を実行
  axiosInstance.interceptors.response.use(
    response => {
      // レスポンスが正常な場合の処理
      return response;
    },
    async error => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          // トークン有効期間切れによる認証失敗時にリフレッシュトークンを発行
          await axios.post(`${baseURL}/refresh`, {}, {
            headers: {
              "accept": 'application/json',
            },
            withCredentials: true 
          });
          console.log('Refresh token create');
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // リフレッシュトークンも無効な場合の処理
          console.error('Refresh token is invalid', refreshError);
          removeCurrentUserInfo();
          removei18next();
          removeCurrentDefaultDatabasePackId();
          window.location.href = '/login';
        }
      }

      // error.responseが存在しない場合のエラーハンドリング
      if (!error.response) {
        console.error('Network error or server is down', error);
      }
      
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const refreshToken = async() =>{
  try {
    // リフレッシュトークンを発行
    await axios.post(`${baseURL}/refresh`, {}, {
      headers: {
        "accept": 'application/json',
      },
      withCredentials: true 
    });
    console.log('Refresh token create');
  } catch (refreshError) {
    // リフレッシュトークン無効な場合の処理
    console.error('Refresh token is invalid', refreshError);
    removeCurrentUserInfo();
    removei18next();
    removeCurrentDefaultDatabasePackId();
    window.location.href = '/login';
  }
}


// GET
export const getRequest = async <T>(url: string, params?: object)=> {
  const axiosInstance =  Axios();
  const response = await axiosInstance.get<T>(baseURL + url, { params, withCredentials: true });
  return response.data;
};

// POST
export const postRequest = async <T>(url: string, params?: object) => {
  const axiosInstance =  Axios();
  const response = await axiosInstance.post<T>(baseURL + url,  params, { withCredentials: true});
  return response.data;
};

// PUT
export const putRequest = async <T>(url: string, params?: object): Promise<T> => {
  const axiosInstance =  Axios();
  const response = await axiosInstance.put<T>(baseURL + url,　params, { withCredentials: true });
  return response.data;
};

// DELETE
export const deleteRequest = async <T>(url: string): Promise<T> => {
  const axiosInstance =  Axios();
  const response = await axiosInstance.delete<T>(baseURL + url, {withCredentials: true });
  return response.data;
};

// 画像アップロード用のPOSTリクエスト
export const uploadFileRequest = async <T>(url: string, file: File): Promise<T> => {
  const axiosInstance = Axios();
  const formData = new FormData();
  formData.append('file', file);

  const response = await axiosInstance.post<T>(baseURL + url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true
  });
  
  return response.data;
};


/**
 * ファイルをサーバーにインポートするリクエスト
 * @param url APIエンドポイントのURL
 * @param file アップロードするファイル
 * @returns レスポンス
 */
export const importFileRequest = async <T>(url: string, file: File): Promise<T> => {
  const axiosInstance = Axios();
  const formData = new FormData();

  // フィールド名を 'upload_file' に修正
  formData.append('upload_file', file);

  const response = await axiosInstance.post<T>(baseURL + url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true
  });

  return response.data;
};



// ファイルダウンロード用のGETリクエスト
export const downloadFileRequest = async <T>(url: string, params?: object)=> {
  const axiosInstance =  Axios();
  const response = await axiosInstance.get<T>(baseURL + url, { params, withCredentials: true, responseType: 'arraybuffer' });
  return response.data;
};

// ファイルダウンロード用のGETリクエスト blobとして取得
export const getAttachedFilesRequest = async <T>(url: string, params?: object) => {
  const axiosInstance = Axios();

  // responseType を 'blob' に設定してファイルデータを取得
  const response = await axiosInstance.get(baseURL + url, {
    params,
    withCredentials: true,
    responseType: 'blob', // ここでファイルとしてレスポンスを取得する
  });

  // レスポンスヘッダーを確認
  const headers = response.headers;
  console.log("response.headers", headers);

  // Blob としてデータを返す
  return response.data;
};


// ファイルダウンロード用のGETリクエスト blobとheader取得
export const getAttachedFilesRequestWithHeader = async <T>(url: string, params?: object) => {
  const axiosInstance = Axios();

  // responseType を 'blob' に設定してファイルデータを取得
  const response = await axiosInstance.get(baseURL + url, {
    params,
    withCredentials: true,
    responseType: 'blob', // ファイルとしてレスポンスを取得
  });

  // レスポンス全体を返す
  return {
    data: response.data, // Blob データ
    headers: response.headers, // レスポンスヘッダー
  };
};