import React, { useContext, useEffect, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import InputElementaryFlowSearchModal from '../elementary_flow_search/InputElementaryFlowSearchModal'
import InputElementaryFlowEditModal from '../input_elementary_flow/InputElementaryFlowEditModal'
import InputIntermediateFlowEditModal from '../input_Intermediate_flow/InputIntermediateFlowEditModal'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { ProcessIosType, UpdateProcess } from '@typeList/types'
import OutputElementaryFlowEditModal from '../output_elementary_flow/OutputElementaryFlowEditModal'
import InputIntermediateFlowProductSearchModal from '../product_search/input_Intermediate_flow/InputIntermediateFlowProductSearchModal'
import { ProcessViewContext } from '@pages/process/ProcessView'
import InputIntermediateFlowEditDataTable from '@common/table/InputIntermediateFlowEditDataTable'
import OutputIntermediateFlowProductSearchModal from '../product_search/output_Intermediate_flow/OutputIntermediateFlowProductSearchModal'
import OutputIntermediateFlowEditDataTable from '@common/table/OutputIntermediateFlowEditDataTable'
import InputElementayFlowEditDataTable from '@common/table/InputElementayFlowEditDataTable'
import OutputElementayFlowEditDataTable from '@common/table/OutputElementayFlowEditDataTable'
import OutputElementaryFlowSearchModal from '../elementary_flow_search/OutputElementaryFlowSearchModal'
import OutputIntermediateFlowEditModal from '../output_Intermediate_flow/OutputIntermediateFlowEditModal'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components';

import iconArrowTop from '@images/table/icon_arrow_top.svg'
import iconRemainder from '@images/table/icon_remainder.svg'
import { IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useGetRefereedProcessInputs } from '@hooks/useBackendApi'
import RefereedProcessInputsCell from '../io_info/RefereedProcessInputsCell'
import { formatNumber } from '@utils/utils'

interface FormProps {
    register: UseFormRegister<UpdateProcess>;
    errors: FieldErrors<UpdateProcess>;
}

const allocationList = [
    { value: "allocation", label: '配分' },
    { value: "disposal", label: '廃棄' },
    { value: "alternative", label: '代替' },
    { value: "cutoff", label: 'カットオフ' },
];

/**
 * 006 プロセス閲覧画面(入出力情報)タブ
 * @param param0 
 * @returns 
 */
const IOInfo: React.FC<FormProps> = ({ register, errors }) => {
    const { t } = useTranslation();

    const context = useContext(ProcessViewContext);
    const { userData } = context;

    const navigate = useNavigate();

    // 川上側プロセス
    const hundleUpperProcess = async (upper_output_id: number) => {
        const isConfirmed = window.confirm(t('上流プロセスへ移動します。よろしいですか？'));
        if (isConfirmed) {
            window.location.href = `/process/process_view/${upper_output_id}`;
        }
    }

    // 入力中間フロー
    const FlowColumnHelper = createColumnHelper<ProcessIosType>()
    const inputIntermediateFlowColumns = [
        FlowColumnHelper.accessor('upper_output.process.name', {
            header: () => t('川上側プロセス'),
            cell: info => {
                const value = info.renderValue();
                if (!value || value === "") {
                    return (
                        <ArrowTopButtonWrap>
                            <ArrowTopButton type='button'>
                                <ArrowTopButtonInner>
                                    <RemainderIcon />
                                </ArrowTopButtonInner>
                            </ArrowTopButton>
                        </ArrowTopButtonWrap>
                    )
                }
                return (
                    <ArrowTopButtonWrap>
                        <ArrowTopButton type='button' onClick={() => { hundleUpperProcess(info.row.original.upper_output?.process.id || 0) }}>
                            <ArrowTopButtonInner>
                                <ArrowTopIcon />
                            </ArrowTopButtonInner>
                        </ArrowTopButton>
                        {value}
                    </ArrowTopButtonWrap>
                )
            }
        }),
        FlowColumnHelper.accessor('exchange.name', {
            header: () => t('製品名'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('amount', {
            header: () => t('流量'),
            cell: info => { 
                if(userData) {
                    return formatNumber(info.renderValue() || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                } else {
                    return info.renderValue(); // デフォルトではそのままの値を表示
                }
            },
        }),
        FlowColumnHelper.accessor('unit.name', {
            header: () => t('単位'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('public_comment', {
            header: () => t('公開コメント'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('private_comment', {
            header: () => t('プライベートコメント'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('compatibility_level', {
            header: () => t('適合度'),
            cell: info => {
                const value = info.renderValue();
                if (value === 1) {
                    return <>{t('よい')}</>;
                } else if (value === 2) {
                    // 適合度が1でない場合の処理
                    return <>{t('普通')}</>;
                } else if (value === 3) {
                    // 適合度が1でない場合の処理
                    return <>{t('悪い')}</>;
                }
            }
        }),
        FlowColumnHelper.accessor('compatible_product_name', {
            header: () => t('情報源のフロー名'),
            cell: info => info.renderValue(),
        }),
    ]

    // 入力基本フロー
    const inputElementaryFlowColumns = [
        FlowColumnHelper.accessor('exchange.name', {
            header: () => t('基本フロー名'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('exchange.category1_name', {
            header: () => t('カテゴリ1'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('exchange.category2_name', {
            header: () => t('カテゴリ2'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('exchange.category3_name', {
            header: () => t('カテゴリ3'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('amount', {
            header: () => t('流量'),
            cell: info => { 
                if(userData) {
                    return formatNumber(info.renderValue() || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                } else {
                    return info.renderValue(); // デフォルトではそのままの値を表示
                }
            },
        }),
        FlowColumnHelper.accessor('unit.name', {
            header: () => t('単位'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('public_comment', {
            header: () => t('公開コメント'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('private_comment', {
            header: () => t('プライベートコメント'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('compatibility_level', {
            header: () => t('適合度'),
            cell: info => {
                const value = info.renderValue();
                if (value === 1) {
                    return <>{t('よい')}</>;
                } else if (value === 2) {
                    // 適合度が1でない場合の処理
                    return <>{t('普通')}</>;
                } else if (value === 3) {
                    // 適合度が1でない場合の処理
                    return <>{t('悪い')}</>;
                }
            }
        }),
        FlowColumnHelper.accessor('compatible_product_name', {
            header: () => t('情報源のフロー名'),
            cell: info => info.renderValue(),
        }),
    ]

    const { getRefereedProcessInputs, refereedProcessInputsData } = useGetRefereedProcessInputs();

    // 出力中間フロー
    const outputIntermediateFlowColumns = [
        FlowColumnHelper.accessor('exchange.name', {
            header: () => t('製品名'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('amount', {
            header: () => t('流量'),
            cell: info => { 
                if(userData) {
                    return formatNumber(info.renderValue() || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                } else {
                    return info.renderValue(); // デフォルトではそのままの値を表示
                }
            },
        }),
        FlowColumnHelper.accessor('unit.name', {
            header: () => t('単位'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('treatment.type', {
            header: () => t('取扱方法'),
            cell: info => {
                const value = info.renderValue();
                return t(allocationList.find(option => option.value === value)?.label || "")
            }
        }),
        FlowColumnHelper.accessor('id', {
            header: () => t('参照数'),
            cell: info => (
                <RefereedProcessInputsCell
                    id={info.row.original.id}
                    value={null}
                    getRefereedProcessInputs={getRefereedProcessInputs}
                />
            ),
        }),
        FlowColumnHelper.accessor('public_comment', {
            header: () => t('公開コメント'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('private_comment', {
            header: () => t('プライベートコメント'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('compatibility_level', {
            header: () => t('適合度'),
            cell: info => {
                const value = info.renderValue();
                if (value === 1) {
                    return <>{t('よい')}</>;
                } else if (value === 2) {
                    return <>{t('普通')}</>;
                } else if (value === 3) {
                    return <>{t('悪い')}</>;
                }
            }
        }),
        FlowColumnHelper.accessor('compatible_product_name', {
            header: () => t('情報源のフロー名'),
            cell: info => info.renderValue(),
        }),
    ];


    // 出力基本フロー
    const outputElementaryFlowColumns = [
        FlowColumnHelper.accessor('exchange.name', {
            header: () => t('基本フロー名'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('exchange.category1_name', {
            header: () => t('カテゴリ1'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('exchange.category2_name', {
            header: () => t('カテゴリ2'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('exchange.category3_name', {
            header: () => t('カテゴリ3'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('amount', {
            header: () => t('流量'),
            cell: info => { 
                if(userData) {
                    return formatNumber(info.renderValue() || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)
                } else {
                    return info.renderValue(); // デフォルトではそのままの値を表示
                }
            },
        }),
        FlowColumnHelper.accessor('unit.name', {
            header: () => t('単位'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('public_comment', {
            header: () => t('公開コメント'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('private_comment', {
            header: () => t('プライベートコメント'),
            cell: info => info.renderValue(),
        }),
        FlowColumnHelper.accessor('compatibility_level', {
            header: () => t('適合度'),
            cell: info => {
                const value = info.renderValue();
                if (value === 1) {
                    return <>{t('よい')}</>;
                } else if (value === 2) {
                    // 適合度が1でない場合の処理
                    return <>{t('普通')}</>;
                } else if (value === 3) {
                    // 適合度が1でない場合の処理
                    return <>{t('悪い')}</>;
                }
            }
        }),
        FlowColumnHelper.accessor('compatible_product_name', {
            header: () => t('情報源のフロー名'),
            cell: info => info.renderValue(),
        }),
    ]


    return (
        <>
            <SectionTable>
                <SectionTableWrap>
                    <TableCaption>{t('入力中間フロー')}</TableCaption>
                    <InputIntermediateFlowEditDataTable columns={inputIntermediateFlowColumns} data={context.inProduct ?? []} paging_flag={false} ModalComponent={InputIntermediateFlowEditModal} />
                </SectionTableWrap>
                <InputIntermediateFlowProductSearchModal type={"io_info"} />
            </SectionTable>

            <SectionTable>
                <SectionTableWrap>
                    <TableCaption>{t('入力基本フロー')}</TableCaption>
                    <InputElementayFlowEditDataTable columns={inputElementaryFlowColumns} data={context.inElementaryFlow ?? [{}]} paging_flag={false} ModalComponent={InputElementaryFlowEditModal} />
                </SectionTableWrap>
                <InputElementaryFlowSearchModal />
            </SectionTable>

            <SectionTable>
                <SectionTableWrap>
                    <TableCaption>{t('出力中間フロー')}</TableCaption>
                    <OutputIntermediateFlowEditDataTable columns={outputIntermediateFlowColumns} data={context.outProduct ?? [{}]} paging_flag={false} ModalComponent={OutputIntermediateFlowEditModal} />
                </SectionTableWrap>
                <OutputIntermediateFlowProductSearchModal />
            </SectionTable>

            <SectionTable>
                <SectionTable>
                    <TableCaption>{t('出力基本フロー')}</TableCaption>
                    <OutputElementayFlowEditDataTable columns={outputElementaryFlowColumns} data={context.outElementaryFlow ?? [{}]} paging_flag={false} ModalComponent={OutputElementaryFlowEditModal} />
                </SectionTable>
                <OutputElementaryFlowSearchModal />
            </SectionTable>
        </>
    )
}

export default IOInfo

const SectionTable = styled.div`
    margin-bottom: 32px;
  
`

const SectionTableWrap = styled.div`
    overflow-x: auto;    
`

const Table = styled.table`
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 8px;
    background-color: #fff;  
    border-spacing: 0;  
`

const TableCaption = styled.p`
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    text-align: left;
    padding-bottom: 8px; 
`

const ArrowTopButtonWrap = styled.span`
width: fit-content;
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
gap: 0 8px;
`

const ArrowTopButton = styled.button`
cursor: pointer;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
color: inherit;
padding: 0;
`

const ArrowTopButtonInner = styled.div`
`
const ArrowTopIcon = styled.span`
mask: url(${iconArrowTop}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowTop}) no-repeat center center / contain;
display: block;
width: 30px;
height: 30px;
background: var(--color-txt-primary);
`

const RemainderIcon = styled.span`
mask: url(${iconRemainder}) no-repeat center center / contain;
-webkit-mask: url(${iconRemainder}) no-repeat center center / contain;
display: block;
width: 30px;
height: 30px;
background: var(--color-txt-primary);
`