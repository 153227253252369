import React from 'react';
import Plot from 'react-plotly.js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getRandomColorFromColorPalette } from '@utils/colorUtils';

interface SankeyDiagramProps {
    data: {
        nodes: { label: string; color?: string }[];
        links: { source: number; target: number; value: number; color?: string }[];
    };
    width?: number;
    height?: number;
}

const PlotlySankeyDiagram: React.FC<SankeyDiagramProps> = ({ data, width = 1200, height = 800 }) => {
    const { t } = useTranslation();

    // ラベルの文字列を10文字ごとに折り返す
    const wrapLabel = (label: string, length: number = 18) => {
        return label.replace(/(.{18})/g, '$1<br>');
    };

    // Sankey Diagram 用のデータ構造を生成
    const plotlyData = [
        {
            type: 'sankey',
            orientation: 'h',
            node: {
                pad: 15,
                thickness: 20,
                line: {
                    color: '#000000',
                    width: 0.5,
                },
                // label: data.nodes.map((node) => wrapLabel(node.label)),
                label: data.nodes.map((node) => wrapLabel(node.label)),
                color: data.nodes.map((node) => '#7c8285'),
                customdata: data.nodes.map((node) => node.label.replace(/<br>/g, '')), // hovertemplate用に改行を除去したデータを追加
                hovertemplate: '%{customdata}<extra></extra>', 
            },
            link: {
                source: data.links.map((link) => link.source),
                target: data.links.map((link) => link.target),
                value: data.links.map((link) => link.value),
                color: data.links.map((link) => link.color),
                hovertemplate: '<b>source</b>: %{source.customdata}<br><b>target</b>: %{target.customdata}<br><b>value</b>: %{value:.16f}<extra></extra>'
            },
        },
    ];

    return (
        <Section>
            <Plot
                data={plotlyData}
                layout={{
                    //   title: t('Sankey Diagram'),
                    font: { size: 12 },
                    width,
                    height,
                    hoverlabel: {
                        align: 'left', // すべてのホバーテキストを左寄せに設定
                    },
                }}
                config={{
                    displayModeBar: true,
                    displaylogo: false, // Plotly ロゴリンクを非表示
                    modeBarButtonsToRemove: [
                        'zoom2d', // 2Dズームボタン
                        'pan2d', // パンボタン
                        'select2d', // 選択ボタン
                        'lasso2d', // ラッソ選択ボタン
                        // 'toImage', // 画像保存ボタン
                    ],
                    responsive: true
                }}
            />
        </Section>
    );
};

export default PlotlySankeyDiagram;

const Section = styled.div`
    margin-top: 10px;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
`;
