import UnitSelectModal from '@common/UnitSelectModal';
import Modal from '@common/modal/Modal';
import { useCreateProduct, useFileUpload, useGetIdentifierSchemes, useGetUnitGroupsCurrency, useGetUserDetail } from '@hooks/useBackendApi';
import { getCurrentDefaultDatabasePackId, getCurrentUserInfo } from '@services/Cookies';
import { RequiredSpan } from '@styles/Common';
import { CreateProduct, CreateProductIdentifierSchemeValues, UnitConversions, UnitGroupList } from '@typeList/types';
import { getValueByKey } from '@utils/utils';
import React, { createContext, useEffect, useRef, useState, useCallback, Dispatch, SetStateAction } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import styled from 'styled-components';
import ProductCategrySelect from './ProductCategrySelect';
import UnitConvertingSettingModal from '../unit_converting_setting/UnitConvertingSettingModal';
import { useTranslation } from 'react-i18next';
import ModalButton from '@common/button/ModalButton';

import iconAdd from '@images/product_system/icon_add.svg'
import iconSub from '@images/table/icon_arrow_up.svg'
import iconImg from '@images/file/icon_file_upload.svg'
import iconCross from '@images/table/icon_cross.svg';

// 製品作成コンテキスト
export interface CreateProductContext {
    inputDatabasePackId: number | undefined;
    databasePacks: any[],
    inputDatabasePackName: string;
    inputCategoryId: number | undefined;
    inputCategoryValueId: number | undefined;
    categoryIds: Array<{ id: number, name: string }> | null | undefined;
    ids: Array<string> | null | undefined;
    setIds: Dispatch<SetStateAction<Array<string> | null | undefined>>;
    setInputCategoryId: Dispatch<SetStateAction<number | undefined>>;
    setInputCategoryValueId: Dispatch<SetStateAction<number | undefined>>;
    setCategoryIds: Dispatch<SetStateAction<Array<{ id: number, name: string }> | null | undefined>>;
    setInputDatabasePackName: Dispatch<SetStateAction<string>>;
  }


const defaulutContextvalue: CreateProductContext = {
    inputDatabasePackId: 1,
    databasePacks: [],
    inputDatabasePackName: "",
    inputCategoryId: 1,
    inputCategoryValueId: 0,
    categoryIds: null,
    ids: [""],
    setIds: () => { },
    setInputCategoryId: () => { },
    setInputCategoryValueId: () => { },
    setCategoryIds: () => { },
    setInputDatabasePackName: () => { },
}

export const productCreateContext = createContext<CreateProductContext>(defaulutContextvalue);

interface ProductCreateType {
    selectted_database_pack_id: number | null | undefined;
}

/**
 * 016 製品の作成
 * 
 * @param selectted_database_pack_id 
 * @returns 
 */
const ProductCreateModal: React.FC<ProductCreateType> = ({ selectted_database_pack_id }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCollapseOpen, setIsCollapseOpen] = useState(false)
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const onIsClickcollapseOpen = () => setIsCollapseOpen((prev) => !prev);

    const id = getCurrentUserInfo()?.id;
    const locale = getCurrentUserInfo()?.default_locale_code;
    const { userData } = useGetUserDetail(id);
    const [databasePacks, setDatabasePacks] = useState<any[]>([]);
    const [inputDatabasePackName, setInputDatabasePackName] = useState("");
    const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(0);

    const { register, handleSubmit, setValue, formState: { errors }, control } = useForm<CreateProduct>({
        defaultValues: {
            category_value_ids: []
        }
    });

    // ProductCategrySelectコンポーネントの参照を作成
    const productCategrySelectRef = useRef<{ getCategoryValueIds: () => number[] }>(null);

    useEffect(() => {

        if (userData?.licenses) {
            const filteredDatabasePacks = userData?.licenses
                .filter(license => !license.is_inactive) // is_inactive = false のみを対象
                .map(license => ({
                    id: license.role.database_pack.id,
                    name: license.role.database_pack.name,
                }));

            // id の重複を Set で除去し、再度配列に戻す
            const uniqueDatabasePacks = Array.from(new Map(
                filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
            ).values());

            setDatabasePacks(uniqueDatabasePacks);
            setInputDatabasePackId(getCurrentDefaultDatabasePackId() ?? uniqueDatabasePacks[0].id);
        }
    }, [userData]);

    useEffect(() =>{
        if(inputDatabasePackId){
            setInputDatabasePackName(getValueByKey(databasePacks, 'id', Number(inputDatabasePackId), 'name'))
        }
    },[databasePacks, inputDatabasePackId])

    useEffect(() => {
        if(getCurrentDefaultDatabasePackId()){
            setInputDatabasePackId(getCurrentDefaultDatabasePackId())
        }
    }, [getCurrentDefaultDatabasePackId()])

    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        if (key === 'database_pack_id') {
            setInputDatabasePackId(Number(event.target.value));
            setInputDatabasePackName(getValueByKey(databasePacks, 'id', Number(event.target.value), 'name'));
        }
        if (key === 'currency') {
            setSelectCurrencyData(Number(event.target.value));
        }
    };

    const [inputUnitId, setInputUnitId] = useState<number>(0);
    const [inputUnitName, setInputUnitName] = useState<string>("");

    useEffect(() => {
        setValue('unit_id', inputUnitId);
    }, [inputUnitId, setValue]);

    // カテゴリをセット
    const [inputCategoryId, setInputCategoryId] = useState<number | undefined>(1);
    const [inputCategoryValueId, setInputCategoryValueId] = useState<number | undefined>(1);
    const [categoryIds, setCategoryIds] = useState<Array<{ id: number, name: string }> | null | undefined>();
    const [ids, setIds] = useState<Array<string> | null | undefined>([String(inputCategoryValueId)]);
    const [categorySelectComponents, setCategorySelectComponents] = useState([<ProductCategrySelect />]);

    // 単位換算をセット
    const [unitConversions, setUnitConversions] = useState<UnitConversions[]>([]);
    const [unitConversionsCode, setUnitConversionsCode] = useState<string>("");
    useEffect(() => {
        setValue('unit_conversions', unitConversions);
    }, [unitConversions, setValue]);


    // ID体系
    const {getIdentifierSchemes, identifierSchemesData} = useGetIdentifierSchemes();
    useEffect(() =>{
        if(isModalOpen) {
            try {
                getIdentifierSchemes();
            } catch (error) {
                console.log(error);
            }
        }
    },[isModalOpen])

    // ID体系データ用の状態
    const [identifierSchemeValues, setIdentifierSchemeValues] = useState<{ id: number; name: string; value: string }[]>([]);

    // ID体系データを取得後に設定
    useEffect(() => {
        if (isModalOpen && identifierSchemesData) {
            const schemes = identifierSchemesData.map((scheme) => ({
                id: scheme.id,
                name: scheme.name,
                value: "",
            }));
            setIdentifierSchemeValues(schemes);
        }
    }, [isModalOpen, identifierSchemesData]);

    // ID入力変更時のハンドラー
    const handleIdentifierSchemeChange = (id: number, value: string) => {
        setIdentifierSchemeValues((prevValues) =>
            prevValues.map((scheme) =>
                scheme.id === id ? { ...scheme, value } : scheme
            )
        );
    };

    // エラーメッセージ
    const [errorMsg, setErrorMsg] = useState<any>("");

    //　画像アップロード
    const { fileUpload, fileUploadData } = useFileUpload();

    // 登録処理
    const { createProduct, createProductLoading } = useCreateProduct();

    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() =>{
        if(isModalOpen){
            setIsDisabled(false)
        }
    },[isModalOpen])

    useEffect(() => {
        if(createProductLoading){
            setIsDisabled(true);
        } 
    },[createProductLoading])

    // フォームの送信処理
    const onSubmit: SubmitHandler<CreateProduct> = async data => {
        if (isDisabled) return;

        try {
            setErrorMsg(null);

            // 画像
            let Files = [];
            // if (processData?.process_attached_files && processData?.process_attached_files.length > 0) {
            //   for (const fileData of processData?.process_attached_files) {
            //     Files.push({ id: fileData.id, is_technology_image: fileData.is_technology_image });
            //   }
            // }
            if (imageFile) {
                const response = await fileUpload(imageFile);
                Files.push({ id: response.id, is_technology_image: true });
            }

            if (!data.unit_price) {
                data.unit_price = null;
            }

            // ID体系
            const identifierSchemePayload = identifierSchemeValues
                .filter((scheme) => scheme.value)
                .map((scheme) => ({
                    identifier_scheme_id: scheme.id,
                    value: scheme.value,
                }));

            setValue("identifier_scheme_values", identifierSchemePayload);

            // カテゴリ値IDを取得し、フォームの状態を更新
            const categoryValueIds = productCategrySelectRef.current?.getCategoryValueIds() || [];
            // '0' (文字列) と 0 (数値) の両方を除去
            const filteredCategoryValueIds = categoryValueIds.filter(id => String(id) !== '0');

            // setValue関数にフィルタ済みのIDリストを渡す
            setValue('category_value_ids', filteredCategoryValueIds, { shouldValidate: true });

            // その他のフォームデータとともに製品作成を実行
            await createProduct(inputDatabasePackId, {
                ...data,
                category_value_ids: filteredCategoryValueIds,
                identifier_scheme_values: identifierSchemePayload,
                unit_price: data.unit_price,
                unit_price_currency_unit_id: selectCurrencyData || null,
                attached_file_ids: Files.map(item => item.id),
            });
            setIsModalOpen(false);
        } catch (error) {
            setErrorMsg(error);
        }
    };

    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageName, setImageName] = useState<string | null>(null);

    const [imageFile, setImageFile] = useState<File>();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            setImageName(file.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result as string);
                setImageFile(file);
            };
            reader.readAsDataURL(file);
        }
    };

    // 画像の削除ハンドラー
    const handleImageDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // デフォルト動作を防止
        event.stopPropagation(); // イベントの伝播を防止
        setImageSrc('');
        setPreviewImage('');
        setImageName('');
        setImageFile(undefined);
        // ファイル入力フィールドをリセット
        const fileInput = document.getElementById('fileImg_product') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = ''; // ファイル選択のリセット
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileImg')?.click();
    };

    // 単位プルダウン
    const { getUnitGroupsCurrency, unitGroupsCurrencyData } = useGetUnitGroupsCurrency();
    const [currencyDataList, setCurrencyDataList] = useState<UnitGroupList | null>(null);
    const [selectCurrencyData, setSelectCurrencyData] = useState<number | null>(null);
    useEffect(() => {
        if (userData && inputDatabasePackId) {
            setCurrencyDataList(null)
            try {
                getUnitGroupsCurrency(inputDatabasePackId);
            } catch (error) {
                console.log(error);
            }
        }
    }, [userData, inputDatabasePackId])
    useEffect(() => {
        setCurrencyDataList(unitGroupsCurrencyData);
    }, [unitGroupsCurrencyData])

    // ドロップダウンの初期選択設定
    useEffect(() => {
        if (userData?.default_currency_code && unitGroupsCurrencyData?.units) {
            const matchingUnit = unitGroupsCurrencyData.units.find(unit => unit.code === userData.default_currency_code);
            if (matchingUnit) {
                setSelectCurrencyData(matchingUnit.id);  // 該当するIDをセット
            }
        }
    }, [userData?.default_currency_code, unitGroupsCurrencyData]);


    return (
        <productCreateContext.Provider value={{
            inputDatabasePackId,
            databasePacks,
            inputDatabasePackName,
            inputCategoryId,
            inputCategoryValueId,
            categoryIds,
            ids,
            setIds,
            setInputCategoryId,
            setInputCategoryValueId,
            setCategoryIds,
            setInputDatabasePackName,
        }}>
            <StyledCreateButton onClick={openModal}>
                {t('新規追加')}
            </StyledCreateButton>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <Title>{t('製品新規追加')}</Title>
                    {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
                    <form>
                        <SectionBody>
                            <SectionColumn>
                                {databasePacks.length > 0 &&
                                    <>
                                        <SectionColumnWrap>
                                            <SectionColumnTitle>{t('データベースパック')}</SectionColumnTitle>
                                            <SectionColumnContent>
                                                <SelectPrimary>
                                                    <SelectInner
                                                        name="database-pack"
                                                        id="database-pack-select"
                                                        onChange={(event) => handleInputChangeSelect(event, 'database_pack_id')}
                                                        value={inputDatabasePackId ?? ''}
                                                    >
                                                        {databasePacks.map((database_pack, index) => (
                                                            <option key={`${database_pack.id}-${index}`} value={database_pack.id}>
                                                                {database_pack.name}
                                                            </option>
                                                        ))}
                                                    </SelectInner>
                                                </SelectPrimary>
                                            </SectionColumnContent>
                                        </SectionColumnWrap>
                                    </>
                                }
                                <input id='locale' {...register("locale")} hidden value={locale} readOnly />

                                <SectionColumnWrap>
                                    <SectionColumnTitle htmlFor="name">{t('名前')}<RequiredSpan>※</RequiredSpan></SectionColumnTitle>
                                    <SectionColumnContent>
                                        <InputPrimary id="name"  {...register("name", { required: true })} />
                                    </SectionColumnContent>
                                    {errors.name && <ErrorMsg>{t('名前は必須です')}</ErrorMsg>}
                                </SectionColumnWrap>

                                <SectionColumnWrap>
                                    <SectionColumnTitle htmlFor="public_comment">{t('説明')}</SectionColumnTitle>
                                    <SectionColumnContent>
                                        <InputPrimary id="public_comment" {...register("public_comment")} />
                                    </SectionColumnContent>
                                </SectionColumnWrap>

                                <SectionColumnWrap>
                                    <SectionColumnTitle htmlFor="private_comment">{t('プライベートコメント')}</SectionColumnTitle>
                                    <SectionColumnContent>
                                        <InputPrimary id="private_comment" {...register("private_comment")} />
                                    </SectionColumnContent>
                                </SectionColumnWrap>

                                <SectionColumnWrap>
                                    <SectionColumnTitle htmlFor="unit_id">{t('主単位')}<RequiredSpan>※</RequiredSpan></SectionColumnTitle>
                                    <SectionColumnContent>
                                        <input
                                            id="unit_id"
                                            type="hidden"
                                            {...register("unit_id", {
                                                required: true,
                                                validate: value => value !== 0
                                            })}
                                        />
                                        <InputPrimary type="text" disabled value={inputUnitName} readOnly />
                                        <UnitSelectModal link_text={t('参照')}
                                            inputDatabasePackName={inputDatabasePackName}
                                            setInputUnitId={setInputUnitId}
                                            inputDatabasePackId={inputDatabasePackId}
                                            setInputUnitName={setInputUnitName}
                                            editMode={false}
                                        />
                                        {errors.unit_id && <ErrorMsg>{t('単位は必須です')}</ErrorMsg>}
                                    </SectionColumnContent>
                                </SectionColumnWrap>
                                <SectionColumnWrap>
                                    <SectionColumnTitle htmlFor="unit_price">{t('単価')}</SectionColumnTitle>
                                    <SectionColumnContent>
                                        <SectionColumnContentText>{t('pricePerUnit', {inputUnitName })}</SectionColumnContentText>
                                        <InputPrimary type='number' id="unit_price" {...register("unit_price")} />
                                        {/* <InputPrimary type='text' readOnly /> */}
                                        <Select
                                            value={selectCurrencyData || 0}
                                            onChange={(event) => handleInputChangeSelect(event, 'currency')}
                                        >
                                            <option value="">{t('選択しない')}</option>
                                            {currencyDataList?.units.map((unit) => (
                                                <option key={unit.id} value={unit.id}>
                                                    {unit.name}
                                                </option>
                                            ))}
                                        </Select>

                                    </SectionColumnContent>
                                </SectionColumnWrap>
                                <SectionColumnWrapSecondary>
                                    <SectionColumnSecondaryTitle htmlFor="category_value_ids">{t('カテゴリ')}</SectionColumnSecondaryTitle>
                                    <input id="category_value_ids" value={JSON.stringify(ids)} hidden {...register("category_value_ids")} />
                                    {inputDatabasePackId &&
                                        <ProductCategrySelect ref={productCategrySelectRef} />
                                    }
                                </SectionColumnWrapSecondary>

                                <SectionColumnWrapSecondary>
                                    <SectionColumnSecondaryTitle>{t('ID体系')}</SectionColumnSecondaryTitle>
                                    <SectionColumnWrapTertiary>
                                        {identifierSchemeValues.map((scheme) => (
                                            <React.Fragment key={scheme.id}>
                                                <SectionColumnContentText>{scheme.name}</SectionColumnContentText>
                                                <InputPrimary
                                                    id={`identifier_scheme_${scheme.id}`}
                                                    onChange={(event) =>
                                                        handleIdentifierSchemeChange(
                                                            scheme.id,
                                                            event.target.value
                                                        )
                                                    }
                                                    value={scheme.value}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </SectionColumnWrapTertiary>
                                </SectionColumnWrapSecondary>

                                <SectionColumnWrap>
                                    <SectionColumnTitle htmlFor="unit_conversions">{t('別単位(換算)')}</SectionColumnTitle>
                                    <SectionColumnContent>
                                        <InputPrimary id="unit_conversions" value={JSON.stringify(unitConversions)} hidden {...register("unit_conversions")} />
                                        <InputPrimary type="text" value={unitConversionsCode} disabled readOnly />
                                        <UnitConvertingSettingModal
                                            inputDatabasePackId={inputDatabasePackId}
                                            inputUnitId={inputUnitId}
                                            inputUnitName={inputUnitName}
                                            setInputUnitId={setInputUnitId}
                                            setInputUnitName={setInputUnitName}
                                            setUnitConversions={setUnitConversions}
                                            unitConversions={unitConversions}
                                            setUnitConversionsCode={setUnitConversionsCode}
                                        />

                                    </SectionColumnContent>
                                </SectionColumnWrap>
                            </SectionColumn>
                        </SectionBody>
                        <SectionBody>
                            <SectionAcordionHeader onClick={onIsClickcollapseOpen}>
                                <AddIcon>
                                    <AddIconImage open={isCollapseOpen} />
                                </AddIcon>
                                <SectionAcordionTitle>{t('追加情報')}</SectionAcordionTitle>
                            </SectionAcordionHeader>
                            <SectionAcordion open={isCollapseOpen}>
                                <SectionAcordionContent>
                                    <SectionColumn>
                                        <SectionColumnWrap>
                                            <SectionColumnTitle htmlFor="cas_number">{t('CAS番号')}</SectionColumnTitle>
                                            <SectionColumnContent>
                                                <InputPrimary id="cas_number" {...register("cas_number")} />
                                            </SectionColumnContent>
                                            <SectionColumnTitle htmlFor="synonyms_text">{t('同義語')}</SectionColumnTitle>
                                            <SectionColumnContent>
                                                <InputPrimary id="synonyms_text" {...register("synonyms_text")} />
                                            </SectionColumnContent>
                                            <SectionColumnTitle htmlFor="include_items_text">{t('包含品目')}</SectionColumnTitle>
                                            <SectionColumnContent>
                                                <InputPrimary id="include_items_text" {...register("include_items_text")} />
                                            </SectionColumnContent>
                                            <SectionColumnTitle htmlFor="related_items_text">{t('関連項目')}</SectionColumnTitle>
                                            <SectionColumnContent>
                                                <InputPrimary id="related_items_text" {...register("related_items_text")} />
                                            </SectionColumnContent>

                                            <SectionColumnTitle>{t('画像名称')}</SectionColumnTitle>
                                            <SectionColumnContent>
                                                <DisableInput disabled type="text" value={imageName || ''} />
                                            </SectionColumnContent>
                                        </SectionColumnWrap>
                                        <FileDiv>
                                            <SectionColumnTitle>{t('技術の図')}</SectionColumnTitle>
                                            <SectionColumnContent>
                                                <FileWrap>
                                                    <FileInput type="file" id="fileImg_product" onChange={handleFileChange} />
                                                    <FileLabel htmlFor="fileImg_product" onClick={(e) => e.stopPropagation()}>
                                                        {previewImage ? (
                                                            <ImgWrapper>
                                                                <Img src={previewImage} width="100%" height="382px" alt={t('技術の図')} />
                                                                <DeleteButton onClick={handleImageDelete}>
                                                                    <FileDeleteIcon />
                                                                </DeleteButton>
                                                            </ImgWrapper>
                                                        ) : imageSrc ? (
                                                            <ImgWrapper>
                                                                <Img src={`data:image/png;base64,${imageSrc}`} width="100%" height="380px" alt={t('技術の図')} />
                                                                <DeleteButton onClick={handleImageDelete}>
                                                                    <FileDeleteIcon />
                                                                </DeleteButton>
                                                            </ImgWrapper>
                                                        ) : (
                                                            <Img src={iconImg} alt={t('技術の図')} />
                                                        )}
                                                    </FileLabel>
                                                </FileWrap>
                                            </SectionColumnContent>
                                        </FileDiv>
                                    </SectionColumn>
                                </SectionAcordionContent>
                            </SectionAcordion>
                        </SectionBody>
                        {/* <SectionBodyLast>
                            <SectionColumnTitle>{t('システム情報')}</SectionColumnTitle>
                            <SectionAcordionContent>
                                <SectionBody>
                                    <SectionColumn>
                                        <SectionColumnWrap>
                                            <SectionColumnWrapTitle htmlFor="created_at">作成日時</SectionColumnWrapTitle>
                                            <SectionColumnContent>
                                                <DisableInput id="created_at" disabled/>
                                            </SectionColumnContent>
                                        </SectionColumnWrap>
                                        <SectionColumnWrap>
                                            <SectionColumnWrapTitle htmlFor="updated_at">更新日時</SectionColumnWrapTitle>
                                            <SectionColumnContent>
                                                <DisableInput id="updated_at" disabled />
                                            </SectionColumnContent>
                                        </SectionColumnWrap>
                                        <SectionColumnWrap>
                                            <SectionColumnWrapTitle htmlFor="uuid">UUID</SectionColumnWrapTitle>
                                            <SectionColumnContent>
                                                <DisableInput id="uuid" disabled />
                                            </SectionColumnContent>
                                        </SectionColumnWrap>
                                        <SectionColumnWrap>
                                            <SectionColumnWrapTitle htmlFor="team_uuid">チームUUID</SectionColumnWrapTitle>
                                            <SectionColumnContent>
                                                <DisableInput id="team_uuid" disabled />
                                            </SectionColumnContent>
                                        </SectionColumnWrap>
                                    </SectionColumn>
                                </SectionBody>
                            </SectionAcordionContent>
                        </SectionBodyLast> */}
                        <ButtonCreateSectionTabel>
                            <ModalButton type="button" onClick={handleSubmit(onSubmit)} text={t('作成する')} disabled={isDisabled}/>
                        </ButtonCreateSectionTabel>
                    </form>

                </Inner>
            </Modal>
        </productCreateContext.Provider>
    );
};

export default ProductCreateModal;

const CreateButton = styled.button`
    background-color: #46A3B3;
    color: white;
    border: none ;
    margin-bottom: 5px ;
    cursor: pointer;
`;

const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`


const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const SectionBody = styled.div`
    margin-bottom: 60px;
`


const SectionBodyLast = styled.div`
`

const SectionColumn = styled.dl`
`

const SectionColumnWrap = styled.div`
    margin-bottom: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;    
`

const SectionColumnWrapSecondary = styled.div`
    margin-bottom: 8px;
    display: grid;
    align-items: stretch;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;    
`

const SectionColumnWrapTertiary = styled.dd`
    display: grid;
    gap: 8px 12px;
    grid-template-columns: 16.4705882353% 1fr;  
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
`


const SectionColumnTitle = styled.label`
    margin-bottom: 0;
    margin-top: calc(22.5px - 0.5em);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`


const SectionColumnSecondaryTitle = styled.label`
    margin-top: calc(22.5px - 0.5em);
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const SectionColumnContent = styled.dd`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;
`

const SectionAcordionContent = styled.dd`
    padding-top: 24px;
`


const SectionColumnContentText = styled.label`
    font-size: 12px;
    white-space: nowrap;
    font-weight: 500;
    line-height: 1.25;
`


const SectionRequired = styled.span`
    font-size: 0.7em;
    vertical-align: top;
    position: relative;
    top: 0.1em;
    color: var(--color-red--01);
`

const InputPrimary = styled.input`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const Select = styled.select`
    width: 100%;

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    color: rgb(85, 85, 85);
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`
const ButtonCreateSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`


const StyledCreateButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 7px 16px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;    
`
const SectionColumnWrapTitle = styled.label`
    margin-bottom: 0;   
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const DisableInput = styled.input`
    background-color: var(--color-bg-primary);
    width: 100%;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
   
`

const FileDiv = styled.div`
    align-items: stretch;
    display: grid;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;
`
const FileWrap = styled.div`
grid-column: 1 / 4;
width: 100%;
height: 382px;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: var(--color-bg-primary);
position: relative;
`

const FileInput = styled.input`
display: none;
`

const FileLabel = styled.label`
width: 100%;
height: 100%;
cursor: pointer;
display: grid;
place-items: center;
`
const Img = styled.img`
`

const SectionAcordion = styled.div<{ open: boolean }>`
  max-height: ${props => (props.open ? "100%" : "0")};
  overflow: hidden;
  padding: ${props => (props.open ? "15px" : "0 15px")};
`

const SectionAcordionHeader = styled.summary`
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 8px;
`

const SectionAcordionTitle = styled.label`
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`


const AddIcon = styled.div`   
    width: 20px;
    height: 20px;
    border: none;
    background-color: #ffffff;
    display: grid;
    place-content: center;
    border-radius: 50%;
    border: 2px solid var(--color-line-primary); 
`

const AddIconImage = styled.span<{ open: boolean }>`
    width: 12px;
    height: 12px;
    background-color: #ffffff;
    position: relative;
    display: block;
    background: var(--color-txt-primary);    
    mask: url(${props => (props.open ? iconSub : iconAdd)}) no-repeat center center / contain;
    -webkit-mask: url(${props => (props.open ? iconSub : iconAdd)}) no-repeat center center / contain;

`

const FileDeleteIcon = styled.span`
display: block;
width: 40px;
height: 40px;
mask: url(${iconCross}) no-repeat center center / contain;
-webkit-mask: url(${iconCross}) no-repeat center center / contain;
background-color: var(--color-txt-primary);
`;

// 画像削除ボタン用のスタイル
const ImgWrapper = styled.div`
position: relative;
`;

const DeleteButton = styled.button`
position: absolute;
top: 10px;
right: 10px;
background: transparent;
border: none;
cursor: pointer;
padding: 0;
`;
