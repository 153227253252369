import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import ProcessImpactAssesmentMethodsTable from '@common/table/ProcessImpactAssesmentMethodsTable';
import { ImpactAssesmentMethods, LCIAResults, ProcessCalculate, ResultItems0, ResultItems1 } from '@typeList/types';
import { useLciaResults } from '@hooks/useBackendApi';
import { ProcessViewContext } from '@pages/process/ProcessView';
import ProcessLciaResultTable from '@common/table/ProcessLciaResultTable';
import StackedBarChart from '@common/graph/StackedBarChart';
import ProessContributionAnalysisModal from '../contribution_analysis/ProessContributionAnalysisModal';

import AddButton from '@common/button/AddButton';
import styled from 'styled-components';
import LciaModelSelectModal from './LciaModelSelectModal';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '@utils/utils';
import ErrorMsg from '@common/error/ErrorMsg';
import { FadeLoader } from 'react-spinners';
import LciaFactorAnalysisModal from '@common/modal/LciaFactorAnalysisModal';


interface ProcessCharacterizationProps {
    calculateData: ProcessCalculate[] | null;
    selectedProcessId: number | null;
}

/**
 * 010 プロセス閲覧画面（特性化）タブ
 * @returns 
 */
const ProcessCharacterization: React.FC<ProcessCharacterizationProps> = ({ calculateData, selectedProcessId }) => {
    const { t } = useTranslation();
    const context = useContext(ProcessViewContext);
    const {
        userData,
        processData,
        inputCharacterizationDisplayFormatValue,
        setInputCharacterizationDisplayFormatValue,
        methodsData,
        lciaResultsData,
        setLciaResultsData,
        lciaResultsLoading,
        lciaResultsError,
        selectModelIdList,
        setSelectModelIdList
    } = context;

    const [filteredResultData, setFilteredResultData] = useState<LCIAResults>()

    // 型ガード関数の定義
    function isResultItems1(item: ResultItems0 | ResultItems1): item is ResultItems1 {
        return (item as ResultItems1).lcia_model !== undefined;
    }


    // 要因分析用
    const [isLciaFactorAnalysisModalOpen, setIsLciaFactorAnalysisModalOpen] = useState(false);
    const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
    const [selectedGroupingName, setSelectedGroupingName] = useState<string | undefined>();
    const [selectedSubGroupingName, setSelectedSubGroupingName] = useState<string | undefined>();

    const openLciaFactorAnalysisModal = (amount: number, groupingName?: string, subGroupingName?: string) => {
        setSelectedAmount(amount);
        setSelectedGroupingName(groupingName);
        setSelectedSubGroupingName(subGroupingName);
        setIsLciaFactorAnalysisModalOpen(true);
    };

    const closeLciaFactorAnalysisModal = () => {
        setIsLciaFactorAnalysisModalOpen(false);
        setSelectedAmount(null);
        setSelectedGroupingName(undefined);
        setSelectedSubGroupingName(undefined);
    };


    const generateColumns = () => {
        let baseColumns: ColumnDef<any>[] = [
            {
                id: 'impact_assessment_method',
                accessorFn: (row) => isResultItems1(row) ? row.lcia_model.impact_assessment_method.name : '', // ソート用のデータ指定
                enableSorting: true,
                header: () => t('影響評価手法'),
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.lcia_model.impact_assessment_method.name : '';
                },
            },
            {
                id: 'impact_category',
                header: () => t('影響カテゴリ'),
                accessorFn: (row) => isResultItems1(row) ? row.lcia_model.impact_category.name : '', // ソート用のデータ指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.lcia_model.impact_category.name : '';
                },
            },
            {
                id: 'lcia_indicator',
                header: () => t('指標'),
                accessorFn: (row) => isResultItems1(row) ? row.lcia_model.lcia_indicator.name : '', // ソート用のデータ指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.lcia_model.lcia_indicator.name : '';
                },
            },
            {
                id: 'total_amount',
                header: () => t('全体'),
                accessorKey: 'amount', // ソート用のキーを指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    const amount = resultItem.amount;
                    if (inputCharacterizationDisplayFormatValue === "2" && amount > 0) {
                        return (
                            <AmountLink onClick={() => openLciaFactorAnalysisModal(amount)}>
                                {((resultItem.amount / amount) * 100).toFixed(2) + '%'}
                            </AmountLink>
                        );
                    }
                    if (userData) {
                        return (
                            <AmountLink onClick={() => openLciaFactorAnalysisModal(amount)}>
                                {formatNumber(amount || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)}
                            </AmountLink>
                        );
                    } else {
                        // デフォルトではそのままの値を表示
                        return (
                            <AmountLink onClick={() => openLciaFactorAnalysisModal(amount)}>
                                {amount}
                            </AmountLink>
                        );
                    }
                },
            },
            {
                id: 'unit',
                header: () => t('単位'),
                accessorFn: (row) => isResultItems1(row) ? row.unit.name : '', // ソート用のデータ指定
                enableSorting: true,
                cell: (info) => {
                    const resultItem = info.row.original;
                    return isResultItems1(resultItem) ? resultItem.unit.name : '';
                },
            },
        ];
        // lci_result_breakdownを使用した追加カラムの処理
        filteredResultData?.result_breakdown?.lci_result_breakdown.forEach((breakdown: any, breakdownIndex: any) => {
            baseColumns.push({
                id: `breakdown_${breakdown.grouping_key_id}_${breakdown.sub_grouping_key_id}`,
                header: () =>(
                    <span>
                        {breakdown.grouping_name}
                        <br />
                        {breakdown.sub_grouping_name}
                    </span>
                ),
                accessorFn: (row) => {
                    // ソート用のデータ指定
                    const relatedBreakdown = row.relatedBreakdown.find(
                        (b: any) => b.groupingKeyId === breakdown.grouping_key_id && b.subGroupingKeyId === breakdown.sub_grouping_key_id
                    );
                    return relatedBreakdown ? relatedBreakdown.amount : 0;
                },
                enableSorting: true,
                cell: (info) => {
                    const relatedBreakdown = info.row.original.relatedBreakdown.find(
                        (b: any) => b.groupingKeyId === breakdown.grouping_key_id && b.subGroupingKeyId === breakdown.sub_grouping_key_id
                    );

                    const amount = relatedBreakdown ? relatedBreakdown.amount : 0;

                    if (inputCharacterizationDisplayFormatValue === "2") {
                        return (
                            <AmountLink onClick={() => openLciaFactorAnalysisModal(amount, breakdown.grouping_name, breakdown.sub_grouping_name)}>
                                {`${(relatedBreakdown?.ratio * 100).toFixed(2)}%`}
                            </AmountLink>
                        )
                    }

                    if (userData) {
                        return (
                            <AmountLink onClick={() => openLciaFactorAnalysisModal(amount, breakdown.grouping_name, breakdown.sub_grouping_name)}>
                                {formatNumber(amount || 0, userData?.significant_figures, userData?.use_decimal_notation, userData?.decimal_places)}
                            </AmountLink>
                        )
                    } else {
                        // デフォルトではそのままの値を表示
                        return (
                            <AmountLink onClick={() => openLciaFactorAnalysisModal(amount, breakdown.grouping_name, breakdown.sub_grouping_name)}>
                                {amount}
                            </AmountLink>
                        ) 
                    }
                },
            });
        });


        return baseColumns;
    };

    const [columns, setColumns] = useState<ColumnDef<any>[]>(generateColumns());

    // 表示形式の切り替えの為column再生成
    useEffect(() => {
        if(filteredResultData) {
            setColumns(generateColumns());
            // setResultData(resultData);
        }
    }, [lciaResultsData, inputCharacterizationDisplayFormatValue, filteredResultData]);

    useEffect(() => {
        if (selectedProcessId && calculateData && calculateData.length > 0) {
            // selectedProcessIdに一致するデータを取得
            const selectData = calculateData.find((data) => data.id === selectedProcessId);
            // 該当データが存在する場合、process_output.product_nameを取得
            if (selectData) {
                const selectName = selectData.process_output.product_name;

                // resultDataからproduct_nameが一致するものをフィルタリング
                const filteredData = lciaResultsData?.find(
                    (data) => data.process_output.product_name === selectName
                );

                // フィルタリングされたデータをステートにセット
                setFilteredResultData(filteredData);
            }
        } else {
            setFilteredResultData(undefined);
        }
    }, [selectedProcessId, calculateData, lciaResultsData]);


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            // case "grouping":
            //     setInputCharacterizationGroupingValue(event.target.value);
            //     break;
            case "displayFormat":
                setInputCharacterizationDisplayFormatValue(event.target.value);
                break;
        }
    };


    // フラットなデータを作成する関数
    const flattenResultData = () => {
        if (!filteredResultData) return [];

        return filteredResultData.result_items.map((item) => {
            // 型ガードで lcia_model プロパティが存在することを確認
            if (!isResultItems1(item)) {
                return { ...item, relatedBreakdown: [] };
            }

            const relatedBreakdown = filteredResultData.result_breakdown?.lci_result_breakdown.map((breakdown) => {
                // lcia_model が存在することを前提にフィルタリング
                const matchingItems = breakdown.lci_result_breakdown_items.filter(
                    (breakdownItem) => breakdownItem.result_key_id === item.lcia_model.id
                );

                return {
                    groupingKeyId:  breakdown.grouping_key_id,
                    subGroupingKeyId:  breakdown.sub_grouping_key_id,
                    groupingName: breakdown.grouping_name + ' ' + breakdown.sub_grouping_name,
                    amount: matchingItems.length > 0 ? matchingItems[0].amount : 0,
                    ratio: matchingItems.length > 0 ? matchingItems[0].ratio : 0,
                };
            });

            return {
                ...item,
                relatedBreakdown: relatedBreakdown || [],
            };
        });
    };
    const [flattenedData, setFlattenedData] = useState<any[]>([]);
    useEffect(() => {
        setFlattenedData(flattenResultData());
    }, [filteredResultData]);

    // エクスポート用のcolumn data
    const [exportColumns, setExportColumns] = useState<any>()
    const [exportData, setExportData] = useState<any>()
    const exportSet = (columns: any, data: any) => {
        setExportColumns(columns);
        setExportData(data);
    }

    // CSVダウンロード用の関数を定義
    const downloadCsv = (csv: string, filename: string) => {
        // UTF-8 BOMを追加
        const csvWithBom = `\uFEFF${csv}`;

        // CSV文字列をBlobオブジェクトに変換
        const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });

        // ダウンロードリンクを作成してダウンロードをトリガー
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // メモリリークを防ぐためにURLを解放
    };

    // データを整形する関数
    const transformData = (data: any[], columns: ColumnDef<any>[]) => {
        const headers = columns.map((column) => {
            if (typeof column.header === 'function') {
                const headerContent = column.header({} as any);
                return headerContent.props ? `${headerContent.props.children[0]} ${headerContent.props.children[2]}` : headerContent;
            }
            return column.header;
        });

        const transformedData = data.map((row) => {
            const transformedRow: any = {};

            columns.forEach((column) => {
                if (!column.id) return;

                switch (column.id) {
                    case 'impact_assessment_method':
                        transformedRow[column.id] = row.lcia_model?.impact_assessment_method?.name || '';
                        break;
                    case 'impact_category':
                        transformedRow[column.id] = row.lcia_model?.impact_category?.name || '';
                        break;
                    case 'lcia_indicator':
                        transformedRow[column.id] = row.lcia_model?.lcia_indicator?.name || '';
                        break;
                    case 'total_amount':
                        transformedRow[column.id] = row.amount || 0;
                        break;
                    case 'unit':
                        transformedRow[column.id] = row.unit?.name || '';
                        break;
                    default:
                        if (column.id.startsWith('breakdown_')) {
                            // breakdown_<grouping_key_id>_<sub_grouping_key_id> の形式から ID を取得
                            const [groupingKeyId, subGroupingKeyId] = column.id.split('_').slice(1).map(Number);

                            // `relatedBreakdown` の中から `groupingKeyId` と `subGroupingKeyId` に一致する項目を探す
                            const matchingBreakdowns = row.relatedBreakdown.filter(
                                (b: any) => b.groupingKeyId === groupingKeyId && b.subGroupingKeyId === subGroupingKeyId
                            );

                            // マッチする breakdown の amount を取り出して連結
                            transformedRow[column.id] = matchingBreakdowns.map((b: any) => b.amount).join(', ') || '0'; // カンマ区切りで連結
                        } else {
                            transformedRow[column.id] = ''; // その他のカラムは空に設定
                        }
                        break;
                }
            });

            return transformedRow;
        });

        return { headers, transformedData };
    };

    // エクスポート関数
    const exportCsv = (data: any[], columns: ColumnDef<any>[]) => {
        if (!data || data.length === 0 || !columns || columns.length === 0) {
            console.error(t('エクスポートするデータまたはカラム情報が不足しています。'));
            return;
        }

        // データを変換
        const { headers, transformedData } = transformData(data, columns);

        try {
            // CSVを生成する
            const csvContent = [
                headers.map(header => `"${header}"`).join(','), 
                ...transformedData.map(row => 
                    Object.values(row).map(value => `"${String(value).replace(/"/g, '""')}"`).join(',') 
                )].join('\n');

            // Blobを使ってダウンロード
            downloadCsv(csvContent, "process_midpoint_lcia_results" || 'default.csv');
        } catch (error) {
            console.error(t('CSVのエクスポート中にエラーが発生しました') +':', error);
        }
    };
  
    useEffect(()=>{
        // LCIAモデルのチェックがすべて外れたときはテーブルを空にする
        if(selectModelIdList && selectModelIdList.length === 0){
            setFlattenedData([])
            setLciaResultsData([]);
        }
    },[selectModelIdList])


    return (
        <Section>
            <div>
                <H2>{t('表示形式')}</H2>
                <RadioWrap>
                    <RadioInput
                        type="radio"
                        name="displayFormat"
                        id="3"
                        value="1"
                        checked={inputCharacterizationDisplayFormatValue === "1"}
                        onChange={(event) => handleInputChange(event, "displayFormat")}
                    />
                    <RadioLabel htmlFor="3">
                        <RadioText>
                            {t('数値')}
                        </RadioText>
                    </RadioLabel>

                    <RadioInput
                        type="radio"
                        name="displayFormat"
                        id="4"
                        value="2"
                        checked={inputCharacterizationDisplayFormatValue === "2"}
                        onChange={(event) => handleInputChange(event, "displayFormat")}
                    />
                    <RadioLabel htmlFor="4">
                        <RadioText>
                            {t('パーセント')}
                        </RadioText>
                    </RadioLabel>
                </RadioWrap>
            </div>


            <ButtonWrap>
                <Button type='button' onClick={() => exportCsv(exportData || [], exportColumns || [])}>
                    <ButtonText>{t('エクスポート')}</ButtonText>
                </Button>
                    <ProessContributionAnalysisModal calculateData={lciaResultsData} selectedProcessId={null} result_type="lcia"></ProessContributionAnalysisModal>
            </ButtonWrap>
            <LciaModelSelectModal
                methodsData={methodsData}
                setSelectModelIdList={setSelectModelIdList}
                selectModelIdList={selectModelIdList}
            />

            {/* 要因分析  */}
            <LciaFactorAnalysisModal
                isModalOpen={isLciaFactorAnalysisModalOpen}
                setIsModalOpen={setIsLciaFactorAnalysisModalOpen}
                amount={selectedAmount || 0}
                groupingName={selectedGroupingName}
                subGroupingName={selectedSubGroupingName}
            />

            <ProcessImpactAssesmentMethodsTable
                columns={columns}
                resultData={flattenedData}
                paging_flag={false}
                exportSet={exportSet}
            />

            {lciaResultsLoading &&
                <FadeLoader
                    color="#48bdbb"
                    height={10}
                    radius={2}
                    width={5}
                />
            }
            {lciaResultsError && <ErrorMsg>{lciaResultsError}</ErrorMsg>}
            <StackedBarChart data={flattenedData}/>
        </Section>
    );
};

export default ProcessCharacterization;

const Section = styled.section`
    margin-bottom: 32px;
    display: block;
`

const H2 = styled.h2`
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`


const RadioWrap = styled.div`
    margin-bottom: 32px;
    gap: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const RadioInput = styled.input`
    display: none;

    &:checked + label::before {
        border-color: var(--color-site-secondary);
        background-color: var(--color-site-secondary);
    }

    &:checked + label::after {
        position: absolute;
        top: 50%;
        left: 8px;
        width: 4.5px;
        height: 4.5px;
        content: "";
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
    }

    &:not(:checked) + label .c-input,
    &:not(:checked) + label .c-select,
    &:not(:checked) + label .c-select__inner {
        pointer-events: none;
        color: var(--color-line-primary) !important;
    }
`;

const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;

    &::before {
        content: "";
        width: 14px;
        height: 14px;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--color-txt-primary);
        border-radius: 50%;
        background-color: #fff;
    }
`;

const RadioText = styled.span`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500;
`;

const ButtonWrap = styled.div`
    margin: 10px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
`

const Button = styled.button`
    margin-right: 10px;
    background-color: var(--color-site-secondary);
    border-radius: 4px;
    border: 1px solid var(--color-site-secondary);
    padding: 7px 16px;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    font: inherit;
    outline: none;
`

const ButtonText = styled.span`
    color: #fff;
    font-size: 13px;
    font-weight: 500;
`

const AmountLink = styled.span`
    // color: blue;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
`;
