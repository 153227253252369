import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal from '@common/modal/Modal';
import { Subsystems } from '@typeList/types';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import styled from 'styled-components';
import ReloadButton from '@common/button/ReloadButton';
import { useTranslation } from 'react-i18next';
import { RequiredSpan } from '@styles/Common';

interface CustomNodeSubsystems extends Subsystems {
	borderBottomColor: string;
}
interface SubsystemEditProps {
	isOpen: boolean;
	onClose: () => void;
	data: CustomNodeSubsystems;
	onSave: (updatedData: CustomNodeSubsystems) => void;
}

/**
 * 058 サブシステム編集
 * @param param0 
 * @returns 
 */
const SubsystemEdit: React.FC<SubsystemEditProps> = ({ isOpen, onClose, data, onSave }) => {
	const { t } = useTranslation();
	const context = useContext(CaseStudyViewContext);
	const { subsystemCategorySetData, selectCategorySet } = context;
	const [selectedCategoryIds, setSelectedCategoryIds] = useState<Array<number>>([]);
	const [error, setError] = useState(false);

	// 選択されたカテゴリIDに基づくサブシステムカテゴリの色を設定
	const selectedCategoryColor = useMemo(() => {
		// selectCategorySetと一致するサブシステムカテゴリセットを取得
		const matchingCategorySet = subsystemCategorySetData?.find(
			(categorySet) => categorySet.id === selectCategorySet
		);

		// matchingCategorySetのsubsystem_categoriesの中で、selectedCategoryIdsのそれぞれの色を取得
		const selectedColors = selectedCategoryIds.map((id, index) => {
			const category = matchingCategorySet?.subsystem_categories.find(cat => cat.id === id);
			return category?.color || 'defaultColor'; // デフォルトのカラーを指定
		});

		// 必要に応じて一つの色を返す（最初の選択中の色、もしくは他のロジックに基づく）
		return selectedColors[0] || 'defaultColor'; // 適宜変更可能

	}, [selectedCategoryIds, subsystemCategorySetData, selectCategorySet]);

	// 更新用のステート
	const { nodes, setNodes } = useContext(CaseStudyViewContext);

	// 表示用のステート
	const [name, setName] = useState(data.name);
	const [samplingProcedure, setSamplingProcedure] = useState(data.sampling_procedure || '');
	const [criteriaForExcludingIntermediateFlow, setCriteriaForExcludingIntermediateFlow] = useState(data.criteria_for_excluding_intermediate_flow || '');
	const [publicComment, setPublicCommet] = useState(data.public_comment);
	const [privateComment, setPrivateComment] = useState(data.private_comment);

	const handleSave = () => {
		//nameが空の場合はエラーにする
		if (!name) {
			setError(true)
			return
		}

		onSave({
			...data,
			name,
			sampling_procedure: samplingProcedure,
			criteria_for_excluding_intermediate_flow: criteriaForExcludingIntermediateFlow,
			borderBottomColor: selectedCategoryColor,
			subsystem_category_ids: selectedCategoryIds,
			public_comment: publicComment,
			private_comment: privateComment,

		});

		// nodesを更新
		const updatedNodes = nodes.map(node => {
			if (String(node.data.id) === String(data.id)) {
				return {
					...node,
					data: {
						...node.data,
						name: name,
						sampling_procedure: samplingProcedure,
						selectedCategoryColor: selectedCategoryColor,
						subsystem_category_ids: selectedCategoryIds
					}
				};
			}
			return node;
		});

		setNodes(updatedNodes);
		onClose();
	};

	// 初期値にdata.subsystem_category_idsをセット
	useEffect(() => {
		if (isOpen && subsystemCategorySetData) {
			const initialSelectedCategoryIds = subsystemCategorySetData.map((categorySet) => {
				const matchingId = data.subsystem_category_ids?.find(id =>
					categorySet.subsystem_categories.some(category => category.id === id)
				);
				return matchingId ?? categorySet.subsystem_categories[0]?.id; // デフォルト値
			});

			setSelectedCategoryIds(initialSelectedCategoryIds);
		}
	}, [data.subsystem_category_ids, isOpen, subsystemCategorySetData]);


	// カテゴリ変更
	const handleCategoryChange = (categoryId: number, categorySetId: number) => {
		// subsystemCategorySetData が null の場合は処理を中断
		if (!subsystemCategorySetData) {
			return;
		}

		setSelectedCategoryIds((prevIds) => {
			// prevIds をマッピングし、対応する categorySet を見つけて値を更新
			const updatedIds = prevIds.map((id, index) => {
				const categorySet = subsystemCategorySetData[index]; // インデックスで取得
				if (categorySet?.id === categorySetId) {
					return categoryId; // 対応する categorySet の ID を更新
				}
				return id; // 他はそのまま
			});

			return updatedIds; // 更新された ID 配列を返す
		});
	};


	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<Inner>
				<Title>{t('サブシステム編集')}</Title>
				<SectionBody>
					<SectionColumn>
						<form>
							<SectionColumnWrap>
								<SectionColumnWrapTitle>{t('名前')}<RequiredSpan>※</RequiredSpan></SectionColumnWrapTitle>
								<InputPrimary
									type="text"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
								<SectionColumnWrapTitle></SectionColumnWrapTitle>
								{error && <ErrorMsg>{t('名前は必須です')}</ErrorMsg>}
							</SectionColumnWrap>

							<SectionColumnWrapForCategory>
								<SectionColumnWrapTitle>{t('サブシステムカテゴリ')}</SectionColumnWrapTitle>
								<SectionColumnWrapContent>
									{subsystemCategorySetData?.map((categorySet) => (
										<React.Fragment key={categorySet.id}>
											<Input
												type="text"
												value={categorySet.name || ''}
												disabled
											/>
											<SelectLabel>
												<Select
													value={
														selectedCategoryIds.find(id =>
															categorySet.subsystem_categories.some(category => category.id === id)
														) || ''
													}
													onChange={(e) => handleCategoryChange(Number(e.target.value), categorySet.id)}
												>
													{categorySet.subsystem_categories.map(category => (
														<option key={category.id} value={category.id}>
															{category.name}
														</option>
													))}
												</Select>
											</SelectLabel>
										</React.Fragment>
									))}
								</SectionColumnWrapContent>

							</SectionColumnWrapForCategory>

							<SectionColumnWrap>
								<SectionColumnWrapTitle>{t('収集方法')}</SectionColumnWrapTitle>
								<CommentTextArea
									cols={100}
									rows={3}
									value={samplingProcedure || ''}
									onChange={(event) => setSamplingProcedure(event.target.value)}
								/>
							</SectionColumnWrap>
							<SectionColumnWrap>
								<SectionColumnWrapTitle>{t('カットオフした項目')}</SectionColumnWrapTitle>
								<CommentTextArea
									cols={100}
									rows={3}
									value={criteriaForExcludingIntermediateFlow || ''}
									onChange={(event) => setCriteriaForExcludingIntermediateFlow(event.target.value)}
								/>
							</SectionColumnWrap>
							<SectionColumnWrap>
								<SectionColumnWrapTitle>{t('その他（公開コメント）')}</SectionColumnWrapTitle>
								<CommentTextArea
									cols={100}
									rows={3}
									value={publicComment || ''}
									onChange={(event) => setPublicCommet(event.target.value)}
								/>
							</SectionColumnWrap>
							<SectionColumnWrap>
								<SectionColumnWrapTitle>{t('その他（プライベートコメント）')}</SectionColumnWrapTitle>
								<CommentTextArea
									cols={100}
									rows={3}
									value={privateComment || ''}
									onChange={(event) => setPrivateComment(event.target.value)}
								/>
							</SectionColumnWrap>
						</form>
					</SectionColumn>
				</SectionBody>
				<ButtonReloadSectionTabel>
					<ReloadButton onClick={handleSave} text={t('更新する')} />
				</ButtonReloadSectionTabel>
			</Inner>
		</Modal>
	);
};

export default SubsystemEdit;

const ButtonReloadSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;    
`

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`

const SectionBody = styled.div`
    margin-bottom: 60px;
`

const SectionBodyLast = styled.div`
`

const SectionColumn = styled.dl`
`

const SectionColumnWrap = styled.div`
    margin-bottom: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;      
`

const SectionColumnWrapForCategory = styled.div`
    margin-bottom: 8px;
    display: grid;
    // align-items: center;
    grid-template-columns: 27.0833333333% minmax(0, 1fr);
    gap: 6px 40px;      
`;

const SectionColumnWrapTitle = styled.dt`
    margin-bottom: 0;   
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
`

const SectionColumnWrapContent = styled.dd`
    display: grid;
    gap: 8px 12px;
    grid-template-columns: 42.6470588235% 1fr;
    justify-content: flex-start;
    align-items: center;
`;

const InputPrimary = styled.input`
    width: 100%;
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`;

const Input = styled.input`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`;

const SelectLabel = styled.label`
    width: 100%;
    position: relative;
    display: block;
`;

const Select = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    color: rgb(85, 85, 85);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`

const CommentTextArea = styled.textarea`
    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    width: 100%;
`