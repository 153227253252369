import { useGetRequest } from "./useGetRequest";
import { putRequest, postRequest, getRequest, deleteRequest, uploadFileRequest, downloadFileRequest, getAttachedFilesRequest, importFileRequest, getAttachedFilesRequestWithHeader } from '../services/api/api';
import { AddLicense, AddParameter, CaseStudyCalculate, CaseStudyContributionAnalyze, CaseStudyLCIAResults, CaseStudyLciResultEachSubsystem, CategoriesList, CategoryValueList, CommonAttachedFiles, ContributionAnalyze, CreateCaseStudy, CreateProcess, CreateProduct, CreateRole, CreateUser, DataQualityIndicators, FileUpload, GetCaseStudy, GetProcessType, GetProductById, GetReferredIo, GetUserDetail, ImpactAssesmentMethods, LCIAResults, LciResultBreakdowns, ProcessCalculate, ProcessHistories, RefereedProcessInputs, Role, RoleList, SearchCaseStudies, SearchElementaryFlows, SearchProcesses, SearchProducts, SearchUserList, SubsystemCategorySet, UnitConversionsList, UnitGroupList, UpdateCaseStudy, UpdateProcess, UpdateProduct, UpdateUser, UpperProcess, UserList, DatabasePacksList, Notices, CalculatedDateOut, IdentifierSchemes, AccessibleDatabasePacksList, } from "@typeList/types";
import { useEffect, useState } from "react";


/**
 * バックエンドAPIのフック 
 */


/**
 * ユーザー一覧取得
 * 
 * @returns 
 */
export const useGetUsersList = () => {
    const [userListData, setUserListData] = useState<UserList[] | null>(null);
    const [userListDataCount, setUserListDataCount] = useState<number>(0);
    const [userListLoading, setUserListLoading] = useState(false);
    const [userListError, setUserListError] = useState<any>(null);
    const { data, error, loading } = useGetRequest<UserList[]>('/users');
    useEffect(() => {
        setUserListData(data);
        setUserListLoading(loading);
        setUserListError(error)
        if (data) {
            setUserListDataCount(JSON.parse(JSON.stringify(data)).length)
        }
    }, [data, loading, error])
    return {
        userListData,
        userListLoading,
        userListError,
        userListDataCount,
        setUserListData,
        setUserListLoading,
        setUserListError,
        setUserListDataCount,
    };
}

/**
 * ユーザー作成
 * 
 * @returns 
 */
export const useCreateUser = (id: number | undefined, params: object) => {
    const [createUserData, setCreateUserData] = useState<CreateUser | null>(null);
    const [createUserLoading, setCreateUserLoading] = useState(false);
    const [createUserError, setCreateUserError] = useState<any>(null);
    const createUser = async () => {
        setCreateUserLoading(true);
        try {
            const response = await postRequest<CreateUser>(`/users/${id}`, params);
            setCreateUserData(response);
            setCreateUserError(null);
            return response;
        } catch (err: any) {
            setCreateUserError(err.response.data.detail);
            throw err;
        } finally {
            setCreateUserLoading(false);
        }
    };
    return {
        createUser,
        createUserData,
        createUserLoading,
        createUserError,
        setCreateUserData,
        setCreateUserLoading,
        setCreateUserError
    };
}


/**
 * ユーザー詳細取得
 * 
 * @returns 
 */
export const useGetUserDetail = (id: number | undefined) => {
    const [userData, setUserData] = useState<GetUserDetail | null>(null);
    const [userLoading, setUserLoading] = useState(false);
    const [userError, setUserError] = useState<any>(null);
    const { data, loading, error } = useGetRequest<GetUserDetail>(`/users/${id}`);
    useEffect(() => {
        setUserData(data);
        setUserLoading(loading);
        setUserError(error)
    }, [data, loading, error])
    return {
        userData,
        userLoading,
        userError,
        setUserData,
        setUserLoading,
        setUserError
    };
}


/**
 * ユーザー詳細取得
 * 
 * @returns 
 */
export const useGetUserDetailData = () => {
    const [userData, setUserData] = useState<GetUserDetail | null>(null);
    const [userLoading, setUserLoading] = useState(false);
    const [userError, setUserError] = useState<any>(null);
    const getUserDetail = async(id: number) =>{
        setUserLoading(true);
        try {
            const response = await getRequest<GetUserDetail>(`/users/${id}`);
            setUserData(response);
            setUserError(null);
            return response;
        } catch (err: any) {
            setUserError(err.response.data.detail);
            throw err;
        } finally {
            setUserLoading(false);
        }
    }
    return {
        getUserDetail,
        userData,
        userLoading,
        userError,
        setUserData,
        setUserLoading,
        setUserError
    };
}


/**
 * ユーザー更新
 * 
 * @returns 
 */
export const useUpdateUser = () => {
    const [updateUserData, setUpdateUserData] = useState<UpdateUser>();
    const [updateUserLoading, setUpdateUserLoading] = useState(false);
    const [updateUserError, setUpdateUserError] = useState<any>(null);

    const updateUser = async (id: number | undefined, params: object) => {
        setUpdateUserLoading(true);
        try {
            const response = await putRequest<UpdateUser>(`/users/${id}`, params);
            setUpdateUserData(response);
            setUpdateUserError(null);
            return response;
        } catch (err: any) {
            setUpdateUserError(err.response.data.detail);
            throw err;
        } finally {
            setUpdateUserLoading(false);
        }
    };

    return { updateUser, updateUserData, updateUserLoading, updateUserError, setUpdateUserError };
};

/**
 * ユーザー削除
 * 
 * @returns 
 */
export const useDeleteUser = () => {
    const [deleteUserData, setDeleteUserData] = useState<any>();
    const [deleteUserLoading, setDeleteUserLoading] = useState(false);
    const [deleteUserError, setDeleteUserError] = useState<any>(null);
    const deleteUser = async (user_id: number) => {
        setDeleteUserLoading(true);
        try {
            const response = await deleteRequest<any>(`/users/${user_id}`);
            setDeleteUserData(response);
            setDeleteUserError(null);
            return response;
        } catch (err: any) {
            setDeleteUserError(err.response.data.detail);
            throw err;
        } finally {
            setDeleteUserLoading(false);
        }
    };
    return {
        deleteUser,
        deleteUserData,
        deleteUserLoading,
        deleteUserError
    };
}

/**
 * ユーザー検索
 * 
 * @returns 
 */
export const useSearchUser = () => {
    const [searchUserData, setSearchUserData] = useState<SearchUserList>();
    const [searchUserLoading, setSearchUserLoading] = useState(false);
    const [searchUserError, setSearchUserError] = useState<any>(null);

    const searchUser = async (
        SearchText: string = "",
        Page: number = 1,
        PageSize: number = 10,
    ) => {
        setSearchUserLoading(true);
        try {
            const response = await postRequest<SearchUserList>(`/users/search?search_text=${SearchText}&page=${Page}&page_size=${PageSize}`, {});
            setSearchUserData(response);
            setSearchUserError(null);
            return response;
        } catch (err: any) {
            setSearchUserError(err.response.data.detail);
            throw err;
        } finally {
            setSearchUserLoading(false);
        }
    };

    return {
        searchUser,
        searchUserData,
        searchUserLoading,
        searchUserError,
    };
};


/**
 * ライセンス追加
 * 
 * @returns 
 */
export const useAddLicense = () => {
    const [addLicenseData, setAddLicenseData] = useState<AddLicense | null>(null);
    const [addLicenseLoading, setAddLicenseLoading] = useState(false);
    const [addLicenseError, setAddLicenseError] = useState<any>(null);
    const addLicense = async (id: number | undefined, license_key: string) => {
        setAddLicenseLoading(true);
        try {
            const response = await postRequest<AddLicense>(`/users/${id}/licenses?license_key=${license_key}`);
            setAddLicenseData(response);
            setAddLicenseError(null);
            return response;
        } catch (err: any) {
            setAddLicenseError(err.response.data.detail);
            throw err;
        } finally {
            setAddLicenseLoading(false);
        }
    };
    return {
        addLicense,
        addLicenseData,
        addLicenseLoading,
        addLicenseError,
        setAddLicenseData,
        setAddLicenseLoading,
        setAddLicenseError
    };
}

/**
 * ライセンス解除
 * 
 * @returns 
 */
export const useRemoveLicenses = () => {
    const [removeLicensesData, setRemoveLicensesData] = useState<any>();
    const [removeLicensesLoading, setRemoveLicensesLoading] = useState(false);
    const [removeLicensesError, setRemoveLicensesError] = useState<any>(null);
    const removeLicenses = async (license_key: string) => {
        setRemoveLicensesLoading(true);
        try {
            const response = await deleteRequest<any>(`/licenses/${license_key}`);
            setRemoveLicensesData(response);
            setRemoveLicensesError(null);
            return response;
        } catch (err: any) {
            setRemoveLicensesError(err.response.data.detail);
            throw err;
        } finally {
            setRemoveLicensesLoading(false);
        }
    };
    return { removeLicenses, removeLicensesData, removeLicensesLoading, removeLicensesError };
}


/**
 * 自分の持っているライセンスを再認証する
 * 
 * @returns 
 */
export const useReconfirmLicenses = () => {
    const [reconfirmLicensesData, setReconfirmLicensesData] = useState<any>();
    const [reconfirmLicensesLoading, setReconfirmLicensesLoading] = useState(false);
    const [reconfirmLicensesError, setReconfirmLicensesError] = useState<any>(null);
    const reconfirmLicenses = async () => {
        setReconfirmLicensesLoading(true);
        try {
            const response = await postRequest<any>(`/user/me/reconfirm-license`);
            setReconfirmLicensesData(response);
            setReconfirmLicensesError(null);
            return response;
        } catch (err: any) {
            setReconfirmLicensesError(err);
            throw err;
        } finally {
            setReconfirmLicensesLoading(false);
        }
    };
    return { reconfirmLicenses, reconfirmLicensesData, reconfirmLicensesLoading, reconfirmLicensesError };
}


/**
 *  ユーザーパラメーター追加
 * 
 */
export const useAddUserParameter = (id: number | undefined) => {
    const [addUserParameterData, setAddUserParameterData] = useState<AddParameter>();
    const [addUserParameterLoading, setAddUserParameterLoading] = useState(false);
    const [addUserParameterError, setAddUserParameterError] = useState<any>(null);

    const addUserParameter = async (params: object) => {
        setAddUserParameterLoading(true);
        try {
            const response = await postRequest<AddParameter>(`/users/${id}/parameters`, params);
            setAddUserParameterData(response);
            setAddUserParameterError(null);
            return response;
        } catch (err: any) {
            setAddUserParameterError(err.response.data.detail);
            throw err;
        } finally {
            setAddUserParameterLoading(false);
        }
    };

    return { addUserParameter, addUserParameterData, addUserParameterLoading, addUserParameterError, setAddUserParameterError };
};


/**
 * ロール一覧取得
 * 
 * @returns 
 */
export const useGetRoles = () => {
    const [roles, setRoles] = useState<RoleList[] | null>(null);
    const [rolesLoading, setRolesLoading] = useState(false);
    const [rolesError, setRolesError] = useState<any>(null);
    const { data, loading, error } = useGetRequest<RoleList[]>(`/roles`);
    useEffect(() => {
        setRoles(data);
        setRolesLoading(loading);
        setRolesError(error)
    }, [data, loading, error])
    return {
        roles,
        rolesLoading,
        rolesError,
        setRoles,
        setRolesLoading,
        setRolesError
    };
}


/**
 * ロール作成
 * 
 * @returns 
 */
export const useCreateRole = (param: object) => {
    const [createRoleData, setCreateRoleData] = useState<CreateRole | null>(null);
    const [createRoleLoading, setCreateRoleLoading] = useState(false);
    const [createRoleError, setCreateRoleError] = useState<any>(null);
    const createRole = async () => {
        setCreateRoleLoading(true);
        try {
            const response = await postRequest<CreateRole>(`/roles`, param);
            setCreateRoleData(response);
            setCreateRoleError(null);
            return response;
        } catch (err: any) {
            setCreateRoleError(err.response.data.detail);
            throw err;
        } finally {
            setCreateRoleLoading(false);
        }
    };
    return {
        createRole,
        createRoleData,
        createRoleLoading,
        createRoleError,
        setCreateRoleData,
        setCreateRoleLoading,
        setCreateRoleError
    };
}

/**
 * ロール更新
 * 
 * @returns 
 */
export const useUpdateRole = () => {
    const [updatRoleData, setUpdatRoleData] = useState<any>();
    const [updatRoleLoading, setUpdatRoleLoading] = useState(false);
    const [updatRoleError, setUpdatRoleError] = useState<any>(null);
    const updatRole = async (role_id: number, param: object) => {
        setUpdatRoleLoading(true);
        try {
            const response = await putRequest<any>(`/roles/${role_id}`, param);
            setUpdatRoleData(response);
            setUpdatRoleError(null);
            return response;
        } catch (err: any) {
            setUpdatRoleError(err.response.data.detail);
            throw err;
        } finally {
            setUpdatRoleLoading(false);
        }
    };
    return {
        updatRole,
        updatRoleData,
        updatRoleLoading,
        updatRoleError
    };
}

/**
 * ロール削除
 * 
 * @returns 
 */
export const useDeleteRole = () => {
    const [deleteRoleData, setDeleteRoleData] = useState<any>();
    const [deleteRoleLoading, setDeleteRoleLoading] = useState(false);
    const [deleteRoleError, setDeleteRoleError] = useState<any>(null);
    const deleteRole = async (role_id: number) => {
        setDeleteRoleLoading(true);
        try {
            const response = await deleteRequest<any>(`/roles/${role_id}`);
            setDeleteRoleData(response);
            setDeleteRoleError(null);
            return response;
        } catch (err: any) {
            setDeleteRoleError(err.response.data.detail);
            throw err;
        } finally {
            setDeleteRoleLoading(false);
        }
    };
    return {
        deleteRole,
        deleteRoleData,
        deleteRoleLoading,
        deleteRoleError
    };
}

/** 
* プロセス取得
* 
* @returns 
*/
export const useGetProcess = (process_id: number | undefined) => {
    const [processData, setProcessData] = useState<GetProcessType | null>(null);
    const [processLoading, setProcessLoading] = useState(false);
    const [processError, setProcessError] = useState<any>(null);
    const { data, loading, error } = useGetRequest<GetProcessType>(`/processes/${process_id}`);
    useEffect(() => {
        setProcessData(data);
        setProcessLoading(loading);
        setProcessError(error)
    }, [data, loading, error])
    return {
        processData,
        processLoading,
        processError,
        setProcessData,
        setProcessLoading,
        setProcessError
    };
}

/**
 * プロセス削除
 * 
 * @returns 
 */
export const useDeleteProcess = () => {
    const [deleteProcessData, setDeleteProcessData] = useState<any>();
    const [deleteProcessLoading, setDeleteProcessLoading] = useState(false);
    const [deleteProcessError, setDeleteProcessError] = useState<any>(null);
    const deleteProcess = async (process_id: number) => {
        setDeleteProcessLoading(true);
        try {
            const response = await deleteRequest<any>(`/processes/${process_id}`);
            setDeleteProcessData(response);
            setDeleteProcessError(null);
            return response;
        } catch (err: any) {
            setDeleteProcessError(err.response.data.detail);
            throw err;
        } finally {
            setDeleteProcessLoading(false);
        }
    };
    return {
        deleteProcess,
        deleteProcessData,
        deleteProcessLoading,
        deleteProcessError,
    };
}

/** 
* プロセス取得(プロセス閲覧表示用)
* 
* @returns 
*/
export const useGetProcessForProcessView = () => {
    const [processData, setProcessData] = useState<GetProcessType | null>(null);
    const [processLoading, setProcessLoading] = useState(false);
    const [processError, setProcessError] = useState<any>(null);
    const getProcessForProcessView = async (process_id: number | undefined) => {
        setProcessLoading(true);
        try {
            const response = await getRequest<GetProcessType>(`/processes/${process_id}`);
            setProcessData(response);
            setProcessError(null);
            return response;
        } catch (err: any) {
            setProcessError(err.response.data.detail);
            throw err;
        } finally {
            setProcessLoading(false);
        }
    };
    return {
        getProcessForProcessView,
        processData,
        processLoading,
        processError,
        setProcessData,
        setProcessLoading,
        setProcessError
    };
}


/** 
* プロセス更新
* 
* @returns 
*/
export const useUpdateProcess = () => {
    const [updateProcessData, setUpdateProcessData] = useState<UpdateProcess | null>(null);
    const [updateProcessLoading, setUpdateProcessLoading] = useState(false);
    const [updateProcessError, setUpdateProcessError] = useState<any>(null);
    const updateProcess = async (process_id: number | undefined, params: object) => {
        setUpdateProcessLoading(true);
        try {
            const response = await putRequest<UpdateProcess>(`/processes/${process_id}`, params);
            setUpdateProcessData(response);
            setUpdateProcessError(null);
            return response;
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                window.location.href = '/';
            }
            setUpdateProcessError(err.response.data.detail);
            throw err;
        } finally {
            setUpdateProcessLoading(false);
        }
    };
    return {
        updateProcess,
        updateProcessData,
        updateProcessLoading,
        updateProcessError,
        setUpdateProcessData,
        setUpdateProcessLoading,
        setUpdateProcessError
    };
}

/** 
* 上流プロセス取得
* 
* @returns 
*/
export const useGetUpperProcessById = () => {
    const [upperProcessData, setUpperProcessData] = useState<UpperProcess | null>(null);
    const [upperProcessLoading, setUpperProcessLoading] = useState(false);
    const [upperProcessError, setUpperProcessError] = useState<any>(null);
    const getUpperProcess = async (process_id: number, process_output_id?: number, process_output_amount?: number) => {
        setUpperProcessLoading(true);
         // クエリパラメータを動的に組み立てる
         let url = `/processes/${process_id}/upper_process`;
         if (process_output_id !== undefined && process_output_amount !== undefined) {
             url = `${url}?process_output_id=${process_output_id}&process_output_amount=${process_output_amount}`;
         }
        try {
            // const response = await getRequest<UpperProcess>(`/processes/${process_id}/upper_process`);
            const response = await getRequest<UpperProcess>(url);
            setUpperProcessData(response);
            setUpperProcessError(null);
            return response;
        } catch (err: any) {
            // if (err.response && err.response.status === 404) {
            //     window.location.href = '/';
            // }
            setUpperProcessError(err.response.data.detail);
            throw err;
        } finally {
            setUpperProcessLoading(false);
        }
    };
    return {
        getUpperProcess,
        upperProcessData,
        upperProcessLoading,
        upperProcessError,
        setUpperProcessData,
        setUpperProcessLoading,
        setUpperProcessError
    };
}

/** 
* 参照している下流プロセス/サブシステムの情報を取得する
* 
* @returns 
*/
export const useGetRefereedProcessInputs = () => {
    const [refereedProcessInputsData, setRefereedProcessInputsData] = useState<RefereedProcessInputs[]>([]);
    const [refereedProcessInputsLoading, setRefereedProcessInputsLoading] = useState(false);
    const [refereedProcessInputsError, setRefereedProcessInputsError] = useState<any>(null);
    const getRefereedProcessInputs = async (process_output_id: number) => {
        setRefereedProcessInputsLoading(true);
        try {
            const response = await getRequest<RefereedProcessInputs[]>(`/process_ios/${process_output_id}/refereed_process_inputs`);
            setRefereedProcessInputsData(response);
            setRefereedProcessInputsError(null);
            return response;
        } catch (err: any) {
            setRefereedProcessInputsError(err.response.data.detail);
            throw err;
        } finally {
            setRefereedProcessInputsLoading(false);
        }
    };
    return {
        getRefereedProcessInputs,
        refereedProcessInputsData,
        refereedProcessInputsLoading,
        refereedProcessInputsError,
        setRefereedProcessInputsData,
        setRefereedProcessInputsLoading,
        setRefereedProcessInputsError
    };
}

/**
 * プロセス検索
 * 
 * @returns 
 */
export const useSearchProsess = () => {
    const [searchProsessesData, setSearchProsessesData] = useState<SearchProcesses | null>(null);
    const [searchProsessesLoading, setSearchProsessesLoading] = useState(false);
    const [searchProsessesError, setSearchProsessesError] = useState<any>(null);
    const searchProsesses = async (
        DatabasePackId: number | undefined,
        SearchText: string = "",
        Page: number = 1,
        PageSize: number = 10,
        IsFulltextSearch: boolean = false,
        IsCurrentUserUpdated: boolean = false,
        IsCreatedDate: boolean = false,
        CreatedDateFrom: String | null = null,
        CreatedDateTo: String | null = null,
        IsUpdatedDate: boolean = false,
        UpdatedDateFrom: String | null = null,
        UpdatedDateTo: String | null = null
    ) => {
        setSearchProsessesLoading(true);
        try {
            const response = await postRequest<SearchProcesses>(
                `/database_packs/${DatabasePackId}/processes/search?search_text=${SearchText}&page=${Page}&page_size=${PageSize}`,
                {
                    database_pack_id: DatabasePackId,
                    is_fulltext_search: IsFulltextSearch,
                    is_current_user_updated: IsCurrentUserUpdated,
                    is_created_date: IsCreatedDate,
                    created_date_from: CreatedDateFrom,
                    created_date_to: CreatedDateTo,
                    is_updated_date: IsUpdatedDate,
                    updated_date_from: UpdatedDateFrom,
                    updated_date_to: UpdatedDateTo
                }
            );
            setSearchProsessesData(response);
            setSearchProsessesError(null);
            return response;
        } catch (err: any) {
            setSearchProsessesError(err.response.data.detail);
            throw err;
        } finally {
            setSearchProsessesLoading(false);
        }
    };
    return {
        searchProsesses,
        searchProsessesData,
        searchProsessesLoading,
        searchProsessesError,
        setSearchProsessesData,
        setSearchProsessesLoading,
        setSearchProsessesError
    };
}

/**
 * プロセス新規作成（既存製品）
 * 
 * @returns 
 */
export const useCreateProcess = () => {
    const [createProcessData, setCreateProcessData] = useState<CreateProcess | null>(null);
    const [createProcessLoading, setCreateProcessLoading] = useState(false);
    const [createProcessError, setCreateProcessError] = useState<any>(null);
    const createProcess = async (database_pack_id: number | undefined, param: object) => {
        setCreateProcessLoading(true);
        try {
            const response = await postRequest<CreateProcess>(`/database_packs/${database_pack_id}/processes/existing_product`, param);
            setCreateProcessData(response);
            setCreateProcessError(null);
            return response;
        } catch (err: any) {
            setCreateProcessError(err.response.data.detail);
            throw err;
        } finally {
            setCreateProcessLoading(false);
        }
    };
    return {
        createProcess,
        createProcessData,
        createProcessLoading,
        createProcessError,
        setCreateProcessData,
        setCreateProcessLoading,
        setCreateProcessError
    }
};


/**
 * プロセス新規作成（新規製品）
 * 
 * @returns 
 */
export const useCreateProcessAndProduct = () => {
    const [createProcessAndProductData, setCreateProcessAndProductData] = useState<CreateProcess | null>(null);
    const [createProcessAndProductLoading, setCreateProcessAndProductLoading] = useState(false);
    const [createProcessAndProductError, setCreateProcessAndProductError] = useState<any>(null);
    const createProcessAndProduct = async (database_pack_id: number | undefined, param: object) => {
        setCreateProcessAndProductLoading(true);
        try {
            const response = await postRequest<CreateProcess>(`/database_packs/${database_pack_id}/processes/new_product`, param);
            setCreateProcessAndProductData(response);
            setCreateProcessAndProductError(null);
            return response;
        } catch (err: any) {
            setCreateProcessAndProductError(err.response.data.detail);
            // throw err;
        } finally {
            setCreateProcessAndProductLoading(false);
        }
    };
    return {
        createProcessAndProduct,
        createProcessAndProductData,
        createProcessAndProductLoading,
        createProcessAndProductError,
        setCreateProcessAndProductData,
        setCreateProcessAndProductLoading,
        setCreateProcessAndProductError
    }
};

/**
 * 原単位型プロセスを作成する
 * 
 * @returns 
 */
export const useCreateUnitProcess = () => {
    const [createUnitProcessData, setCreateUnitProcessData] = useState<CreateProcess | null>(null);
    const [createUnitProcessLoading, setCreateUnitProcessLoading] = useState(false);
    const [createUnitProcessError, setCreateUnitProcessError] = useState<any>(null);
    const createUnitProcess = async (process_output_id: number) => {
        setCreateUnitProcessLoading(true);
        try {
            const response = await postRequest<CreateProcess>(`/process_ios/${process_output_id}/create_unit_process`);
            setCreateUnitProcessData(response);
            setCreateUnitProcessError(null);
            return response;
        } catch (err: any) {
            setCreateUnitProcessError(err.response.data.detail);
            throw err;
        } finally {
            setCreateUnitProcessLoading(false);
        }
    };
    return {
        createUnitProcess,
        createUnitProcessData,
        createUnitProcessLoading,
        createUnitProcessError,
        setCreateUnitProcessData,
        setCreateUnitProcessLoading,
        setCreateUnitProcessError
    }
};


/**
 * プロセスのコピー作成
 * 
 * @returns 
 */
export const useCreateProcessCopy = () => {
    const [createProcessCopyData, setCreateProcessCopyData] = useState<CreateProcess | null>(null);
    const [createProcessCopyLoading, setCreateProcessCopyLoading] = useState(false);
    const [createProcessCopyError, setCreateProcessCopyError] = useState<any>(null);
    const createProcessCopy = async (process_id: number) => {
        setCreateProcessCopyLoading(true);
        try {
            const response = await postRequest<CreateProcess>(`/processes/${process_id}/copy`);
            setCreateProcessCopyData(response);
            setCreateProcessCopyError(null);
            return response;
        } catch (err: any) {
            setCreateProcessCopyError(err.response.data.detail);
            throw err;
        } finally {
            setCreateProcessCopyLoading(false);
        }
    };
    return {
        createProcessCopy,
        createProcessCopyData,
        createProcessCopyLoading,
        createProcessCopyError,
        setCreateProcessCopyData,
        setCreateProcessCopyLoading,
        setCreateProcessCopyError
    }
};


/**
 * 開いたプロセスの履歴を取得する
 * 
 * @returns 
 */
export const useGetProcessHistories = () => {
    const [processHistoriesData, setProcessHistoriesData] = useState<ProcessHistories[] | null>(null);
    const [processHistoriesLoading, setProcessHistoriesLoading] = useState(false);
    const [processHistoriesError, setProcessHistoriesError] = useState<any>(null);
    const getProcessHistories = async () => {
        setProcessHistoriesLoading(true);
        try {
            const response = await getRequest<ProcessHistories[]>(`/process_histories`);
            setProcessHistoriesData(response);
            setProcessHistoriesError(null);
            return response;
        } catch (err: any) {
            setProcessHistoriesError(err.response.data.detail);
            throw err;
        } finally {
            setProcessHistoriesLoading(false);
        }
    };
    return {
        getProcessHistories,
        processHistoriesData,
        processHistoriesLoading,
        processHistoriesError,
        setProcessHistoriesData,
        setProcessHistoriesLoading,
        setProcessHistoriesError
    }
};


/**
 * 開いたプロセスの履歴に追加する
 * 
 * @returns 
 */
export const usePutProcessHistories = () => {
    const [processHistoriesData, setProcessHistoriesData] = useState<any | null>(null);
    const [processHistoriesLoading, setProcessHistoriesLoading] = useState(false);
    const [processHistoriesError, setProcessHistoriesError] = useState<any>(null);
    const putProcessHistories = async (process_id: number) => {
        setProcessHistoriesLoading(true);
        try {
            const response = await putRequest<any>(`/process_histories?process_id=${process_id}`);
            setProcessHistoriesData(response);
            setProcessHistoriesError(null);
            return response;
        } catch (err: any) {
            setProcessHistoriesError(err.response.data.detail);
            throw err;
        } finally {
            setProcessHistoriesLoading(false);
        }
    };
    return {
        putProcessHistories,
        processHistoriesData,
        processHistoriesLoading,
        processHistoriesError,
        setProcessHistoriesData,
        setProcessHistoriesLoading,
        setProcessHistoriesError
    }
};


/** 
* プロセス入出力のエクセルファイルを取得する
* 
* @returns 
*/
export const useGetProcessIosExcel = () => {
    const [processIosExcelData, setProcessIosExcelData] = useState<any | null>(null);
    const [processIosExcelLoading, setProcessIosExcelLoading] = useState(false);
    const [processIosExcelError, setProcessIosExcelError] = useState<any>(null);
    const getProcessIosExcel = async (process_id: number) => {
        setProcessIosExcelError(true);
        try {
            const response = await downloadFileRequest<any>(`/processes/${process_id}/process_ios_excel`);
            setProcessIosExcelData(response);
            setProcessIosExcelError(null);
            return response;
        } catch (err: any) {
            setProcessIosExcelError(err.response.data.detail);
            throw err;
        } finally {
            setProcessIosExcelLoading(false);
        }
    };
    return {
        getProcessIosExcel,
        processIosExcelData,
        processIosExcelLoading,
        processIosExcelError,
        setProcessIosExcelData,
        setProcessIosExcelError,
        setProcessIosExcelLoading
    };
}


/** 
* 単位マスタ
* 
* @returns 
*/
export const useGetUnitGroups = () => {
    const [unitGroupsData, setUnitGroupsData] = useState<UnitGroupList[] | null>(null);
    const [unitGroupsLoading, setUnitGroupsLoading] = useState(false);
    const [unitGroupsError, setUnitGroupsError] = useState<any>(null);
    const getUnitGroups = async (database_pack_id: number | undefined) => {
        setUnitGroupsLoading(true);
        try {
            const response = await getRequest<UnitGroupList[]>(`/database_packs/${database_pack_id}/unit_groups`);
            setUnitGroupsData(response);
            setUnitGroupsError(null);
            return response;
        } catch (err: any) {
            setUnitGroupsError(err.response.data.detail);
            throw err;
        } finally {
            setUnitGroupsLoading(false);
        }
    };
    return {
        getUnitGroups,
        unitGroupsData,
        unitGroupsLoading,
        unitGroupsError,
        setUnitGroupsData,
        setUnitGroupsLoading,
        setUnitGroupsError
    };
}


/** 
* 重量単位グループの一覧を取得
* 
* @returns 
*/
export const useGetUnitGroupsMass = () => {
    const [unitGroupsMassData, setUnitGroupsMassData] = useState<UnitGroupList | null>(null);
    const [unitGroupsMassLoading, setUnitGroupsMassLoading] = useState(false);
    const [unitGroupsMassError, setUnitGroupsMassError] = useState<any>(null);
    const getUnitGroupsMass = async (database_pack_id: number | undefined) => {
        setUnitGroupsMassLoading(true);
        try {
            const response = await getRequest<UnitGroupList>(`/database_packs/${database_pack_id}/unit_groups/mass`);
            setUnitGroupsMassData(response);
            setUnitGroupsMassError(null);
            return response;
        } catch (err: any) {
            setUnitGroupsMassError(err.response.data.detail);
            throw err;
        } finally {
            setUnitGroupsMassLoading(false);
        }
    };
    return {
        getUnitGroupsMass,
        unitGroupsMassData,
        unitGroupsMassLoading,
        unitGroupsMassError,
        setUnitGroupsMassData,
        setUnitGroupsMassLoading,
        setUnitGroupsMassError
    };
}


/** 
* 通貨単位グループの一覧を取得
* 
* @returns 
*/
export const useGetUnitGroupsCurrency = () => {
    const [unitGroupsCurrencyData, setUnitGroupsCurrencyData] = useState<UnitGroupList | null>(null);
    const [unitGroupsCurrencyLoading, setUnitGroupsCurrencyLoading] = useState(false);
    const [unitGroupsCurrencyError, setUnitGroupsCurrencyError] = useState<any>(null);
    const getUnitGroupsCurrency = async (database_pack_id: number | undefined) => {
        setUnitGroupsCurrencyLoading(true);
        try {
            const response = await getRequest<UnitGroupList>(`/database_packs/${database_pack_id}/unit_groups/currency`);
            setUnitGroupsCurrencyData(response);
            setUnitGroupsCurrencyError(null);
            return response;
        } catch (err: any) {
            setUnitGroupsCurrencyError(err.response.data.detail);
            throw err;
        } finally {
            setUnitGroupsCurrencyLoading(false);
        }
    };
    return {
        getUnitGroupsCurrency,
        unitGroupsCurrencyData,
        unitGroupsCurrencyLoading,
        unitGroupsCurrencyError,
        setUnitGroupsCurrencyData,
        setUnitGroupsCurrencyLoading,
        setUnitGroupsCurrencyError
    };
}


/** 
* 単位換算マスタ
* 
* @returns 
*/
export const useGetUnitConversions = () => {
    const [unitConversionsData, setUnitConversionsData] = useState<UnitConversionsList[] | null>(null);
    const [unitConversionsLoading, setUnitConversionsLoading] = useState(false);
    const [unitConversionsError, setUnitConversionsError] = useState<any>(null);
    const getUnitConversions = async (database_pack_id: number | undefined) => {
        setUnitConversionsLoading(true);
        try {
            const response = await getRequest<UnitConversionsList[]>(`/database_packs/${database_pack_id}/unit_conversions`);
            setUnitConversionsData(response);
            setUnitConversionsError(null);
            return response;
        } catch (err: any) {
            setUnitConversionsError(err.response.data.detail);
            throw err;
        } finally {
            setUnitConversionsLoading(false);
        }
    };
    return {
        getUnitConversions,
        unitConversionsData,
        unitConversionsLoading,
        unitConversionsError,
        setUnitConversionsData,
        setUnitConversionsLoading,
        setUnitConversionsError
    };
}


/**
 * カテゴリ一覧取得
 * 
 * @param id 
 * @returns 
 */
export const useGetCategoriesList = () => {
    const [categoriesListData, setCategoriesListData] = useState<CategoriesList[] | null>(null);
    const [categoriesListLoading, setCategoriesListLoading] = useState(false);
    const [categoriesListError, setCategoriesListrError] = useState<any>(null);
    const { data, loading, error } = useGetRequest<CategoriesList[]>(`/categories/`);
    useEffect(() => {
        setCategoriesListData(data);
        setCategoriesListLoading(loading);
        setCategoriesListrError(error)
    }, [data, loading, error])
    return {
        categoriesListData,
        categoriesListLoading,
        categoriesListError,
        setCategoriesListData,
        setCategoriesListLoading,
        setCategoriesListrError
    };
}


/**
 * カテゴリ取得
 * 
 * @param id 
 * @returns 
 */
export const useGetCategoryValues = (category_id: number | undefined) => {
    const [categoryValuesData, setCategoryValuesData] = useState<CategoryValueList[] | null>(null);
    const [categoryValuesLoading, setCategoryValuesLoading] = useState(false);
    const [categoryValuesError, setCategoryValuesError] = useState<any>(null);
    const { data, loading, error } = useGetRequest<CategoryValueList[]>(`/categories/${category_id}/category_values`);
    useEffect(() => {
        setCategoryValuesData(data);
        setCategoryValuesLoading(loading);
        setCategoryValuesError(error)
    }, [data, loading, error])
    return {
        categoryValuesData,
        categoryValuesLoading,
        categoryValuesError,
        setCategoryValuesData,
        setCategoryValuesLoading,
        setCategoryValuesError
    };
}


/**
 * カテゴリ取得(プロセス 製品表示用)
 * 
 * @param id 
 * @returns 
 */
export const useGetCategoryValuesForProsess = () => {
    const [categoryValuesData, setCategoryValuesData] = useState<CategoryValueList[] | null>(null);
    const [categoryValuesLoading, setCategoryValuesLoading] = useState(false);
    const [categoryValuesError, setCategoryValuesError] = useState<any>(null);
    const getCategoryValuesForProsess = async (category_id: number) => {
        setCategoryValuesLoading(true);
        try {
            const response = await getRequest<CategoryValueList[]>(`/categories/${category_id}/category_values`);
            setCategoryValuesData(response);
            setCategoryValuesError(null);
            return response;
        } catch (err: any) {
            setCategoryValuesError(err.response.data.detail);
            throw err;
        } finally {
            setCategoryValuesLoading(false);
        }
    };
    return {
        getCategoryValuesForProsess,
        categoryValuesData,
        categoryValuesLoading,
        categoryValuesError,
        setCategoryValuesData,
        setCategoryValuesLoading,
        setCategoryValuesError
    };
}

/**
 * 製品IDによる製品取得
 * 
 * @param id 
 * @returns 
 */
export const useGetProductById = (exchange_id: number) => {
    const [getProductByIdData, setGetProductByIdData] = useState<GetProductById | null>(null);
    const [getProductByIdLoading, setGetProductByIdLoading] = useState(false);
    const [getProductByIdError, setGetProductByIdError] = useState<any>(null);
    const { data, loading, error } = useGetRequest<GetProductById>(`/products/${exchange_id}`);
    useEffect(() => {
        setGetProductByIdData(data);
        setGetProductByIdLoading(loading);
        setGetProductByIdError(error)
    }, [data, loading, error])
    return {
        getProductByIdData,
        getProductByIdLoading,
        getProductByIdError,
        setGetProductByIdData,
        setGetProductByIdLoading,
        setGetProductByIdError
    };
}

/**
 * 製品IDによる製品取得
 * 
 * @param id 
 * @returns 
 */
export const useGetProductDetail = () => {
    const [getProductDetailData, setGetProductDetailData] = useState<GetProductById | null>(null);
    const [getProductDetailLoading, setGetProductDetailLoading] = useState(false);
    const [getProductDetailError, setGetProductDetailError] = useState<any>(null);
    const getProductDetail = async (exchange_id: number) => {
        setGetProductDetailLoading(true);
        try {
            const response = await getRequest<GetProductById | null>(`/products/${exchange_id}`);
            setGetProductDetailData(response);
            setGetProductDetailError(null);
            return response;
        } catch (err: any) {
            setGetProductDetailError(err.response.data.detail);
            throw err;
        } finally {
            setGetProductDetailLoading(false);
        }
    };
    return {
        getProductDetail,
        getProductDetailData,
        getProductDetailLoading,
        getProductDetailError,
        setGetProductDetailData,
        setGetProductDetailLoading,
        setGetProductDetailError
    };
}


/**
 * 製品削除
 * 
 * @returns 
 */
export const useDeleteProduct = () => {
    const [deleteProductData, setDeleteProductData] = useState<any>();
    const [deleteProductLoading, setDeleteProductLoading] = useState(false);
    const [deleteProductError, setDeleteProductError] = useState<any>(null);
    const deleteProduct = async (exchange_id: number) => {
        setDeleteProductLoading(true);
        try {
            const response = await deleteRequest<any>(`/products/${exchange_id}`);
            setDeleteProductData(response);
            setDeleteProductError(null);
            return response;
        } catch (err: any) {
            setDeleteProductError(err.response.data.detail);
            throw err;
        } finally {
            setDeleteProductLoading(false);
        }
    };
    return {
        deleteProduct,
        deleteProductData,
        deleteProductLoading,
        deleteProductError,
    };
}

/**
 * 製品更新
 * 
 * @returns 
 */
export const useUpdateProduct = () => {
    const [UpdateProductData, setUpdateProductData] = useState<UpdateProduct | null>(null);
    const [UpdateProductLoading, setUpdateProductLoading] = useState(false);
    const [UpdateProductError, setUpdateProductError] = useState<any>(null);
    const UpdateProduct = async (exchange_id: number | undefined, param: object) => {
        setUpdateProductLoading(true);
        try {
            const response = await putRequest<UpdateProduct>(`/products/${exchange_id}`, param);
            setUpdateProductData(response);
            setUpdateProductError(null);
            return response;
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                const isConfirmed = window.confirm(err.response.data.detail);
                if (isConfirmed) {
                    window.location.href = '/';
                }
            }
            setUpdateProductError(err.response.data.detail);
            throw err;
        } finally {
            setUpdateProductLoading(false);
        }
    };
    return {
        UpdateProduct,
        UpdateProductData,
        UpdateProductLoading,
        UpdateProductError,
        setUpdateProductData,
        setUpdateProductLoading,
        setUpdateProductError
    }
};


/**
 * 製品検索（クエリ）
 * 
 * @param id 
 * @returns 
 */
export const useSearchProductsByQuery = () => {
    const [searchProductsByQueryData, setSearchProductsByQueryData] = useState<SearchProducts | null>(null);
    const [searchProductsByQueryLoading, setSearchProductsByQueryLoading] = useState(false);
    const [searchProductsByQueryError, setSearchProductsByQueryError] = useState<any>(null);
    const searchProductsByQuery = async (
        DatabasePackId: number | undefined,
        SearchText: string = "",
        Page: number = 1,
        PageSize: number = 10,
        IsFulltextSearch: boolean = false,
        IsDefaultLanguage: boolean = false,
        IsCurrentUserUpdated: boolean = false,
        ProductType: number = 0,
    ) => {
        setSearchProductsByQueryLoading(true);
        try {
            const response = await postRequest<SearchProducts>(
                `/database_packs/${DatabasePackId}/products/search?search_text=${SearchText}&page=${Page}&page_size=${PageSize}`
                , {
                    database_pack_id: DatabasePackId,
                    is_fulltext_search: IsFulltextSearch,
                    is_default_language: IsDefaultLanguage,
                    is_current_user_updated: IsCurrentUserUpdated,
                    product_type: ProductType
                });
            setSearchProductsByQueryData(response);
            setSearchProductsByQueryError(null);
            return response;
        } catch (err: any) {
            setSearchProductsByQueryError(err.response.data.detail);
            throw err;
        } finally {
            setSearchProductsByQueryLoading(false);
        }
    };
    return {
        searchProductsByQuery,
        searchProductsByQueryData,
        searchProductsByQueryLoading,
        searchProductsByQueryError,
        setSearchProductsByQueryData,
        setSearchProductsByQueryLoading,
        setSearchProductsByQueryError
    };
}

/**
 * カテゴリから製品を取得する
 * 
 * @param id 
 * @returns 
 */
export const useGetProductByCategory = () => {
    const [productByCategoryData, setProductByCategoryData] = useState<GetProductById[] | null>(null);
    const [productByCategoryLoading, setProductByCategoryLoading] = useState(false);
    const [productByCategoryError, setProductByCategoryError] = useState<any>(null);
    const getProductByCategory = async (database_pack_id: number | undefined, category_id: number, category_value_id: number) => {
        setProductByCategoryLoading(true);
        try {
            const response = await getRequest<GetProductById[]>(`/database_packs/${database_pack_id}/categories/${category_id}/category_values/${category_value_id}/products/`);
            setProductByCategoryData(response);
            setProductByCategoryError(null);
            return response;
        } catch (err: any) {
            setProductByCategoryError(err.response.data.detail);
            throw err;
        } finally {
            setProductByCategoryLoading(false);
        }
    };
    return {
        getProductByCategory,
        productByCategoryData,
        productByCategoryLoading,
        productByCategoryError,
        setProductByCategoryData,
        setProductByCategoryLoading,
        setProductByCategoryError
    };
}

/**
 * 製品新規作成
 * 
 * @returns 
 */
export const useCreateProduct = () => {
    const [createProductData, setCreateProductData] = useState<CreateProduct | null>(null);
    const [createProductLoading, setCreateProductLoading] = useState(false);
    const [createProductError, setCreateProductError] = useState<any>(null);
    const createProduct = async (database_pack_id: number | undefined, param: object) => {
        setCreateProductLoading(true);
        try {
            const response = await postRequest<CreateProduct>(`/database_packs/${database_pack_id}/products`, param);
            setCreateProductData(response);
            setCreateProductError(null);
            return response;
        } catch (err: any) {
            setCreateProductError(err.response.data.detail);
            throw err;
        } finally {
            setCreateProductLoading(false);
        }
    };
    return {
        createProduct,
        createProductData,
        createProductLoading,
        createProductError,
        setCreateProductData,
        setCreateProductLoading,
        setCreateProductError
    }
};


/**
 *　製品を参照しているプロセス入出力を取得する
 * 
 * @returns 
 */
export const useGetReferredIo = () => {
    const [referredIoData, setReferredIoData] = useState<GetReferredIo[] | null>(null);
    const [referredIoLoading, setReferredIoLoading] = useState(false);
    const [referredIoError, setReferredIoError] = useState<any>(null);
    const getReferredIo = async (exchange_id: number | undefined) => {
        setReferredIoLoading(true);
        try {
            const response = await getRequest<GetReferredIo[]>(`/products/${exchange_id}/referred_process_ios`,);
            setReferredIoData(response);
            setReferredIoError(null);
            return response;
        } catch (err: any) {
            setReferredIoError(err);
            throw err;
        } finally {
            setReferredIoLoading(false);
        }
    };
    return {
        getReferredIo,
        referredIoData,
        referredIoLoading,
        referredIoError,
        setReferredIoData,
        setReferredIoLoading,
        setReferredIoError
    }
};


/**
 * 基本フロー検索
 * 
 * @returns 
 */
export const useSearchElementaryFlows = () => {
    const [searchElementaryFlowsData, setSearchElementaryFlowsData] = useState<SearchElementaryFlows | null>(null);
    const [searchElementaryFlowsLoading, setSearchElementaryFlowsLoading] = useState(false);
    const [searchElementaryFlowsError, setSearchElementaryFlowsError] = useState<any>(null);
    const searchElementaryFlows = async (
        DatabasePackId: number | undefined,
        SearchText: string = "",
        Page: number = 1,
        PageSize: number = 10,
        Category1Code: number | null = null,
        Category2CodeList: Array<number> | null = [],
        IsFulltextSearch: boolean = false,
        IsLciaResultFlow: boolean = false,
    ) => {
        setSearchElementaryFlowsLoading(true);
        try {
            const payload: any = {
                category1_code: Category1Code,
                is_fulltext_search: IsFulltextSearch,
                is_lcia_result_flow: IsLciaResultFlow,
            };

            if (Category2CodeList && Category2CodeList.length > 0) {
                payload.category2_code_list = Category2CodeList;
            } else {
                payload.category2_code_list = null
            }

            const response = await postRequest<SearchElementaryFlows>(`/database_packs/${DatabasePackId}/elementary_flows/search?search_text=${SearchText}&page=${Page}&page_size=${PageSize}`,
                payload
            );
            setSearchElementaryFlowsData(response);
            setSearchElementaryFlowsError(null);
            return response;
        } catch (err: any) {
            setSearchElementaryFlowsError(err.response.data.detail);
            throw err;
        } finally {
            setSearchElementaryFlowsLoading(false);
        }
    };
    return {
        searchElementaryFlows,
        searchElementaryFlowsData,
        searchElementaryFlowsLoading,
        searchElementaryFlowsError,
        setSearchElementaryFlowsData,
        setSearchElementaryFlowsLoading,
        setSearchElementaryFlowsError
    }
}

/** 
* 参照している下流プロセス/サブシステムの情報を取得する(基本フロー)
* 
* @returns 
*/
export const useGetRefereedProcessIos = () => {
    const [refereedProcessIosData, setRefereedProcessIosData] = useState<RefereedProcessInputs[]>([]);
    const [refereedProcessIosLoading, setRefereedProcessIosLoading] = useState(false);
    const [refereedProcessIosError, setRefereedProcessIosError] = useState<any>(null);
    const getRefereedProcessIos = async (elementary_flow_id: number) => {
        setRefereedProcessIosLoading(true);
        try {
            const response = await getRequest<RefereedProcessInputs[]>(`/elementary_flows/${elementary_flow_id}/referred_process_ios`);
            setRefereedProcessIosData(response);
            setRefereedProcessIosError(null);
            return response;
        } catch (err: any) {
            setRefereedProcessIosError(err.response.data.detail);
            throw err;
        } finally {
            setRefereedProcessIosLoading(false);
        }
    };
    return {
        getRefereedProcessIos,
        refereedProcessIosData,
        refereedProcessIosLoading,
        refereedProcessIosError,
        setRefereedProcessIosData,
        setRefereedProcessIosLoading,
        setRefereedProcessIosError
    };
}



/**
 *ファイルをアップロードする
 * 
 * @returns 
 */
export const useFileUpload = () => {
    const [fileUploadData, setFileUploadData] = useState<FileUpload | null>(null);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [fileUploadError, setFileUploadError] = useState<any>(null);
    const fileUpload = async (file: File) => {
        setFileUploadLoading(true);
        try {
            const response = await uploadFileRequest<FileUpload>(`/attached_files/`, file);
            setFileUploadData(response);
            setFileUploadError(null);
            return response;
        } catch (err: any) {
            setFileUploadError(err.response.data.detail);
            throw err;
        } finally {
            setFileUploadLoading(false);
        }
    };
    return {
        fileUpload,
        fileUploadData,
        fileUploadLoading,
        fileUploadError,
        setFileUploadData,
        setFileUploadLoading,
        setFileUploadError
    }
}


/** 
* ファイルをbase64エンコードして取得する
* 
* @returns 
*/
export const useGetAttachedFileBase64Image = () => {
    const [attachedFileBase64ImageData, setAttachedFileBase64ImageData] = useState<any | null>(null);
    const [attachedFileBase64ImageLoading, setAttachedFileBase64ImageLoading] = useState(false);
    const [attachedFileBase64ImageError, setAttachedFileBase64ImageError] = useState<any>(null);
    const getAttachedFileBase64Image = async (attached_file_id: number) => {
        setAttachedFileBase64ImageLoading(true);
        try {
            const response = await getRequest<any>(`/attached_files/${attached_file_id}/base64_image`);
            setAttachedFileBase64ImageData(response);
            setAttachedFileBase64ImageError(null);
            return response;
        } catch (err: any) {
            setAttachedFileBase64ImageError(err.response.data.detail);
            throw err;
        } finally {
            setAttachedFileBase64ImageLoading(false);
        }
    };
    return {
        getAttachedFileBase64Image,
        attachedFileBase64ImageData,
        attachedFileBase64ImageLoading,
        attachedFileBase64ImageError,
        setAttachedFileBase64ImageData,
        setAttachedFileBase64ImageLoading,
        setAttachedFileBase64ImageError
    };
}

/** 
* ロールに添付されたファイル一覧の取得
* 
* @returns 
*/
export const useGetRolesAttachedFiles = () => {
    const [RolesAttachedFilesData, setRolesAttachedFilesData] = useState<any | null>(null);
    const [RolesAttachedFilesLoading, setRolesAttachedFilesLoading] = useState(false);
    const [RolesAttachedFilesError, setRolesAttachedFilesError] = useState<any>(null);
    const getRolesAttachedFiles = async (role_id: number) => {
        setRolesAttachedFilesLoading(true);
        try {
            const response = await getRequest<any>(`/roles/${role_id}/attached_files/`);
            setRolesAttachedFilesData(response);
            setRolesAttachedFilesError(null);
            return response;
        } catch (err: any) {
            setRolesAttachedFilesError(err.response.data.detail);
            throw err;
        } finally {
            setRolesAttachedFilesLoading(false);
        }
    };
    return {
        getRolesAttachedFiles,
        RolesAttachedFilesData,
        RolesAttachedFilesLoading,
        RolesAttachedFilesError,
        setRolesAttachedFilesData,
        setRolesAttachedFilesLoading,
        setRolesAttachedFilesError
    };
}

/** 
* ロールにファイルをアップロードする
* 
* @returns 
*/
export const useUploadRolesAttachedFiles = () => {
    const [uploadRolesAttachedFilesData, setUploadRolesAttachedFilesData] = useState<any | null>(null);
    const [uploadRolesAttachedFilesLoading, setUploadRolesAttachedFilesLoading] = useState(false);
    const [uploadRolesAttachedFilesError, setUploadRolesAttachedFilesError] = useState<any>(null);
    const uploadRolesAttachedFiles = async (role_id: number, file: File) => {
        setUploadRolesAttachedFilesLoading(true);
        try {
            const response = await uploadFileRequest<any>(`/roles/${role_id}/attached_files/`, file);
            setUploadRolesAttachedFilesData(response);
            setUploadRolesAttachedFilesError(null);
            return response;
        } catch (err: any) {
            setUploadRolesAttachedFilesError(err.response.data.detail);
            throw err;
        } finally {
            setUploadRolesAttachedFilesLoading(false);
        }
    };
    return {
        uploadRolesAttachedFiles,
        uploadRolesAttachedFilesData,
        uploadRolesAttachedFilesLoading,
        uploadRolesAttachedFilesError,
        setUploadRolesAttachedFilesData,
        setUploadRolesAttachedFilesLoading,
        setUploadRolesAttachedFilesError
    }
}
/**
 * アップロードされたファイルを削除する
 * 
 * @returns 
 */
export const useDeleteAttachedFile = () => {
    const [deleteAttachedFileData, setDeleteAttachedFileData] = useState<any>();
    const [deleteAttachedFileLoading, setDeleteAttachedFileLoading] = useState(false);
    const [deleteAttachedFileError, setDeleteAttachedFileError] = useState<any>(null);
    const deleteAttachedFile = async (attached_file_id: number) => {
        setDeleteAttachedFileLoading(true);
        try {
            const response = await deleteRequest<any>(`/attached_files/${attached_file_id}`);
            setDeleteAttachedFileData(response);
            setDeleteAttachedFileError(null);
            return response;
        } catch (err: any) {
            setDeleteAttachedFileError(err.response.data.detail);
            throw err;
        } finally {
            setDeleteAttachedFileLoading(false);
        }
    };
    return {
        deleteAttachedFile,
        deleteAttachedFileData,
        deleteAttachedFileLoading,
        deleteAttachedFileError,
    };
}


/**
 * 共通ファイルすべての取得
 * 
 * @returns 
 */
export const useGetCommonAttachedFileList = () => {
    const [commonAttachedFileListData, setCommonAttachedFileListData] = useState<CommonAttachedFiles[]>([]);
    const [commonAttachedFileListLoading, setCommonAttachedFileListLoading] = useState(false);
    const [commonAttachedFileListError, setCommonAttachedFileListError] = useState<any>(null);
    const getCommonAttachedFileList = async () => {
        setCommonAttachedFileListLoading(true);
        try {
            const response = await getRequest<CommonAttachedFiles[]>(`/common_attached_files`);
            setCommonAttachedFileListData(response);
            setCommonAttachedFileListError(null);
            return response;
        } catch (err: any) {
            setCommonAttachedFileListError(err.response.data.detail);
            throw err;
        } finally {
            setCommonAttachedFileListLoading(false);
        }
    };
    return {
        getCommonAttachedFileList,
        commonAttachedFileListData,
        commonAttachedFileListLoading,
        commonAttachedFileListError,
    };
}


/** 
* データ品質指標の一覧を取得
* 
* @returns 
*/
export const useGetDataQualityIndicators = () => {
    const [dataQualityIndicatorsData, setDataQualityIndicatorsData] = useState<DataQualityIndicators[] | null>(null);
    const [dataQualityIndicatorsLoading, setDataQualityIndicatorsLoading] = useState(false);
    const [dataQualityIndicatorsError, setDataQualityIndicatorsError] = useState<any>(null);
    const getDataQualityIndicators = async () => {
        setDataQualityIndicatorsLoading(true);
        try {
            const response = await getRequest<DataQualityIndicators[]>(`/data_quality_indicators/`);
            setDataQualityIndicatorsData(response);
            setDataQualityIndicatorsError(null);
            return response;
        } catch (err: any) {
            setDataQualityIndicatorsError(err.response.data.detail);
            throw err;
        } finally {
            setDataQualityIndicatorsLoading(false);
        }
    };
    return {
        getDataQualityIndicators,
        dataQualityIndicatorsData,
        dataQualityIndicatorsLoading,
        dataQualityIndicatorsError,
        setDataQualityIndicatorsData,
        setDataQualityIndicatorsLoading,
        setDataQualityIndicatorsError
    };
}

/** 
* レビュータイプを返す
* 
* @returns 
*/
export const useGetReviewLevelType = () => {
    const [reviewLevelTypeData, setReviewLevelTypeData] = useState<any[] | null>(null);
    const [reviewLevelTypeLoading, setReviewLevelTypeLoading] = useState(false);
    const [reviewLevelTypeError, setReviewLevelTypeError] = useState<any>(null);
    const getReviewLevelType = async () => {
        setReviewLevelTypeLoading(true);
        try {
            const response = await getRequest<any[]>(`/review_level_type/`);
            setReviewLevelTypeData(response);
            setReviewLevelTypeError(null);
            return response;
        } catch (err: any) {
            setReviewLevelTypeError(err.response.data.detail);
            throw err;
        } finally {
            setReviewLevelTypeLoading(false);
        }
    };
    return {
        getReviewLevelType,
        reviewLevelTypeData,
        reviewLevelTypeLoading,
        reviewLevelTypeError,
        setReviewLevelTypeData,
        setReviewLevelTypeLoading,
        setReviewLevelTypeError
    };
}

/** 
* Enum値を返す
* 
* @returns 
*/
export const useGetEnums = () => {
    const [enumsData, setEnumsData] = useState<any[] | null>(null);
    const [enumsLoading, setEnumsLoading] = useState(false);
    const [enumsError, setEnumsError] = useState<any>(null);
    const getEnums = async () => {
        setEnumsLoading(true);
        try {
            const response = await getRequest<any[]>(`/enums/`);
            setEnumsData(response);
            setEnumsError(null);
            return response;
        } catch (err: any) {
            setEnumsError(err.response.data.detail);
            throw err;
        } finally {
            setEnumsLoading(false);
        }
    };
    return {
        getEnums,
        enumsData,
        enumsLoading,
        enumsError,
        setEnumsData,
        setEnumsLoading,
        setEnumsError
    };
}


/** 
* ケーススタディカテゴリセットの一覧を取得する
* 
* @returns 
*/
export const useGetSubsystemCategorySet = () => {
    const [subsystemCategorySetData, setSubsystemCategorySetData] = useState<SubsystemCategorySet[] | null>(null);
    const [subsystemCategorySetLoading, setSubsystemCategorySetLoading] = useState(false);
    const [subsystemCategorySetError, setSubsystemCategorySetError] = useState<any>(null);
    const getSubsystemCategorySet = async (case_study_id: number) => {
        setSubsystemCategorySetLoading(true);
        try {
            const response = await getRequest<SubsystemCategorySet[]>(`/case_studies/${case_study_id}/subsystem_category_set/`);
            setSubsystemCategorySetData(response);
            setSubsystemCategorySetError(null);
            return response;
        } catch (err: any) {
            setSubsystemCategorySetError(err.response.data.detail);
            throw err;
        } finally {
            setSubsystemCategorySetLoading(false);
        }
    };
    return {
        getSubsystemCategorySet,
        subsystemCategorySetData,
        subsystemCategorySetLoading,
        subsystemCategorySetError,
        setSubsystemCategorySetData,
        setSubsystemCategorySetLoading,
        setSubsystemCategorySetError
    };
}

/** 
* ケーススタディにユーザ定義カテゴリセットを作成する
* 
* @returns 
*/
export const useCreateSubsystemCategorySet = () => {
    const [createSubsystemCategorySetData, setCreateSubsystemCategorySetData] = useState<SubsystemCategorySet[] | null>(null);
    const [createSubsystemCategorySetLoading, setCreateSubsystemCategorySetLoading] = useState(false);
    const [createSubsystemCategorySetError, setCreateSubsystemCategorySetError] = useState<any>(null);
    const createSubsystemCategorySet = async (case_study_id: number, param: object) => {
        setCreateSubsystemCategorySetLoading(true);
        try {
            const response = await postRequest<SubsystemCategorySet[]>(`/case_studies/${case_study_id}/subsystem_category_set/`, param);
            setCreateSubsystemCategorySetData(response);
            setCreateSubsystemCategorySetError(null);
            return response;
        } catch (err: any) {
            setCreateSubsystemCategorySetError(err.response.data.detail);
            throw err;
        } finally {
            setCreateSubsystemCategorySetLoading(false);
        }
    };
    return {
        createSubsystemCategorySet,
        createSubsystemCategorySetData,
        createSubsystemCategorySetLoading,
        createSubsystemCategorySetError,
        setCreateSubsystemCategorySetData,
        setCreateSubsystemCategorySetLoading,
        setCreateSubsystemCategorySetError
    };
}


/** 
* ケーススタディのユーザ定義カテゴリセットを更新する
* 
* @returns 
*/
export const useUpdateSubsystemCategorySet = () => {
    const [updateSubsystemCategorySetData, setUpdateSubsystemCategorySetData] = useState<SubsystemCategorySet | null>(null);
    const [updateSubsystemCategorySetLoading, setUpdateSubsystemCategorySetLoading] = useState(false);
    const [updateSubsystemCategorySetError, setUpdateSubsystemCategorySetError] = useState<any>(null);
    const updateSubsystemCategorySet = async (case_study_id: number, param: object | undefined) => {
        setUpdateSubsystemCategorySetLoading(true);
        try {
            const response = await putRequest<SubsystemCategorySet>(`/case_studies/${case_study_id}/subsystem_category_set/`, param);
            setUpdateSubsystemCategorySetData(response);
            setUpdateSubsystemCategorySetError(null);
            return response;
        } catch (err: any) {
            setUpdateSubsystemCategorySetError(err.response.data.detail);
            throw err;
        } finally {
            setUpdateSubsystemCategorySetLoading(false);
        }
    };
    return {
        updateSubsystemCategorySet,
        updateSubsystemCategorySetData,
        updateSubsystemCategorySetLoading,
        updateSubsystemCategorySetError,
        setUpdateSubsystemCategorySetData,
        setUpdateSubsystemCategorySetLoading,
        setUpdateSubsystemCategorySetError
    };
}


/** 
* システムサブシステムカテゴリセットをコピー
* このケーススタディにユーザ定義サブシステムカテゴリセットを作成する
* 
* @returns 
*/
export const useCopySubsystemCategorySet = () => {
    const [copySubsystemCategorySetData, setCopySubsystemCategorySetData] = useState<SubsystemCategorySet | null>(null);
    const [copySubsystemCategorySetLoading, setCopySubsystemCategorySetLoading] = useState(false);
    const [copySubsystemCategorySetError, setCopySubsystemCategorySetError] = useState<any>(null);

    const copySubsystemCategorySet = async (
        case_study_id: number, 
        system_subsystem_category_set_id: number
    ) => {
        setCopySubsystemCategorySetLoading(true);
        try {
            const response = await postRequest<SubsystemCategorySet>(
                `/case_studies/${case_study_id}/subsystem_category_set/${system_subsystem_category_set_id}/copy_as_user`
            );
            setCopySubsystemCategorySetData(response);
            setCopySubsystemCategorySetError(null);
            return response;
        } catch (err: any) {
            setCopySubsystemCategorySetError(err.response.data.detail);
            throw err;
        } finally {
            setCopySubsystemCategorySetLoading(false);
        }
    };
    return {
        copySubsystemCategorySet,
        copySubsystemCategorySetData,
        copySubsystemCategorySetLoading,
        copySubsystemCategorySetError,
        setCopySubsystemCategorySetData,
        setCopySubsystemCategorySetLoading,
        setCopySubsystemCategorySetError
    };
}


/** 
* ケーススタディのユーザ定義カテゴリセットを削除する
* 
* @returns 
*/
export const useDeleteSubsystemCategorySet = () => {
    const [deleteSubsystemCategorySetData, setDeleteSubsystemCategorySetData] = useState<any>();
    const [deleteSubsystemCategorySetLoading, setDeleteSubsystemCategorySetLoading] = useState(false);
    const [deleteSubsystemCategorySetError, setDeleteSubsystemCategorySetError] = useState<any>(null);
    const deleteSubsystemCategorySet = async (case_study_id: number, user_subsystem_category_set_id: number) => {
        setDeleteSubsystemCategorySetLoading(true);
        try {
            const response = await deleteRequest<any>(`/case_studies/${case_study_id}/subsystem_category_set/${user_subsystem_category_set_id}`);
            setDeleteSubsystemCategorySetData(response);
            setDeleteSubsystemCategorySetError(null);
            return response;
        } catch (err: any) {
            setDeleteSubsystemCategorySetError(err.response.data.detail);
            throw err;
        } finally {
            setDeleteSubsystemCategorySetLoading(false);
        }
    };
    return {
        deleteSubsystemCategorySet,
        deleteSubsystemCategorySetData,
        deleteSubsystemCategorySetLoading,
        deleteSubsystemCategorySetError,
        setDeleteSubsystemCategorySetData,
        setDeleteSubsystemCategorySetLoading,
        setDeleteSubsystemCategorySetError
    };
}



/** 
* ケーススタディ新規作成
* 
* @returns 
*/
export const useCreateCaseStudy = () => {
    const [createCaseStudyData, setCreateCaseStudyData] = useState<CreateCaseStudy | null>(null);
    const [createCaseStudyLoading, setCreateCaseStudyLoading] = useState(false);
    const [createCaseStudyError, setCreateCaseStudyError] = useState<any>(null);
    const createCaseStudy = async (database_pack_id: number, params: object) => {
        setCreateCaseStudyLoading(true);
        try {
            const response = await postRequest<CreateCaseStudy>(`/database_packs/${database_pack_id}/case_study`, params);
            setCreateCaseStudyData(response);
            setCreateCaseStudyError(null);
            return response;
        } catch (err: any) {
            setCreateCaseStudyError(err.response.data.detail);
            throw err;
        } finally {
            setCreateCaseStudyLoading(false);
        }
    };
    return {
        createCaseStudy,
        createCaseStudyData,
        createCaseStudyLoading,
        createCaseStudyError,
        setCreateCaseStudyData,
        setCreateCaseStudyLoading,
        setCreateCaseStudyError
    };
}

/** 
* ケーススタディ取得
* 
* @returns 
*/
export const useGetCaseStudy = () => {
    const [caseStudyData, setCaseStudyData] = useState<GetCaseStudy | null>(null);
    const [caseStudyLoading, setCaseStudyLoading] = useState(false);
    const [caseStudyError, setCaseStudyError] = useState<any>(null);
    const getCaseStudy = async (case_study_id: number) => {
        setCaseStudyLoading(true);
        try {
            const response = await getRequest<GetCaseStudy>(`/case_studies/${case_study_id}`);
            setCaseStudyData(response);
            setCaseStudyError(null);
            return response;
        } catch (err: any) {
            setCaseStudyError(err.response.data.detail);
            throw err;
        } finally {
            setCaseStudyLoading(false);
        }
    };
    return {
        getCaseStudy,
        caseStudyData,
        caseStudyLoading,
        caseStudyError,
        setCaseStudyData,
        setCaseStudyLoading,
        setCaseStudyError
    };
}

/**
 * ケーススタディ削除
 * 
 * @returns 
 */
export const useDeleteCaseStudy = () => {
    const [deleteCaseStudyData, setDeleteCaseStudyData] = useState<any>();
    const [deleteCaseStudyLoading, setDeleteCaseStudyLoading] = useState(false);
    const [deleteCaseStudyError, setDeleteProcessError] = useState<any>(null);
    const deleteCaseStudy = async (case_study_id: number) => {
        setDeleteCaseStudyLoading(true);
        try {
            const response = await deleteRequest<any>(`/case_studies/${case_study_id}`);
            setDeleteCaseStudyData(response);
            setDeleteProcessError(null);
            return response;
        } catch (err: any) {
            setDeleteProcessError(err.response.data.detail);
            throw err;
        } finally {
            setDeleteCaseStudyLoading(false);
        }
    };
    return {
        deleteCaseStudy,
        deleteCaseStudyData,
        deleteCaseStudyLoading,
        deleteCaseStudyError,
    };
}

/** 
* ケーススタディ更新
* 
* @returns 
*/
export const useUpdateCaseStudy = () => {
    const [updateCaseStudyData, setUpdateCaseStudyData] = useState<UpdateCaseStudy | null>(null);
    const [updateCaseStudyLoading, setUpdateCaseStudyLoading] = useState(false);
    const [updateCaseStudyError, setUpdateCaseStudyError] = useState<any>(null);
    const updateCaseStudy = async (case_study_id: number | undefined, params: object) => {
        setUpdateCaseStudyLoading(true);
        try {
            const response = await putRequest<UpdateCaseStudy>(`/case_studies/${case_study_id}`, params);
            setUpdateCaseStudyData(response);
            setUpdateCaseStudyError(null);
            return response;
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                window.location.href = '/';
            }
            setUpdateCaseStudyError(err.response.data.detail);
            throw err;
        } finally {
            setUpdateCaseStudyLoading(false);
        }
    };
    return {
        updateCaseStudy,
        updateCaseStudyData,
        updateCaseStudyLoading,
        updateCaseStudyError,
        setUpdateCaseStudyData,
        setUpdateCaseStudyLoading,
        setUpdateCaseStudyError
    };
}

/**
 * ケーススタディを別名で保存する
 * 
 * @returns
 */
export const useSaveAsNewCaseStudy = () => {
    const [saveAsNewCaseStudyData, setSaveAsNewCaseStudyData] = useState<any>(null);
    const [saveAsNewCaseStudyLoading, setSaveAsNewCaseStudyLoading] = useState(false);
    const [saveAsNewCaseStudyError, setSaveAsNewCaseStudyError] = useState<any>(null);
    const saveAsNewCaseStudy = async (org_case_study_id: number | undefined, params: object) => {
        setSaveAsNewCaseStudyLoading(true);
        try {
            const response = await postRequest<any>(`/case_studies/${org_case_study_id}/save_as_new`, params);
            setSaveAsNewCaseStudyData(response);
            setSaveAsNewCaseStudyError(null);
            return response;
        } catch (err: any) {
            if (err?.response && err.response.data?.detail) {
                setSaveAsNewCaseStudyError(err.response.data.detail);
            }else {
                setSaveAsNewCaseStudyError(err);
            }
            throw err;
        } finally {
            setSaveAsNewCaseStudyLoading(false);
        }
    };
    return {
        saveAsNewCaseStudy,
        saveAsNewCaseStudyData,
        saveAsNewCaseStudyLoading,
        saveAsNewCaseStudyError,
        setSaveAsNewCaseStudyData,
        setSaveAsNewCaseStudyLoading,
        setSaveAsNewCaseStudyError
    };
}


/**
 * ケーススタディ検索
 * 
 * @returns 
 */
export const useSearchCaseStudies = () => {
    const [searchCaseStudiesData, setSearchCaseStudiesData] = useState<SearchCaseStudies | null>(null);
    const [searchCaseStudiesLoading, setSearchCaseStudiesLoading] = useState(false);
    const [searchCaseStudiesError, setSearchCaseStudiesError] = useState<any>(null);
    const searchCaseStudies = async (
        DatabasePackId: number | undefined,
        SearchText: string = "",
        Page: number = 1,
        PageSize: number = 10,
        IsFulltextSearch: boolean = false,
        IsCurrentUserUpdated: boolean = false,
        IsCreatedDate: boolean = false,
        CreatedDateFrom: String | null = null,
        CreatedDateTo: String | null = null,
        IsUpdatedDate: boolean = false,
        UpdatedDateFrom: String | null = null,
        UpdatedDateTo: String | null = null
    ) => {
        setSearchCaseStudiesLoading(true);
        try {
            const response = await postRequest<SearchCaseStudies>(
                `/database_packs/${DatabasePackId}/case_studies/search?search_text=${SearchText}&page=${Page}&page_size=${PageSize}`,
                {
                    database_pack_id: DatabasePackId,
                    is_fulltext_search: IsFulltextSearch,
                    is_current_user_updated: IsCurrentUserUpdated,
                    is_created_date: IsCreatedDate,
                    created_date_from: CreatedDateFrom,
                    created_date_to: CreatedDateTo,
                    is_updated_date: IsUpdatedDate,
                    updated_date_from: UpdatedDateFrom,
                    updated_date_to: UpdatedDateTo
                }
            );
            setSearchCaseStudiesData(response);
            setSearchCaseStudiesError(null);
            return response;
        } catch (err: any) {
            setSearchCaseStudiesError(err.response.data.detail);
            throw err;
        } finally {
            setSearchCaseStudiesLoading(false);
        }
    };
    return {
        searchCaseStudies,
        searchCaseStudiesData,
        searchCaseStudiesLoading,
        searchCaseStudiesError,
        setSearchCaseStudiesData,
        setSearchCaseStudiesLoading,
        setSearchCaseStudiesError
    };
}


/**
 * プロセスの環境負荷を計算する
 * @returns 
 */
export const useCalculate = () => {
    const [calculateData, setCalculateData] = useState<ProcessCalculate[] | null>(null);
    const [calculateLoading, setCalculateLoading] = useState(false);
    const [calculateError, setCalculateError] = useState<any>(null);
    const executeCalculate = async (process_id: number) => {
        setCalculateLoading(true);
        try {
            const response = await postRequest<ProcessCalculate[]>(`/processes/${process_id}/calculate`);
            setCalculateData(response);
            setCalculateError(null);
            // return response;
        } catch (err: any) {
            setCalculateError(err.response.data.detail);
            throw err;
        } finally {
            setCalculateLoading(false);
        }
    };
    return {
        executeCalculate,
        calculateData,
        calculateLoading,
        calculateError,
        setCalculateData,
        setCalculateLoading,
        setCalculateError
    };
}


/**
 * ケーススタディの環境負荷を計算する
 * @returns 
 */
export const useCaseStudyCalculate = () => {
    const [calculateData, setCalculateData] = useState<CaseStudyCalculate | null>(null);
    const [calculateLoading, setCalculateLoading] = useState(false);
    const [calculateError, setCalculateError] = useState<any>(null);
    const executeCalculate = async (case_study_id: number) => {
        setCalculateLoading(true);
        try {
            const response = await postRequest<CaseStudyCalculate>(`/case_studies/${case_study_id}/calculate`);
            setCalculateData(response);
            setCalculateError(null);
            // return response;
        } catch (err: any) {
            setCalculateError(err.response.data.detail);
            throw err;
        } finally {
            setCalculateLoading(false);
        }
    };
    return {
        executeCalculate,
        calculateData,
        calculateLoading,
        calculateError,
        setCalculateData,
        setCalculateLoading,
        setCalculateError
    };
}


/**
 * プロセスの計算結果を取得する
 * @returns 
 */
export const useGetLciResults = () => {
    const [lciResultsData, setLciResultsData] = useState<ProcessCalculate[] | null>(null);
    const [lciResultsLoading, setLciResultsLoading] = useState(false);
    const [lciResultsError, setLciResultsError] = useState<any>(null);
    const getLciResults = async (process_id: number) => {
        setLciResultsLoading(true);
        try {
            const response = await getRequest<ProcessCalculate[]>(`/processes/${process_id}/lci_results`);
            setLciResultsData(response);
            setLciResultsError(null);
            return response;
        } catch (err: any) {
            // setLciResultsError(err.response.data.detail);
            setLciResultsError(err);
            throw err;
        } finally {
            setLciResultsLoading(false);
        }
    };
    return {
        getLciResults,
        lciResultsData,
        lciResultsLoading,
        lciResultsError,
        setLciResultsData,
        setLciResultsLoading,
        setLciResultsError
    };
}

/**
 * ケーススタディの計算結果(サブシステムごと)を取得する
 * @returns 
 */
export const useGetCaseStudyLciResults = () => {
    const [lciResultsData, setLciResultsData] = useState<CaseStudyCalculate | null>(null);
    const [lciResultsLoading, setLciResultsLoading] = useState(false);
    const [lciResultsError, setLciResultsError] = useState<any>(null);
    const getLciResults = async (case_study_id: number) => {
        setLciResultsLoading(true);
        try {
            const response = await getRequest<CaseStudyCalculate>(`/case_studies/${case_study_id}/lci_result`);
            setLciResultsData(response);
            setLciResultsError(null);
            return response;
        } catch (err: any) {
            // setLciResultsError(err.response.data.detail);
            setLciResultsError(err);
            throw err;
        } finally {
            setLciResultsLoading(false);
        }
    };
    return {
        getLciResults,
        lciResultsData,
        lciResultsLoading,
        lciResultsError,
        setLciResultsData,
        setLciResultsLoading,
        setLciResultsError
    };
}



/**
 * ケーススタディのLCIA結果（サブシステムごと）を取得する
 * 
 * @returns 
 */
export const useCaseStudyLciaResults = () => {
    const [caseStudyLciaResultsData, setCaseStudyLciaResultsData] = useState<CaseStudyLCIAResults | null>(null);
    const [caseStudyLciaResultsLoading, setCaseStudyLciaResultsLoading] = useState(false);
    const [caseStudyLciaResultsError, setCaseStudyLciaResultsError] = useState<any>(null);
    const excuteCaseStudyLciaResults = async (case_study_id: number, param: Array<number>) => {
        setCaseStudyLciaResultsLoading(true);
        try {
            const response = await postRequest<CaseStudyLCIAResults>(`/case_studies/${case_study_id}/lcia_result`, param);
            setCaseStudyLciaResultsData(response);
            setCaseStudyLciaResultsError(null);
            return response;
        } catch (err: any) {
            // setCaseStudyLciaResultsError(err.response.data.detail);
            setCaseStudyLciaResultsError(err);
            throw err;
        } finally {
            setCaseStudyLciaResultsLoading(false);
        }
    };
    return {
        excuteCaseStudyLciaResults,
        caseStudyLciaResultsData,
        caseStudyLciaResultsLoading,
        caseStudyLciaResultsError,
        setCaseStudyLciaResultsData,
        setCaseStudyLciaResultsLoading,
        setCaseStudyLciaResultsError
    };
}


/**
 * ケーススタディの計算結果(上流ごと)を取得する
 * @returns 
 */
export const useGetCaseStudyLciResultsUpper = () => {
    const [lciResultsUpperData, setLciResultsUpperData] = useState<CaseStudyCalculate | null>(null);
    const [lciResultsUpperLoading, setLciResultsUpperLoading] = useState(false);
    const [lciResultsUpperError, setLciResultsUpperError] = useState<any>(null);
    const getLciResultsUpper = async (case_study_id: number) => {
        setLciResultsUpperLoading(true);
        try {
            const response = await getRequest<CaseStudyCalculate>(`/case_studies/${case_study_id}/lci_result/upper`);
            setLciResultsUpperData(response);
            setLciResultsUpperError(null);
            return response;
        } catch (err: any) {
            // setLciResultsUpperError(err.response.data.detail);
            setLciResultsUpperError(err);
            throw err;
        } finally {
            setLciResultsUpperLoading(false);
        }
    };
    return {
        getLciResultsUpper,
        lciResultsUpperData,
        lciResultsUpperLoading,
        lciResultsUpperError,
        setLciResultsUpperData,
        setLciResultsUpperLoading,
        setLciResultsUpperError
    };
}

/**
 * ケーススタディのLCIA結果（上流ごと）を取得する
 * 
 * @returns 
 */
export const useCaseStudyLciaResultsUpper = () => {
    const [caseStudyLciaResultsUpperData, setCaseStudyLciaResultsUpperData] = useState<CaseStudyLCIAResults | null>(null);
    const [caseStudyLciaResultsUpperLoading, setCaseStudyLciaResultsUpperLoading] = useState(false);
    const [caseStudyLciaResultsUpperError, setCaseStudyLciaResultsUpperError] = useState<any>(null);
    const excuteCaseStudyLciaResultsUpper = async (case_study_id: number, param: Array<number>) => {
        setCaseStudyLciaResultsUpperLoading(true);
        try {
            const response = await postRequest<CaseStudyLCIAResults>(`/case_studies/${case_study_id}/lcia_result/upper`, param);
            setCaseStudyLciaResultsUpperData(response);
            setCaseStudyLciaResultsUpperError(null);
            return response;
        } catch (err: any) {
            // setCaseStudyLciaResultsError(err.response.data.detail);
            setCaseStudyLciaResultsUpperError(err);
            throw err;
        } finally {
            setCaseStudyLciaResultsUpperLoading(false);
        }
    };
    return {
        excuteCaseStudyLciaResultsUpper,
        caseStudyLciaResultsUpperData,
        caseStudyLciaResultsUpperLoading,
        caseStudyLciaResultsUpperError,
        setCaseStudyLciaResultsUpperData,
        setCaseStudyLciaResultsUpperLoading,
        setCaseStudyLciaResultsUpperError
    };
}


/**
 * ケーススタディの計算結果(カテゴリごと)を取得する
 * @returns 
 */
export const useGetCaseStudyLciResultsCategory = () => {
    const [lciResultsCategoryData, setLciResultsCategoryData] = useState<CaseStudyCalculate | null>(null);
    const [lciResultsCategoryLoading, setLciResultsCategoryLoading] = useState(false);
    const [lciResultsCategoryError, setLciResultsCategoryError] = useState<any>(null);
    const getLciResultsCategory = async (case_study_id: number, subsystem_category_set_id: number) => {
        setLciResultsCategoryLoading(true);
        try {
            const response = await getRequest<CaseStudyCalculate>(`/case_studies/${case_study_id}/category/${subsystem_category_set_id}/lci_result/`);
            setLciResultsCategoryData(response);
            setLciResultsCategoryError(null);
            return response;
        } catch (err: any) {
            // setLciResultsCategoryError(err.response.data.detail);
            setLciResultsCategoryError(err);
            throw err;
        } finally {
            setLciResultsCategoryLoading(false);
        }
    };
    return {
        getLciResultsCategory,
        lciResultsCategoryData,
        lciResultsCategoryLoading,
        lciResultsCategoryError,
        setLciResultsCategoryData,
        setLciResultsCategoryLoading,
        setLciResultsCategoryError
    };
}


/**
 * ケーススタディのLCIA結果（カテゴリごと）を取得する
 * 
 * @returns 
 */
export const useCaseStudyLciaResultsCategory = () => {
    const [caseStudyLciaResultsCategoryData, setCaseStudyLciaResultsCategoryData] = useState<CaseStudyLCIAResults | null>(null);
    const [caseStudyLciaResultsCategoryLoading, setCaseStudyLciaResultsCategoryLoading] = useState(false);
    const [caseStudyLciaResultsCategoryError, setCaseStudyLciaResultsCategoryError] = useState<any>(null);
    const excuteCaseStudyLciaResultsCategory = async (case_study_id: number, subsystem_category_set_id: number, param: Array<number>) => {
        setCaseStudyLciaResultsCategoryLoading(true);
        try {
            const response = await postRequest<CaseStudyLCIAResults>(`/case_studies/${case_study_id}/category/${subsystem_category_set_id}/lcia_result/`, param);
            setCaseStudyLciaResultsCategoryData(response);
            setCaseStudyLciaResultsCategoryError(null);
            return response;
        } catch (err: any) {
            // setCaseStudyLciaResultsError(err.response.data.detail);
            setCaseStudyLciaResultsCategoryError(err);
            throw err;
        } finally {
            setCaseStudyLciaResultsCategoryLoading(false);
        }
    };
    return {
        excuteCaseStudyLciaResultsCategory,
        caseStudyLciaResultsCategoryData,
        caseStudyLciaResultsCategoryLoading,
        caseStudyLciaResultsCategoryError,
        setCaseStudyLciaResultsCategoryData,
        setCaseStudyLciaResultsCategoryLoading,
        setCaseStudyLciaResultsCategoryError
    };
}


/**
 * LCIAモデル一覧

 * @returns 
 */
export const useGetLciaModels = () => {
    const [lciaModelsData, setLciaModelsData] = useState<ImpactAssesmentMethods[] | null>(null);
    const [lciaModelsLoading, setLciaModelsLoading] = useState(false);
    const [lciaModelsError, setLciaModelsError] = useState<any>(null);
    const getLciaModels = async (database_pack_id: number, method_type: string) => {
        setLciaModelsLoading(true);
        try {
            const response = await getRequest<ImpactAssesmentMethods[]>(`/database_packs/${database_pack_id}/lcia_models/${method_type}`);
            setLciaModelsData(response);
            setLciaModelsError(null);
            return response;
        } catch (err: any) {
            setLciaModelsError(err.response.data.detail);
            throw err;
        } finally {
            setLciaModelsLoading(false);
        }
    };
    return {
        getLciaModels,
        lciaModelsData,
        lciaModelsLoading,
        lciaModelsError,
        setLciaModelsData,
        setLciaModelsLoading,
        setLciaModelsError
    };
}

/**
 * LCIAモデル一覧　被害評価

 * @returns 
 */
export const useGetLciaModelsForEndpoint = () => {
    const [lciaModelsForEndpointData, setLciaModelsForEndpointData] = useState<ImpactAssesmentMethods[] | null>(null);
    const [lciaModelsForEndpointLoading, setLciaModelsForEndpointLoading] = useState(false);
    const [lciaModelsForEndpointError, setLciaModelsForEndpointError] = useState<any>(null);
    const getLciaModelsForEndpoint = async (database_pack_id: number) => {
        setLciaModelsForEndpointLoading(true);
        try {
            const response = await getRequest<ImpactAssesmentMethods[]>(`/database_packs/${database_pack_id}/lcia_models/endpoint`);
            setLciaModelsForEndpointData(response);
            setLciaModelsForEndpointError(null);
            return response;
        } catch (err: any) {
            setLciaModelsForEndpointError(err.response.data.detail);
            throw err;
        } finally {
            setLciaModelsForEndpointLoading(false);
        }
    };
    return {
        getLciaModelsForEndpoint,
        lciaModelsForEndpointData,
        lciaModelsForEndpointLoading,
        lciaModelsForEndpointError,
        setLciaModelsForEndpointData,
        setLciaModelsForEndpointLoading,
        setLciaModelsForEndpointError
    };
}

/**
 * LCIAモデル一覧　特性化

 * @returns 
 */
export const useGetLciaModelsForMidpoint = () => {
    const [lciaModelsForMidpointData, setLciaModelsForMidpointData] = useState<ImpactAssesmentMethods[] | null>(null);
    const [lciaModelsForMidpointLoading, setLciaModelsForMidpointLoading] = useState(false);
    const [lciaModelsForMidpointError, setLciaModelsForMidpointError] = useState<any>(null);
    const getLciaModelsForMidpoint = async (database_pack_id: number) => {
        setLciaModelsForMidpointLoading(true);
        try {
            const response = await getRequest<ImpactAssesmentMethods[]>(`/database_packs/${database_pack_id}/lcia_models/midpoint`);
            setLciaModelsForMidpointData(response);
            setLciaModelsForMidpointError(null);
            return response;
        } catch (err: any) {
            setLciaModelsForMidpointError(err.response.data.detail);
            throw err;
        } finally {
            setLciaModelsForMidpointLoading(false);
        }
    };
    return {
        getLciaModelsForMidpoint,
        lciaModelsForMidpointData,
        lciaModelsForMidpointLoading,
        lciaModelsForMidpointError,
        setLciaModelsForMidpointData,
        setLciaModelsForMidpointLoading,
        setLciaModelsForMidpointError
    };
}

/**
 * LCIAモデル一覧　統合化

 * @returns 
 */
export const useGetLciaModelsForIntegration = () => {
    const [lciaModelsForIntegrationData, setLciaModelsForIntegrationData] = useState<ImpactAssesmentMethods[] | null>(null);
    const [lciaModelsForIntegrationLoading, setLciaModelsForIntegrationLoading] = useState(false);
    const [lciaModelsForIntegrationError, setLciaModelsForIntegrationError] = useState<any>(null);
    const getLciaModelsForIntegration = async (database_pack_id: number) => {
        setLciaModelsForIntegrationLoading(true);
        try {
            const response = await getRequest<ImpactAssesmentMethods[]>(`/database_packs/${database_pack_id}/lcia_models/integration`);
            setLciaModelsForIntegrationData(response);
            setLciaModelsForIntegrationError(null);
            return response;
        } catch (err: any) {
            setLciaModelsForIntegrationError(err.response.data.detail);
            throw err;
        } finally {
            setLciaModelsForIntegrationLoading(false);
        }
    };
    return {
        getLciaModelsForIntegration,
        lciaModelsForIntegrationData,
        lciaModelsForIntegrationLoading,
        lciaModelsForIntegrationError,
        setLciaModelsForIntegrationData,
        setLciaModelsForIntegrationLoading,
        setLciaModelsForIntegrationError
    };
}


/**
 * プロセスの特性化結果を作成して取得する
 * @returns 
 */
export const useLciaResults = () => {
    const [lciaResultsData, setLciaResultsData] = useState<LCIAResults[] | null>(null);
    const [lciaResultsLoading, setLciaResultsLoading] = useState(false);
    const [lciaResultsError, setLciaResultsError] = useState<any>(null);
    const excuteLciaResults = async (process_id: number, param: Array<number>) => {
        setLciaResultsLoading(true);
        try {
            const response = await postRequest<LCIAResults[]>(`/processes/${process_id}/lcia_results`, param);
            setLciaResultsData(response);
            setLciaResultsError(null);
            return response;
        } catch (err: any) {
            setLciaResultsError(err?.response?.data?.detail);
            throw err;
        } finally {
            setLciaResultsLoading(false);
        }
    };
    return {
        excuteLciaResults,
        lciaResultsData,
        lciaResultsLoading,
        lciaResultsError,
        setLciaResultsData,
        setLciaResultsLoading,
        setLciaResultsError
    };
}


/**
 * プロセスの寄与率分析結果を取得する
 * @returns 
 */
export const useProcessContributionAnalyze = () => {
    const [contributionAnalyzeData, setContributionAnalyzeData] = useState<ContributionAnalyze[] | null>(null);
    const [contributionAnalyzeLoading, setContributionAnalyzeLoading] = useState(false);
    const [contributionAnalyzeError, setContributionAnalyzeError] = useState<any>(null);
    const excuteProcessContributionAnalyze = async (process_id: number, param: object) => {
        setContributionAnalyzeLoading(true);
        try {
            const response = await postRequest<ContributionAnalyze[]>(`/processes/${process_id}/contribution_analyze`, param);
            setContributionAnalyzeData(response);
            setContributionAnalyzeError(null);
            return response;
        } catch (err: any) {
            setContributionAnalyzeError(err);
            throw err;
        } finally {
            setContributionAnalyzeLoading(false);
        }
    };
    return {
        excuteProcessContributionAnalyze,
        contributionAnalyzeData,
        contributionAnalyzeLoading,
        contributionAnalyzeError,
        setContributionAnalyzeData,
        setContributionAnalyzeLoading,
        setContributionAnalyzeError
    };
}


/**
 * ケーススタディの寄与率分析結果を取得する
 * @returns 
 */
export const useCaseStudyContributionAnalyze = () => {
    const [contributionAnalyzeData, setContributionAnalyzeData] = useState<CaseStudyContributionAnalyze | null>(null);
    const [contributionAnalyzeLoading, setContributionAnalyzeLoading] = useState(false);
    const [contributionAnalyzeError, setContributionAnalyzeError] = useState<any>(null);
    const excuteProcessContributionAnalyze = async (case_study_id: number, param: object) => {
        setContributionAnalyzeLoading(true);
        try {
            const response = await postRequest<CaseStudyContributionAnalyze>(`/case_studies/${case_study_id}/contribution_analyze`, param);
            setContributionAnalyzeData(response);
            setContributionAnalyzeError(null);
            return response;
        } catch (err: any) {
            setContributionAnalyzeError(err);
            throw err;
        } finally {
            setContributionAnalyzeLoading(false);
        }
    };
    return {
        excuteProcessContributionAnalyze,
        contributionAnalyzeData,
        contributionAnalyzeLoading,
        contributionAnalyzeError,
        setContributionAnalyzeData,
        setContributionAnalyzeLoading,
        setContributionAnalyzeError
    };
}


/**
 * この単位から換算できる値の一覧を取得する
 * @returns 
*/
export const useGetUnitConversionsByFromUnitId  = () => {
    const [getUnitConversionsByFromUnitIdData, setgetUnitConversionsByFromUnitIdData] = useState<any | null>(null);
    const [getUnitConversionsByFromUnitIdLoading, setgetUnitConversionsByFromUnitIdLoading] = useState(false);
    const [getUnitConversionsByFromUnitIdError, setgetUnitConversionsByFromUnitIdError] = useState<any>(null);
    const getUnitConversionsByFromUnitId  = async (database_pack_id: number, exchange_id: number, from_unit_id: number) => {
        setgetUnitConversionsByFromUnitIdLoading(true);
        try {
            const response = await getRequest<any>(`/database_packs/${database_pack_id}/exchange/${exchange_id}/unit_conversions?from_unit_id=${from_unit_id}`);
            setgetUnitConversionsByFromUnitIdData(response);
            setgetUnitConversionsByFromUnitIdError(null);
            return response;
        } catch (err: any) {
            setgetUnitConversionsByFromUnitIdError(err?.response?.data?.detail);
            throw err;
        } finally {
            setgetUnitConversionsByFromUnitIdLoading(false);
        }
    };
    return {
        getUnitConversionsByFromUnitId,
        getUnitConversionsByFromUnitIdData,
        getUnitConversionsByFromUnitIdLoading,
        getUnitConversionsByFromUnitIdError,
    };
}


/**
 * データベースパック一覧取得
 * @returns 
 */
export const useGetDatabasePacksList = () => {
    const [databasePacksListData, setDatabasePacksListData] = useState<DatabasePacksList | null>(null);
    const [databasePacksListLoading, setDatabasePacksListLoading] = useState(false);
    const [databasePacksListError, setDatabasePacksListError] = useState<any>(null);
    const { data, loading, error } = useGetRequest<DatabasePacksList>(`/database_packs`);
    useEffect(() => {
        setDatabasePacksListData(data);
        setDatabasePacksListLoading(loading);
        setDatabasePacksListError(error)
    }, [data, loading, error])
    return {
        databasePacksListData,
        databasePacksListLoading,
        databasePacksListError,
        setDatabasePacksListData,
        setDatabasePacksListLoading,
        setDatabasePacksListError
    };
}


/**
 * 外部IDのスキーマの一覧を取得する
 * @returns 
*/
export const useGetIdentifierSchemes = () => {
    const [identifierSchemesData, setIdentifierSchemesData] = useState<IdentifierSchemes[] | null>(null);
    const [identifierSchemesLoading, setIdentifierSchemesLoading] = useState(false);
    const [identifierSchemesError, setIdentifierSchemesError] = useState<any>(null);
    const getIdentifierSchemes = async () => {
        setIdentifierSchemesLoading(true);
        try {
            const response = await getRequest<IdentifierSchemes[]>(`/identifier_schemes`);
            setIdentifierSchemesData(response);
            setIdentifierSchemesError(null);
            return response;
        } catch (err: any) {
            setIdentifierSchemesError(err.response.data.detail);
            throw err;
        } finally {
            setIdentifierSchemesLoading(false);
        }
    };
    return {
        getIdentifierSchemes,
        identifierSchemesData,
        identifierSchemesLoading,
        identifierSchemesError,
        setIdentifierSchemesData,
        setIdentifierSchemesLoading,
        setIdentifierSchemesError
    };
}


/**
 * エクスポート一覧取得
 * @returns 
*/
export const useGetExportList = () => {
    const [exportListData, setExportListData] = useState<ImpactAssesmentMethods[] | null>(null);
    const [exportListLoading, setExportListLoading] = useState(false);
    const [exportListError, setExportListError] = useState<any>(null);
    const getExportList = async (database_pack_id: number) => {
        setExportListLoading(true);
        try {
            const response = await getRequest<ImpactAssesmentMethods[]>(`/database_packs/${database_pack_id}/bulk_io_formats`);
            setExportListData(response);
            setExportListError(null);
            return response;
        } catch (err: any) {
            setExportListError(err.response.data.detail);
            throw err;
        } finally {
            setExportListLoading(false);
        }
    };
    return {
        getExportList,
        exportListData,
        exportListLoading,
        exportListError,
        setExportListData,
        setExportListLoading,
        setExportListError
    };
}

/**
 * エクスポートファイル取得
 * @returns 
*/
export const useGetExportFile = () => {
    const [exportFileData, setExportFileData] = useState<any | null>(null);
    const [exportFileLoading, setExportFileLoading] = useState(false);
    const [exportFileError, setExportFileError] = useState<any>(null);
    const getExportFile = async (database_pack_id: number, data_format: string) => {
        setExportFileLoading(true);
        try {
            const response = await getRequest<any>(`/database_packs/${database_pack_id}/export?data_format=${data_format}`);
            setExportFileData(response);
            setExportFileError(null);
            return response;
        } catch (err: any) {
            setExportFileError(err.response.data.detail);
            throw err;
        } finally {
            setExportFileLoading(false);
        }
    };
    return {
        getExportFile,
        exportFileData,
        exportFileLoading,
        exportFileError,
        setExportFileData,
        setExportFileError,
        setExportFileLoading
    };
}

/**
 * お知らせの一覧を取得する
 * @returns 
*/
export const useGetNotices = () => {
    const [noticesData, setNoticesData] = useState<Notices[] | null>(null);
    const [noticesLoading, setNoticesLoading] = useState(false);
    const [noticesError, setNoticesError] = useState<any>(null);
    const getNotices = async () => {
        setNoticesError(true);
        try {
            const response = await getRequest<Notices[]>(`/notices`);
            setNoticesData(response);
            setNoticesError(null);
            return response;
        } catch (err: any) {
            setNoticesError(err?.response?.data?.detail);
            throw err;
        } finally {
            setNoticesLoading(false);
        }
    };
    return {
        getNotices,
        noticesData,
        noticesLoading,
        noticesError,
    };
}


/**
 * プロセスデータをTSV形式でインポートする
 * @returns 
*/
export const useProcessTsvImport= () => {
    const [processTsvImportData, setProcessTsvImportData] = useState<any | null>(null);
    const [processTsvImportLoading, setProcessTsvImportLoading] = useState(false);
    const [processTsvImportError, setProcessTsvImportError] = useState<any>(null);
    const processTsvImport = async (database_pack_id: number, file: File) => {
        setProcessTsvImportLoading(true);
        try {
            const response = await importFileRequest<any>(`/database_packs/${database_pack_id}/process_import/tsv`, file);
            setProcessTsvImportData(response);
            setProcessTsvImportError(null);
            return response;
        } catch (err: any) {
            setProcessTsvImportError(err?.response?.data?.detail);
            throw err;
        } finally {
            setProcessTsvImportLoading(false);
        }
    };
    return {
        processTsvImport,
        processTsvImportData,
        processTsvImportLoading,
        processTsvImportError,
    };
}

/**
 * ケーススタディデータをTSV形式でインポートする
 * @returns 
*/
export const useCaseStudyTsvImport= () => {
    const [caseStudyTsvImportData, setCaseStudyTsvImportData] = useState<any | null>(null);
    const [caseStudyTsvImportLoading, setCaseStudyTsvImportLoading] = useState(false);
    const [caseStudyTsvImportError, setCaseStudyTsvImportError] = useState<any>(null);
    const caseStudyTsvImport = async (database_pack_id: number, file: File) => {
        setCaseStudyTsvImportLoading(true);
        try {
            const response = await importFileRequest<any>(`/database_packs/${database_pack_id}/case_study_import/tsv`, file);
            setCaseStudyTsvImportData(response);
            setCaseStudyTsvImportError(null);
            return response;
        } catch (err: any) {
            setCaseStudyTsvImportError(err?.response?.data?.detail);
            throw err;
        } finally {
            setCaseStudyTsvImportLoading(false);
        }
    };
    return {
        caseStudyTsvImport,
        caseStudyTsvImportData,
        caseStudyTsvImportLoading,
        caseStudyTsvImportError,
    };
}

/**
 * プロセスデータをjson形式でエクスポートする
 * @returns 
*/
export const useProcessJsonExport = () => {
    const [processJsonExportData, setProcessJsonExportData] = useState<any | null>(null);
    const [processJsonExportLoading, setProcessJsonExportLoading] = useState(false);
    const [processJsonExportError, setProcessJsonExportError] = useState<any>(null);
    const processJsonExport = async (database_pack_id: number, ids:number[], is_exclude_information: boolean = false) => {
        setProcessJsonExportLoading(true);
        try {
            const response = await postRequest<any>(`/database_packs/${database_pack_id}/process_export/json?is_exclude_information=${is_exclude_information}`, ids);
            setProcessJsonExportData(response);
            setProcessJsonExportError(null);
            return response;
        } catch (err: any) {
            setProcessJsonExportError(err?.response?.data?.detail);
            throw err;
        } finally {
            setProcessJsonExportLoading(false);
        }
    };
    return {
        processJsonExport,
        processJsonExportData,
        processJsonExportLoading,
        processJsonExportError,
    };
}



/**
 * プロセスの計算日時を取得する
 * @returns 
*/
export const useGetProcessCalculatedDate = () => {
    const [calculatedDateData, setCalculatedDateData] = useState<CalculatedDateOut | null>(null);
    const [calculatedDateLoading, setCalculatedDateLoading] = useState(false);
    const [calculatedDateError, setCalculatedDateError] = useState<any>(null);
    const getCalculatedDate = async (process_id: number) => {
        setCalculatedDateLoading(true);
        try {
            const response = await getRequest<CalculatedDateOut | null>(`/processes/${process_id}/calculated_date`);
            setCalculatedDateData(response);
            setCalculatedDateError(null);
            return response;
        } catch (err: any) {
            setCalculatedDateError(err?.response?.data?.detail);
            throw err;
        } finally {
            setCalculatedDateLoading(false);
        }
    };
    return {
        getCalculatedDate,
        calculatedDateData,
        calculatedDateLoading,
        calculatedDateError,
    };
}


/**
 * ケーススタディの計算日時を取得する
 * @returns 
*/
export const useGetCaseStudyCalculatedDate = () => {
    const [calculatedDateData, setCalculatedDateData] = useState<CalculatedDateOut | null>(null);
    const [calculatedDateLoading, setCalculatedDateLoading] = useState(false);
    const [calculatedDateError, setCalculatedDateError] = useState<any>(null);
    const getCalculatedDate = async (case_study_id: number) => {
        setCalculatedDateLoading(true);
        try {
            const response = await getRequest<CalculatedDateOut | null>(`/case_studies/${case_study_id}/calculated_date`);
            setCalculatedDateData(response);
            setCalculatedDateError(null);
            return response;
        } catch (err: any) {
            setCalculatedDateError(err?.response?.data?.detail);
            throw err;
        } finally {
            setCalculatedDateLoading(false);
        }
    };
    return {
        getCalculatedDate,
        calculatedDateData,
        calculatedDateLoading,
        calculatedDateError,
    };
}


/**
 * EPD用のレポートを作成する
 * @returns 
*/
export const useGetReportForEpd = () => {
    const [reportForEpdData, setReportForEpdData] = useState<any | null>(null);
    const [reportForEpdLoading, setReportForEpdLoading] = useState(false);
    const [reportForEpdError, setReportForEpdError] = useState<any>(null);

    const getReportForEpd = async (case_study_id: number, epd_type: number) => {
        let type = "standard";
        if(epd_type === 1) {
            type = "iso21930_2017";
        }

        setReportForEpdLoading(true);
        try {
            // const response = await getAttachedFilesRequest<any | null>(`/case_studies/${case_study_id}/reports/epd?epd_type=${type}`);
            const { data: blob, headers }  = await getAttachedFilesRequestWithHeader<any | null>(`/case_studies/${case_study_id}/reports/epd?epd_type=${type}`);
            setReportForEpdData(blob);
            setReportForEpdError(null);
            return  { 
                data: blob, 
                headers 
            } ;
        } catch (err: any) {
            setReportForEpdError(err?.response?.data?.detail);
            throw err;
        } finally {
            setReportForEpdLoading(false);
        }
    };
    return {
        getReportForEpd,
        setReportForEpdData,
        setReportForEpdLoading,
        setReportForEpdError,
        reportForEpdData,
        reportForEpdLoading,
        reportForEpdError,
    };
}

/**
 * ユーザがアクセス可能なデータベースパックのリストそれに関する情報一覧の取得
 * @returns 
*/
export const useGetAccessibleDatabasePacksList = () => {
    const [accessibleDatabasePacksListData, setAccessibleDatabasePacksListData] = useState<AccessibleDatabasePacksList[] | null>(null);
    const [accessibleDatabasePacksListLoading, setAccessibleDatabasePacksListLoading] = useState(false);
    const [accessibleDatabasePacksListError, setAccessibleDatabasePacksListError] = useState<any>(null);
    const getAccessibleDatabasePacksList = async (user_id: number) => {
        setAccessibleDatabasePacksListLoading(true);
        try {
            const response = await getRequest<AccessibleDatabasePacksList[]>(`/users/${user_id}/available_database_packs`);
            setAccessibleDatabasePacksListData(response);
            setAccessibleDatabasePacksListError(null);
            return response;
        } catch (err: any) {
            setAccessibleDatabasePacksListError(err.response.data.detail);
            throw err;
        } finally {
            setAccessibleDatabasePacksListLoading(false);
        }
    };
    return {
        getAccessibleDatabasePacksList,
        accessibleDatabasePacksListData,
        accessibleDatabasePacksListLoading,
        accessibleDatabasePacksListError,
        setAccessibleDatabasePacksListData,
        setAccessibleDatabasePacksListLoading,
        setAccessibleDatabasePacksListError
    };
}