import Modal from '@common/modal/Modal';
import { LinkMenu } from '@styles/Common';
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ModalButton from '@common/button/ModalButton';
import { GetUserDetail } from '@typeList/types';
import { getCurrentDefaultDatabasePackId } from '@services/Cookies';
import { useCaseStudyTsvImport } from '@hooks/useBackendApi';
import ErrorMsg from '@common/error/ErrorMsg';
import LoadingDialog from '@common/modal/LoadingDialog';
import { createColumnHelper } from '@tanstack/react-table';
import DataTable from '@common/table/DataTable';

interface CaseStudyImportModalProps {
    userData: GetUserDetail | null;
}

/**
 * 031　ケーススタディのインポート
 * @returns 
 */
const CaseStudyImportModal: React.FC<CaseStudyImportModalProps> = ({ userData }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const [inpuCaseStudyInportRadio, setInpuCaseStudyInportRadio] = useState<string>("1");
    const [inputFiles, setInputFiles] = useState<any>("");
    const [inputFileName, setInputFileName] = useState<string>("");
    const inputFileRef = useRef<HTMLInputElement>(null);

    // データベースパック
    const [inputDatabasePackId, setInputDatabasePackId] = useState<number | undefined>(userData?.default_database_pack_id);
    const [databasePacks, setDatabasePacks] = useState<any[]>([]);
    useEffect(() => {
        if (userData?.licenses) {
            const filteredDatabasePacks = userData?.licenses
                .filter(license => !license.is_inactive) // is_inactive = false のみを対象
                .map(license => ({
                    id: license.role.database_pack.id,
                    name: license.role.database_pack.name,
                }));

            // id の重複を Set で除去し、再度配列に戻す
            const uniqueDatabasePacks = Array.from(new Map(
                filteredDatabasePacks.map(pack => [pack.id, pack]) // id をキーにした Map を生成
            ).values());

            setDatabasePacks(uniqueDatabasePacks);
            setInputDatabasePackId(getCurrentDefaultDatabasePackId() ?? uniqueDatabasePacks[0].id);
        }
    }, [userData]);

    useEffect(() => {
        if (getCurrentDefaultDatabasePackId()) {
            setInputDatabasePackId(getCurrentDefaultDatabasePackId())
        }
    }, [getCurrentDefaultDatabasePackId()])

    // フォーマット選択
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        switch (key) {
            case "case_study_format":
                if (event.target.value === "1") {
                    setInpuCaseStudyInportRadio("1");
                }
                if (event.target.value === "2") {
                    setInpuCaseStudyInportRadio("2");
                }
                if (event.target.value === "3") {
                    setInpuCaseStudyInportRadio("3");
                }
                break;
        }
    };

    // ファイル選択
    const handleFileSelect = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '*/*';
        input.onchange = (event) => {
            const selectedFile = (event.target as HTMLInputElement).files?.[0];
            if (selectedFile) {
                setInputFiles(selectedFile);
                setInputFileName(selectedFile.name);
            }
        };
        input.click();
    };


    // データベースパック
    const handleInputChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case 'database_pack_id':
                setInputDatabasePackId(Number(event.target.value));
                break;
        }
    };

    // TSV形式インポートAPI
    const { caseStudyTsvImport, caseStudyTsvImportData, caseStudyTsvImportLoading, caseStudyTsvImportError } = useCaseStudyTsvImport()


    // ローディングダイアログ
    const [isLoadingDaialogOpen, setIsLoadingDaialogOpen] = useState(false);
    const openLoadingDaialog = () => setIsLoadingDaialogOpen(true);
    const closeLoadingDaialog = () => setIsLoadingDaialogOpen(false);
    const [daialogMessage, setDaialogMessage] = useState<string | JSX.Element>("");
    const daialogLoadingMessage = t('インポート中です');

    const handleClickOK = async () => {
        if (!inputFiles || !inputDatabasePackId) {
            return
        }
        try {
            setDaialogMessage('')
            openLoadingDaialog();
            setLogData([]);

            if (inpuCaseStudyInportRadio === "1") {
                // TSV形式
                const response = await caseStudyTsvImport(inputDatabasePackId, inputFiles)
                setLogData(response.logs)
            }
            if (inpuCaseStudyInportRadio === "2") {
                // クラウド版MiLCA形式
            }
            if (inpuCaseStudyInportRadio === "3") {
                // EcoSpold2形式
            }

            setDaialogMessage(t('インポートが完了しました'))
        } catch (error) {
            console.log(error);
            setDaialogMessage(t('インポート処理に失敗しました'))
        } finally {
            setInputFiles(null)
            setInputFileName("")
        }
    };

    // ログテーブル
    const [logData, setLogData] = useState<any[]>();

    const columnHelper = createColumnHelper<any[]>()
    const columns = [
        columnHelper.accessor('timestamp', {
            header: () => t('処理時刻'),
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor('message', {
            header: () => t('処理ログ'),
            cell: info => info.renderValue(),
        }),
    ]

    return (
        <>
            <LoadingDialog
                open={isLoadingDaialogOpen}
                onClose={closeLoadingDaialog}
                loading={caseStudyTsvImportLoading}
                loading_message={daialogLoadingMessage}
                result_message={daialogMessage}
            />

            <LinkMenu onClick={openModal}>
                {t('インポート')}
            </LinkMenu>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Inner>
                    <Title>{t('ケーススタディのインポート')}</Title>
                    <SectionBody>
                        <SectionContent>
                            <DatabasePack>
                                <DatabasePackLabel>
                                    <DatabasePackText>{t('データベースパック')}</DatabasePackText>
                                </DatabasePackLabel>
                            </DatabasePack>
                        </SectionContent>

                        <SectionContent>
                            <SelectPrimary>
                                <SelectInner
                                    name="database-pack"
                                    id="database-pack-select"
                                    onChange={(event) => handleInputChangeSelect(event, 'database_pack_id')}
                                    value={inputDatabasePackId ?? ''}
                                >
                                    {databasePacks.map((database_pack) => (
                                        <option key={database_pack.id} value={database_pack.id}>
                                            {database_pack.name}
                                        </option>
                                    ))}
                                </SelectInner>
                            </SelectPrimary>
                        </SectionContent>

                        <SectionContent>
                            <Radio>
                                <RadioLabel>
                                    <RadioInput
                                        type="radio"
                                        name="radio2"
                                        id="1"
                                        value="1"
                                        checked={inpuCaseStudyInportRadio === "1"}
                                        onChange={(event) => handleInputChange(event, "case_study_format")}
                                    />
                                    <RadioText>{t('TSV形式')}</RadioText>
                                </RadioLabel>
                                {userData?.is_admin &&
                                    <RadioLabel>
                                        <RadioInput
                                            type="radio"
                                            name="radio1"
                                            id="2"
                                            value="2"
                                            checked={inpuCaseStudyInportRadio === "2"}
                                            onChange={(event) => handleInputChange(event, "case_study_format")}
                                        />
                                        <RadioText>[SystemTest]{t('クラウド版MiLCA形式')}</RadioText>
                                    </RadioLabel>
                                }
                                {userData?.is_admin &&
                                    <RadioLabel>
                                        <RadioInput
                                            type="radio"
                                            name="radio3"
                                            id="3"
                                            value="3"
                                            checked={inpuCaseStudyInportRadio === "3"}
                                            onChange={(event) => handleInputChange(event, "case_study_format")}
                                        />
                                        <RadioText>[SystemTest]{t('EcoSpold2形式')}</RadioText>
                                    </RadioLabel>
                                }
                            </Radio>
                        </SectionContent>
                        <SectionContent>
                            <InputPrimary
                                type="text"
                                value={inputFileName ?? ''}
                                disabled
                            />
                            <StyledButton onClick={handleFileSelect} >
                                {t('ファイルを選択')}
                            </StyledButton>
                            <input
                                ref={inputFileRef}
                                style={{ display: 'none' }}
                                type="file"
                            />
                        </SectionContent>
                    </SectionBody>
                    {caseStudyTsvImportError && <ErrorMsg>{caseStudyTsvImportError}</ErrorMsg>}

                    <ButtonCreateSectionTabel>
                        <ModalButton onClick={handleClickOK} text="OK" />
                    </ButtonCreateSectionTabel>

                    {/* インポートログ  */}
                    {logData && logData.length !== 0 &&
                        <StyledLogTableInner>
                            <DataTable count={0} columns={columns} data={logData} paging_flag={false} />
                        </StyledLogTableInner>
                    }
                </Inner>
            </Modal>
        </>
    );
}

export default CaseStudyImportModal;

const Title = styled.h2`
    font-size: 18px;
    padding: 0 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-line-primary);
    font-size: 16px;
    font-weight: 500;    
    line-height: 1.25;    
`
const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const SectionBody = styled.div`
`

const SectionContent = styled.dd`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 16px;    
`

const InputPrimary = styled.input`
    width: min(100%, 340px);

    padding: 12px;
    color: var(--color-txt-primary);
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const Radio = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px; 
`

const RadioLabel = styled.label`
    order: 1;
    
    isplay: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    width: fit-content;
    cursor: pointer;
    position: relative;    
`
const RadioText = styled.span`
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0 20px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500; 
`

const StyledButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 14px 30px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;      

  &:hover {
    background-color: var(--color-gray--01);
    color: inherit;

  }
`

const RadioInput = styled.input`
    accent-color: var(--color-site-secondary);
`

const ButtonCreateSectionTabel = styled.div`
    margin-top: 40px;
    text-align: right;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    
    margin-bottom: 20px;
`


const DatabasePack = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 54px;
`

const DatabasePackLabel = styled.label`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    cursor: pointer;
  
`

const DatabasePackText = styled.span`
    font-size: 16px;
    font-weight: 500;
`

const SelectPrimary = styled.label`
    width: min(100%, 340px);
    position: relative;
    display: block;
    cursor: pointer;
`

const SelectInner = styled.select`
    position: relative;
    width: 100%;
    padding: 12px 32px 12px 12px;
    cursor: pointer !important;
    border: 1px solid var(--color-line-primary);
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
`

const StyledLogTableInner = styled.div`
    table td {
        max-width: unset;
    }
`;