import React, { useEffect, useState } from 'react';

import { deleteRequest } from '@services/api/api';
import { useGetUserDetail } from '@hooks/useBackendApi';
import { getCurrentUserInfo, removeCurrentDefaultDatabasePackId, removeCurrentUserInfo, removei18next, setCurrentDefaultDatabasePackId } from '@services/Cookies';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// マスタ
import UnitGroupModal from '@specific/master/unit_groups/UnitGroupModal';
import RegionsModal from '@specific/master/regions/RegionsModal';
import ElementaryFlowsModal from '@specific/master/elementary_flows/ElementaryFlows';
import UnitConversionsModal from '@specific/master/unit_conversions/UnitConversions';
import iconMaster from '@images/sidebar/icon_master.svg'

// プロセス
import ProcessCreateModal from '@specific/process/process_create/ProcessCreateModal';
import ProcessSearchModal from '@specific/process/process_search/ProcessSearchModal';
import ProductSearchModal from '@specific/process/product_search/ProductSearchModal';
import { LinkMenu } from '@styles/Common';
import iconProcess from '@images/sidebar/icon_process.svg'

// ケーススタディ
import iconCaseStudy from '@images/sidebar/icon_casestudy.svg'

// LEC管理
import iconLec from '@images/sidebar/icon_lec.svg'

// ログアウト
import iconLogout from '@images/sidebar/icon_logout.svg'

// マニュアル
import iconManual from '@images/sidebar/icon_manual.svg'
import CaseStudyCreateModal from '@specific/case_study/case_study_create/CaseStudyCreateModal';
import ProcessImportModal from '@specific/process/process_import/ProcessImportModal';
import ProcessExportModal from '@specific/process/process_export/ProcessExportModal';
import CaseStudyImportModal from '@specific/case_study/case_study_import/CaseStudyImportModal';
import CaseStudyExportModal from '@specific/case_study/case_study_export/CaseStudyExportModal';
import CaseStudySearchModal from '@specific/case_study/case_study_search/CaseStudySearchModal';
import ProcessHistory from '@specific/process/history/ProcessHistory';
import Version from './Version';
import { use } from 'i18next';
import LicensesAdd from '@common/LicensesAdd';

const Sidebar: React.FC = () => {
	const { t } = useTranslation();
	// 画面遷移ナビゲート
	const navigate = useNavigate();

	const id = getCurrentUserInfo()?.id;

	const {
		userData,
	} = useGetUserDetail(id);


	useEffect(() => {
		if (userData) {
			const defaultDatabasePackId = userData.default_database_pack_id;

			// licenses内に一致するdatabase_pack.idが存在するか確認
			const isDefaultDatabasePackIdPresent = userData.licenses.some(
				(license) => license.role?.database_pack?.id === defaultDatabasePackId
			);

			if (isDefaultDatabasePackIdPresent) {
				setCurrentDefaultDatabasePackId(defaultDatabasePackId);
			}
		}
	}, [userData]);

	// ログアウト
	const onClickLogout = async () => {
		await deleteRequest('/logout').then((data) => {
			removeCurrentUserInfo();
			removei18next();
			removeCurrentDefaultDatabasePackId();
		}).then(() => {
			navigate("/login")
		}).catch((error) => {
			console.log("Logout Error: ", error);
		});
	};

	// アクティブなライセンスがあるかチェック
	const [isActiveLicences, setIsActiveLicences] = useState<boolean>(false);
	useEffect(() => {
		if (userData) {
			const hasActiveLicense = userData.licenses.some(license => !license.is_inactive);
			setIsActiveLicences(hasActiveLicense);
		}
	}, [userData]);

	return (
		<>

			<Aside>
				<Nav>
					<Ul>
						{userData && userData.licenses.length > 0 && isActiveLicences &&
							<List>
								<ProcessMenuTitle>{t('プロセス')}</ProcessMenuTitle>
								<ListMenuSub>
									<ListMenuSubItem>
										{/*新規作成*/}
										<ProcessCreateModal userData={userData} />
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/* プロセス情報 */}
										<ProcessSearchModal userData={userData} />
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/* 製品管理 */}
										<ProductSearchModal selectted_database_pack_id={null} userData={userData} />
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/* インポート */}
										<ProcessImportModal userData={userData} />
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/* エクスポート */}
										{userData.is_admin &&
											<ProcessExportModal userData={userData} />
										}
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/* 閲覧履歴 */}
										<ProcessHistory />
									</ListMenuSubItem>
								</ListMenuSub>
							</List>
						}
						{userData && userData.licenses.length > 0 && isActiveLicences &&
							<List>
								<CaseStudyMenuTitle>{t('ケーススタディ')}</CaseStudyMenuTitle>
								<ListMenuSub>
									<ListMenuSubItem>
										{/* 新規作成 */}
										<CaseStudyCreateModal userData={userData} />
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/* <LinkMenu href="#" >{t('ケーススタディの検索')}</LinkMenu> */}
										<CaseStudySearchModal userData={userData} />
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/* <LinkMenu href="#" >{t('インポート')}</LinkMenu> */}
										<CaseStudyImportModal userData={userData} />
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/* <LinkMenu href="#" >{t('エクスポート')}</LinkMenu> */}
										{userData.is_admin &&
											<CaseStudyExportModal userData={userData} />
										}
									</ListMenuSubItem>
								</ListMenuSub>
							</List>
						}
						{userData && userData.licenses.length > 0 && isActiveLicences &&
							<List>
								<MasterMenuTitle>{t('マスター一覧')}</MasterMenuTitle>
								<ListMenuSub>
									<ListMenuSubItem>
										{/* 基本フローマスタ */}
										<ElementaryFlowsModal userData={userData} />
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/* 単位マスタ */}
										<UnitGroupModal userData={userData} />
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/*単位換算マスタ*/}
										<UnitConversionsModal userData={userData} />
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/* 地域マスタ */}
										<RegionsModal />
									</ListMenuSubItem>
									<ListMenuSubItem>
										{/* <LinkMenu href="#" >{t('マスタインポート')}</LinkMenu> */}
									</ListMenuSubItem>
								</ListMenuSub>
							</List>
						}

						{userData && userData.is_admin &&
							<List>
								<LecMenuTitle>{t('LEC管理')}</LecMenuTitle>
								<ListMenuSub>
									<ListMenuSubItem>
										{userData.is_admin &&
											<LinkMenu href="#" >[SystemTest]{t('プロセスエクスポート')}</LinkMenu>
										}
									</ListMenuSubItem>
									<ListMenuSubItem>
										<A href="/admin/master_export_model">{t('マスタエクスポート')}</A>
										{/* <ReactRouterLink to={"/admin/master_export_model"}>{t('マスタエクスポート')}</ReactRouterLink> */}
									</ListMenuSubItem>
									<ListMenuSubItem>
										<A href="/admin/user_management">{t('ユーザー管理')}</A>
										{/* <ReactRouterLink to={"/admin/user_management"}>{t('ユーザー管理')}</ReactRouterLink> */}
									</ListMenuSubItem>
									<ListMenuSubItem>
										<A href="/admin/shared_file_management">{t('共有ファイル管理')}</A>
										{/* <ReactRouterLink to={"/admin/shared_file_management"}>{t('共有ファイル管理')}</ReactRouterLink> */}
									</ListMenuSubItem>
								</ListMenuSub>
							</List>
						}

						<List>
							<LogoutMenuTitle onClick={onClickLogout}>{t('ログアウト')}</LogoutMenuTitle>
						</List>
					</Ul>
				</Nav>
				<VersionContent>
					<Version />
				</VersionContent>
				<Manual>
					<ManualLink href='#'>
						<img src={iconManual} alt="" />
					</ManualLink>
				</Manual>
			</Aside>
		</>

	);
};

export default Sidebar;

const VersionContent = styled.div`
	position: absolute;
	bottom: 50px; /* Manualの高さに合わせて調整 */
	width: 100px;
	word-break: break-all; /* これを追加してテキストを折り返し */
	text-align: center; /* テキストを中央に揃える */
	white-space: normal; /* テキストの折り返しを有効にする */
`

const Aside = styled.aside`
    position: fixed;
    z-index: 300;
    top: 90px;
    left: 0;
    width: 100px;
    height: calc(100vh - 90px);
    background-color: var(--color-site-secondary);
    font-size: 12px;
`

const Nav = styled.nav`
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
	display: block;
`

const Ul = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
`

const List = styled.li`
	position: relative;
	width: 100%;
	height: 80px;
	&:hover{
		background-color: var(--color-site-primary);
	}
`

const ListMenuTitle = styled.span`
	display: flex;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	color: #fff;
	font-size: 11px;
	font-weight: 700;
	gap: 4px;
	cursor: pointer;

	&:hover + ul {
		display: block;
	}
`

const ProcessMenuTitle = styled(ListMenuTitle)`
	&::before {
		width: 30px;
		height: 30px;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 30px;
		background-image: url(${iconProcess});
	}
`

const CaseStudyMenuTitle = styled(ListMenuTitle)`
	&::before {
		width: 30px;
		height: 30px;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 30px;
		background-image: url(${iconCaseStudy});
	}
`

const MasterMenuTitle = styled(ListMenuTitle)`
	&::before {
		width: 30px;
		height: 30px;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 30px;
		background-image: url(${iconMaster});
	}
`

const LecMenuTitle = styled(ListMenuTitle)`
	&::before {
		width: 30px;
		height: 30px;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 30px;
		background-image: url(${iconLec});
	}
`

const LogoutMenuTitle = styled.a`
	display: flex;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	color: #fff;
	font-size: 11px;
	font-weight: 700;
	gap: 4px;

	&::before {
		width: 30px;
		height: 30px;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 30px;
		background-image: url(${iconLogout});
	}
`

const ListMenuSub = styled.ul`
	position: absolute;
	top: 0;
	left: 100px;
	display: none;
	width: max-content;
	min-width: 150px;
	padding: 12px 0;
	background-color: var(--color-site-primary);
	list-style: none;
	&:hover {
		display: block;
	}
`

const ListMenuSubItem = styled.li`
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
`

const Manual = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 40px;
	background-color: var(--color-site-tertiary);
`

const ManualLink = styled.a`
	display: flex;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	height: 100%;
`

export const ReactRouterLink = styled(Link)`
	cursor: pointer;
	text-decoration: none;
	color: inherit;
	display: block;
	width: fit-content;
	padding: 12px;
	color: #fff;
	font-weight: 700;
	line-height: 1;
	&:hover {
		text-decoration: underline;
	}
`

const A = styled.a`
	cursor: pointer;
	text-decoration: none;
	color: inherit;
	display: block;
	width: fit-content;
	padding: 12px;
	color: #fff;
	font-weight: 700;
	line-height: 1;
	&:hover {
		text-decoration: underline;
	}
`