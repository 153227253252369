// src/utils/colorUtils.ts
// ランダムな色を生成するためのユーティリティ
export const getRandomColor = (): string => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  

// src/utils/colorUtils.ts
// 指定されたカラーコードの配列からランダムな色を取得するユーティリティ
const colorPalette = [
  "#bfbdb0", 
  "#b1b134", 
  "#bbd634", 
  "#dbe3b6", 
  "#b2c8bd", 
  "#165b65", 
  "#697d99", 
  "#96b8db", 
  "#00a4e8", 
  "#a4dbdb", 
  "#fdd666", 
  "#dc9018", 
  "#e31a22", 
  "#df8f2d",
  "#b24f3f",
  "#b51f29",
  "#f58268",
  "#f4979c"
];

export const getRandomColorFromColorPalette = (): string => {
  const randomIndex = Math.floor(Math.random() * colorPalette.length);
  return colorPalette[randomIndex];
};
