import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
} from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import iconArrowDown from '@images/table/icon_arrow_down.svg';
import iconArrowUp from '@images/table/icon_arrow_up.svg';
import iconSelect from '@images/table/icon_arrow_back.svg';
import iconArrowLeft from '@images/table/icon_arrow_left.svg';
import iconArrowRight from '@images/table/icon_arrow_right.svg';

interface TableProps {
    count: number;
    columns: ColumnDef<any, any>[];
    data: any[];
    paging_flag: boolean;
    handleClick: (id: number, name: string) => void;
}

const initialPageIndex = 0;
const initialPageSize = 10;

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const DraggableHeader: React.FC<{
    header: any;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({ header, index, moveColumn }) => {
    const ref = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover: (item: DragItem, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset!.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Th
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                // cursor: header.column.getCanSort() ? 'move' : 'default',
            }}
            onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
        >
            <ThInner>
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? <IconArrowDown /> : <IconArrowUp />) : ''}
            </ThInner>
        </Th>
    );
};

const Button: React.FC<{ rowId: string; onClick: () => void; downloadLink: string }> = ({ rowId, onClick, downloadLink }) => (
    <SelectButton type='button' onClick={onClick}>
        <ButtonInner>
            <SelectButtonIcon></SelectButtonIcon>
        </ButtonInner>
    </SelectButton>
);

const ButtonDataTable: React.FC<TableProps> = ({ count, columns: initialColumns, data, paging_flag, handleClick }) => {
    const { t } = useTranslation();
    const [columns, setColumns] = useState<ColumnDef<any, any>[]>([
        {
            id: 'handleClick',
            header: () => t('選択'),
            cell: ({ row }) => (
                <Button
                    rowId={row.original.id}
                    onClick={() => handleClick(row.original.unitId, row.original.unitName)}
                    downloadLink={row.original.storage_path} // ダウンロードリンクの設定
                />
            ),
            enableSorting: false, // ソート不可にする
        },
        ...initialColumns,
    ]);

    const [sorting, setSorting] = useState<SortingState>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowId, setCurrentRowId] = useState<string | null>(null);

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const newColumns = [...columns];
            const [movedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(hoverIndex, 0, movedColumn);
            setColumns(newColumns);
        },
        [columns]
    );

    const table = useReactTable({
        data: data ?? [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        initialState: {
            pagination: {
                pageIndex: initialPageIndex,
                pageSize: initialPageSize,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const renderPageNumbers = () => {
        const totalPages = table.getPageCount();
        const currentPage = table.getState().pagination.pageIndex;

        const range = (start: number, end: number) => {
            return Array.from({ length: end - start + 1 }, (_, i) => i + start);
        };

        // 1〜9ページ目までを表示
        if (totalPages <= 9) {
            return range(0, totalPages - 1);
        }

        // 最初のページと最後のページは常に表示
        const pages = [0];

        // 9ページ目以降の場合、省略しつつ範囲を動的に設定
        if (currentPage >= 9) {
            pages.push(-1); // 省略記号として-1を挿入
            pages.push(...range(currentPage - 1, Math.min(currentPage + 1, totalPages - 2)));
        } else {
            pages.push(...range(1, Math.min(8, totalPages - 2)));
        }

        // 最後のページは常に表示
        pages.push(totalPages - 1);

        return pages.reduce<number[]>((acc, page) => {
            if (acc.length > 0 && page - acc[acc.length - 1] > 1) {
                acc.push(-1); // 省略記号
            }
            acc.push(page);
            return acc;
        }, []);
    };
    const pageSize = table.getState().pagination.pageSize
    return (
        <>
            {paging_flag && (
                <PageNationWrapper>
                    <DisplayCountView>
                        {t('displayRange', { count, pageSize})}
                    </DisplayCountView>
                    <PageOptionArrowButton
                        type='button'
                        onClick={() => {
                            table.previousPage();
                        }}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <ButtonInner>
                            <IconArrowLeft />
                        </ButtonInner>
                    </PageOptionArrowButton>
                    {renderPageNumbers().map((page, index) => {
                        if (page === -1) {
                            return (
                                <span key={index} style={{ margin: '0 8px' }}>
                                    ...
                                </span>
                            );
                        }
                        return (
                            <PageOptionButton
                                type='button'
                                key={page}
                                onClick={() => table.setPageIndex(page)}
                                disabled={table.getState().pagination.pageIndex === page}
                            >
                                <PageOptionInner disabled={table.getState().pagination.pageIndex === page}>
                                    {page + 1}
                                </PageOptionInner>
                            </PageOptionButton>
                        );
                    })}
                    <PageOptionArrowButton
                        type='button'
                        onClick={() => {
                            table.nextPage();
                        }}
                        disabled={!table.getCanNextPage()}
                    >
                        <ButtonInner>
                            <IconArrowRight />
                        </ButtonInner>
                    </PageOptionArrowButton>
                    <DisplayCount>{t('表示件数')}</DisplayCount>
                    <Label>
                        <PageNationViewSelect
                            style={{ margin: '5px' }}
                            value={table.getState().pagination.pageSize}
                            onChange={(e) => {
                                table.setPageSize(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </PageNationViewSelect>
                    </Label>
                </PageNationWrapper>
            )}
            <SectionTableWrap>
                <Table>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header, index) => (
                                    <DraggableHeader key={header.id} header={header} index={index} moveColumn={moveColumn} />
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => (
                            <Tr key={row.id} style={{ background: row.index % 2 === 0 ? '#fafafa' : '#fff' }}>
                                {row.getVisibleCells().map((cell) => (
                                    <Td key={cell.id}>
                                        <CellContent>{flexRender(cell.column.columnDef.cell, cell.getContext())}</CellContent>
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </SectionTableWrap>
        </>
    );
};

export default ButtonDataTable;

// スタイル付きコンポーネント
// const TableWrapper = styled.div`
//     padding: 10px;
//     background-color: white;
// `;

const SectionTableWrap = styled.div`
    overflow-x: auto;    
`;


const CellContent = styled.div`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
`;

const ButtonInner = styled.div``;

const SelectButton = styled.button`
cursor: pointer;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const SelectButtonIcon = styled.span`
mask: url(${iconSelect}) no-repeat center center / contain;
-webkit-mask: url(${iconSelect}) no-repeat center center / contain;
display: block;
width: 24px;
height: 24px;
background: var(--color-txt-primary);
`;

const Table = styled.table`
min-width: 100%;
border-collapse: collapse;
margin-bottom: 8px;
background-color: #fff;
border-spacing: 0;
` 

const Thead = styled.thead`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
` 

const Tr = styled.tr`
border-top: 1px solid var(--color-line-primary);
border-bottom: 1px solid var(--color-line-primary);
` 

const Th = styled.th`
font-size: 13px;
font-weight: 700;
line-height: 1.25;
color: var(--color-site-primary);
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;

cursor: grab;

&:active {
cursor: grabbing;
}

` 

const ThInner = styled.div`
width: fit-content;
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 4px;
white-space: nowrap;
`;

const Tbody = styled.tbody`
border-right: 1px solid var(--color-line-primary);
border-left: 1px solid var(--color-line-primary);
`; 

const Td = styled.td`
font-size: 14px;
font-weight: 500;
line-height: 1.25;
padding: 12px;
align-content: center;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
min-height: 40px;
`;

const IconArrowDown = styled.span`
mask: url(${iconArrowDown}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowDown}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const IconArrowUp = styled.span`
mask: url(${iconArrowUp}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowUp}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

// ページネーション
const IconArrowLeft = styled.span`
mask: url(${iconArrowLeft}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowLeft}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const IconArrowRight = styled.span`
mask: url(${iconArrowRight}) no-repeat center center / contain;
-webkit-mask: url(${iconArrowRight}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 20px;
height: 20px;
background: var(--color-txt-primary);
`;

const PageOptionArrowButton = styled.button`
height: 18px;
width: 24px;
cursor: pointer;
text-decoration: none;
color: inherit;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const PageOptionButton = styled.button`
cursor: pointer;
width: 34px;
height: 24px;
appearance: none;
background: transparent;
border: none;
border-radius: 0;
font: inherit;
outline: none;
`;

const PageOptionInner = styled.span<{ disabled: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  border: 1px solid var(--color-line-primary);
  border-radius: 50%;
  text-align: center;
  color: ${({ disabled }) => (disabled ? '#fff' : 'inherit')};
  background-color: ${({ disabled }) => (disabled ? 'var(--color-site-secondary)' : 'transparent')};
  border-color: ${({ disabled }) => (disabled ? 'var(--color-site-secondary)' : 'var(--color-line-primary)')};
`;

const Label = styled.label`
cursor: pointer;
`;

const PageNationViewSelect = styled.select`
padding: 6px 20px 6px 20px;
font-size: 13px;
margin: 8px 0;
position: relative;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-weight: 400;
line-height: 1.25;
font: inherit;
outline: none;
`;

const PageNationWrapper = styled.div`
gap: 0 24px;
align-items: center;
`;

const DisplayCount = styled.span`
margin-left: 10px;
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`;

const DisplayCountView = styled.span`
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`;
